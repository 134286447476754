import { IShare } from '../../types';

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || '';
const protocol = window.location.protocol;

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: any) => btoa(JSON.stringify(object));
const getLinkForShare = (object: any, type: string) =>
	`${protocol}${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;

export const share = (data: IShare) => {
		if(data.sc === 'tw'){
			shareTwitter(data);
		}

		if(data.sc === 'fb'){
			shareFB(data);
		}
};

export const shareFB = (data: IShare) => {
	const { sc, league_id, section } = data;
	const FB_API = FB;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if(league_id) {
		object.league_id = league_id;
	}

	FB_API.ui({
		method:  'share',
		display: 'popup',
		href:    getLinkForShare(object, section),
	});
};

export const shareTwitter = (data: IShare) => {
	const { sc, league_id, section, message = '' } = data;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if(league_id) {
		object.league_id = league_id;
	}
	const href =   getLinkForShare(object, section);

	window.open(
		'https://twitter.com/share?url=' + encodeURIComponent(href) +
		'&text=' + encodeURIComponent(message),
		'twitter-share-dialog',
		'width=626,height=436'
	);
};

export const copyToClipboard = (ref: any) : Promise<object> => {
	return new Promise(((resolve, reject) => {
		try {
			ref.current.select();
			document.execCommand('copy');
			ref.current.blur();
			resolve()
		} catch (e) {
			console.log(e);
			reject(e)
		}
	}))
};