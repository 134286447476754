import styled                             from 'styled-components';
import landing_bg                         from '../../assets/img/landing_bg.png';
import { above }                          from '../../assets/css/media';
import theme                              from '../../assets/css/theme';

export const LandingWrapper = styled.div`
	position: relative;
	background-image: url(${landing_bg});
	min-height: 100vh;
	background-position: center;
`;

export const BackgroundCover = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background-color: #E8351A;
`;

export const GameTagLine = styled.div`
	font-size: 28px;
	color: #fff;
	font-family: ${theme.base_bold_font};
	max-width: 500px;
	margin: 0 auto 0.5em;
	line-height: 34px;
	padding: 10px 0;
	
	${above.desktop`
		font-size: 40px
		max-width: 700px;
		line-height: 50px;
	`}

`;
