import {
	IRequestConfig,
	HTTPClient as HTTPClientCore
} from "@fanhubmedia/fe-common-utils";
import axios from "axios";
import * as qs from "qs";
import { CANCEL } from "redux-saga";
import { getCookie } from "../dom_helpers";


class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest(config: IRequestConfig) {
		const source = axios.CancelToken.source();

		const request: any = super.makeRequest({
			...config,
			params     : {
				...config.params,
				_  : Date.now(),
				sid: getCookie('session_id')
			},
			cancelToken: source.token
		});

		request[ CANCEL ] = () => source.cancel();

		return request;
	}
}

const APIClient = new HTTPClient({
	baseURL         : process.env.REACT_APP_API_URL || "",
	withCredentials : true,
	transformRequest: [ (data) => qs.stringify(data) ]
});

const JSONSportClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_SPORT_URL || ""
});

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_URL || ""
});

export const Api = {
	JSON      : {
		checksums: () => JSONClient.get('checksums.json'),
	},
	JSON_SPORT: {
		settings: () => JSONSportClient.get('settings.json'),
		groups  : () => JSONSportClient.get('races.json'),
		squads  : () => JSONSportClient.get('squads.json'),
		drivers  : () => JSONSportClient.get('drivers.json'),
		rounds  : () => JSONSportClient.get('rounds.json'),
		guidlines: () => JSONSportClient.get('v8_podium_predictor_game_guidelines.json'),
		terms: () => JSONSportClient.get('v8_podium_predictor_terms_and_conditions.json'),
		faq: () => JSONSportClient.get('v8_podium_predictor_faq.json'),
		prizes: () => JSONSportClient.get('v8_podium_predictor_prizes.json'),
		countries:  () => JSONSportClient.get('countries.json'),
		results: (round: number) =>  JSONSportClient.get(`race_results/${round}.json`),
	},
	Auth      : {
		login : (params: object) => APIClient.post('login', params),
		logout: (params?: object) => APIClient.post('logout'),
	},
	User      : {
		create: (params: object) => APIClient.post('user/create', params),
		update: (params?: object) => APIClient.post('user/update', params),
		recover: () => APIClient.post('user/recover'),
		send_token: (params: object) => APIClient.post('password_recovery/send_token', params),
		change_password: (params: object) => APIClient.post('password_recovery/change_password', params),
		unsubscribe: (params: any) => APIClient.post('unsubscribe', params),
	},
	Leagues    : {
		create: (params: object) => APIClient.post('racing_league/create', params),
		update: (params: object) => APIClient.post('racing_league/update', params),
		show: (params: object) => APIClient.get('racing_league/show', params),
		my_list: (params: object) => APIClient.get('racing_league/show_my_stats', params),
		ladder: (params: object) => APIClient.get('racing_league/ladder', params),
	},
	Joins: {
		leave: (params: object) => APIClient.post('racing_join/leave', params),
		join: (params: object) => APIClient.post('racing_join/join', params),
		show_for_join: (params: object) => APIClient.get('racing_join/show_for_join', params),
		invite: (params: object) => APIClient.post('racing_join/invite', params),
	},
	Tournament : {
		save: (params: object) => APIClient.post('podium_predictor/save', params),
		predictions: () => APIClient.get('podium_predictor/show_my'),
		snapshot: (params: object) => APIClient.get('racing_stats/snapshot', params),
	},
	Contact: {
		save: (params: object) => APIClient.post('contact', params),
	},
	Rankings: {
		rankings: (params: object) => APIClient.get('racing_stats/rankings', params),
	}
};

export default Api;
