import * as React                                               from 'react';
import styled, { css }                                          from 'styled-components';
import { PlayerPoolCard, Icon, SearchField }                    from '../';
import { get, debounce, partial, chain, find, filter, compact } from 'lodash';
import { connect }                                              from 'react-redux';
import * as actions                                             from '../../modules/actions';
import { above, below }                                         from '../../assets/css/media';
import theme                                                    from '../../assets/css/theme';
import {
	IDriver,
	IDriverReducersState
}                                                               from '../../modules/reducers/drivers';
import { IStoreState }                                          from '../../modules/types';
import { getDrivers }                                           from '../../modules/selectors';
import { getSelectedGroup }                                     from '../../modules/utils/tournament';
import { IGroup }                                               from '../../modules/reducers/groups';
import { ITournamentState }                                     from '../../modules/reducers/tournament';


const PlayerPoolWrapper = styled.div`
	background: #fff;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.35);
	border-radius: 5px;
	position: relative;
`;

const PlayerPoolMobileContainer = styled.div`
		position: relative;
		${( { isVisible }: IPoolWrapper ) => (isVisible ? below.desktop`
			${css`
				background: ${theme.header_bg};
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				${PlayerPoolWrapper} {
					width: 100%;
				}
			`}
		` : below.desktop`
			display: none;
		`)}
`;

interface IPoolWrapper {
	isVisible: boolean
}

const PlayersList = styled.div`
	margin-top: 1em;
	background: #fff;
    ${( { isMoreThanMax }: IPlayersList ) => (isMoreThanMax && css`
    	max-height: 51.7vh;
    	overflow-y: scroll;
    	${below.desktop`
    	    ${css`
				max-height: 58vh;
			`}
			
		`}
    `)}
    ${below.desktop`
        ${css`
		    min-height: 58vh;
		`}
	`}
`;

interface IPlayersList {
	isMoreThanMax: boolean
}

const PlayerPoolTitle = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 22px;
	color: #fff;
	padding: 1em 0;
	text-align: center;
	background: ${theme.secondary};
`;

const BackButton = styled.div`
	position: absolute;
	top: 20px;
    left: 20px;
    align-self: flex-start;
	${above.desktop`
		display: none;
	`}
`;


const SearchFieldCustom = styled( SearchField )`
	width: 95%;
	margin: 1em auto;
	height: 45px;
`;

interface IProps {
	drivers: IDriverReducersState;
	selected_group: IGroup;
	isVisibleMobile: boolean;
	readonly tournament: ITournamentState;
	readonly setPlayerPoolVisibility: ( is_visible: boolean ) => void;
	readonly changeAction: ( options: any ) => void;
	readonly isAutoSave: boolean;
}

interface IState {
	search: string;
}

/*
* Test
* */
class PlayerPoolClass extends React.Component<IProps, IState> {
	public state = {
		search : '',
		sort_by: ''
	};
	private readonly search_input: React.RefObject<HTMLInputElement>;
	private readonly max_view_length = 7;
	private readonly debounce_delay = 500;

	constructor( props: any ) {
		super( props );
		this.search_input = React.createRef();
		this.setSearchValueToState = debounce( this.setSearchValueToState, this.debounce_delay );
	}

	get selectedPredictions(): any {
		const { selected_group, tournament } = this.props;
		return find( tournament.predictions, { race_id: selected_group.id } ) || { podium: [] };
	}

	get isLocked() {
		const podium = this.selectedPredictions.podium;
		const { selected_group } = this.props;
		let isLocked = true;

		podium.forEach( ( driver: IDriver ) => {
			if ( !driver.id ) {
				isLocked = false;
			}
		} );

		return isLocked || selected_group.status !== 'scheduled';
	}

	get playerCell() {
		const { drivers, selected_group } = this.props;
		const { drivers: group_drivers = [] } = selected_group;
		const predictions: any = this.selectedPredictions;
		const prediction_podium = compact( predictions.podium.map( ( podium: any ) => podium.id ) );
		const available_drivers = filter( group_drivers, ( driver_id: number ) => prediction_podium.indexOf( driver_id ) === -1 );

		const filtered_drivers = chain( drivers )
			.filter( ( driver: IDriver ) => {
				const search = this.state.search.toLowerCase();
				const first_name = driver.first_name.toLowerCase();
				const last_name = driver.last_name.toLowerCase();
				return (last_name.includes( search ) || first_name.includes( search )) && available_drivers.indexOf( driver.id ) > -1;
			} )
			.sortBy( this.state.sort_by )
			.value();

		return filtered_drivers.map( ( driver: any ) => (
			<PlayerPoolCard
				key={`pool-player-${driver.id}`}
				callback={partial( this.addDriver, driver.id )}
				driver={driver}
				isLocked={this.isLocked}
				isEndurance={selected_group.endurance}
			/>)
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		const { drivers, isVisibleMobile } = this.props;
		return (
			<PlayerPoolMobileContainer
				isVisible={isVisibleMobile}
			>
				<PlayerPoolWrapper>
					<BackButton onClick={this.closePlayerPool}>
						<Icon name={'chevron-left'} color={'#E81C19'} size={'25'}/>
					</BackButton>
					<PlayerPoolTitle>
						Drivers
					</PlayerPoolTitle>
					<SearchFieldCustom
						ref={this.search_input as any}
						placeholder='Search for a driver'
						onInput={this.getSearchValue}
					/>
					<PlayersList
						isMoreThanMax={drivers.length > this.max_view_length}
					>
						{this.playerCell}
					</PlayersList>
				</PlayerPoolWrapper>
			</PlayerPoolMobileContainer>
		)
	}

	/**
	 * Save search value to store after debounce
	 */
	private readonly closePlayerPool = () => {
		this.props.setPlayerPoolVisibility( false );
	};

	/**
	 * Save search value to store after debounce
	 */
	private setSearchValueToState( search: string ) {
		this.setState( {
			search
		} );
	}

	/*
	* Add Driver action
	* */
	private readonly addDriver = ( driver_id: number ) => {
		if ( this.isLocked ) {
			return;
		}
		const options = {
			driver_id,
			action   : 'addTournamentFreePlayer',
			auto_save: false,
		};
		this.props.changeAction( options );
	};
	/*
	* Get value from search input
	* */
	private readonly getSearchValue = () => {
		const search = get( this.search_input, 'current.value', '' );
		this.setSearchValueToState( search );
	}
}


const mapStateToProps = ( state: IStoreState ) => ({
	drivers       : getDrivers( state ),
	selected_group: getSelectedGroup( state.tournament.selected_group_id, state.groups.groups ),
	tournament    : state.tournament
});

const mapDispatchToProps = {
	changeAction           : actions.tournamentChangeAction,
	setPlayerPoolVisibility: actions.setPlayerPoolVisible
};

export const PlayerPool = connect( mapStateToProps, mapDispatchToProps )( PlayerPoolClass );

export default PlayerPool;
