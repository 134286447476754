import styled from 'styled-components';
import { below } from '../../assets/css/media';

export const LeagueFormWrapper = styled.form`
	max-width: 90%;
	margin-right: auto;
	text-align: center;
	position: relative;
	min-height: 100px;
	overflow: hidden;
	${below.desktop`
		max-width: 100%;
	`}
`;

export default LeagueFormWrapper;