import { SagaIterator } from 'redux-saga';
import { takeLatest }   from 'redux-saga/effects';
import * as actions     from '../actions';

import * as user          from './user';
import * as groups        from './groups';
import * as squads        from './squads';
import * as rounds        from './rounds';
import * as jsons         from './jsons';
import * as tournament    from './tournament';
import * as leagues       from './leagues';
import * as social_share  from './social_share';
import * as errors        from './error_managment';
import * as countries     from './countries';
import * as drivers       from './drivers';
import * as results       from './results';
import * as unsubscribe   from './unsubscribe';
/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
	/**
	 * User listeners
	 */
	yield takeLatest(actions.userLogin, user.postUserLoginSaga);
	yield takeLatest(actions.userLogout, user.postUserLogoutSaga);
	yield takeLatest(actions.userRegister, user.postUserRegisterSaga);
	yield takeLatest(actions.userForgotPassw, user.postUserSendEmailSaga);
	yield takeLatest(actions.userRecoverPassw, user.postUserRecoverSaga);
	yield takeLatest(actions.userUpdate, user.postUserUpdateSaga);
	yield takeLatest(actions.userRecover, user.recoverUserSaga);

	/**
	 * JSONS
	 */
	yield takeLatest(actions.fetchGroupsJSON, groups.GroupsFetchSaga);
	yield takeLatest(actions.fetchSquadsJSON, squads.SquadsFetchSaga);
	yield takeLatest(actions.fetchRoundsJSON, rounds.RoundsFetchSaga);
	yield takeLatest(actions.fetchJSONS, jsons.JSONSFetchSaga);
	yield takeLatest(actions.fetchJSONSOnLogin, jsons.JSONSFetchOnLoginSaga);
	yield takeLatest(actions.fetchGuidelines, jsons.fetchGuidelinesJSON);
	yield takeLatest(actions.fetchTerms, jsons.fetchTermsJSON);
	yield takeLatest(actions.fetchFAQS, jsons.fetchFAQSJSON);
	yield takeLatest(actions.fetchPrizes, jsons.fetchPrizesJSON);
	yield takeLatest(actions.fetchCountriesJSON, countries.CountriesFetchSaga);
	yield takeLatest(actions.fetchDriversJSON, drivers.DriversFetchSaga);
	yield takeLatest(actions.fetchResults, results.ResultsFetchSaga);
	yield takeLatest(actions.fetchResultsInitial, results.ResultsInitialFetchSaga);
	/*
	* Tournament
	* */
	yield takeLatest(actions.addTournamentFreePlayer, tournament.TournamentAddFreePlayerSaga);
	yield takeLatest(actions.removeTournamentFreePlayer, tournament.TournamentRemoveFreePlayerSaga);
	yield takeLatest(actions.saveTournamentPredictions, tournament.TournamentSaveGroupSaga);
	yield takeLatest(actions.fetchTournamentPredictions, tournament.TournamentGetPredictionsSaga);
	yield takeLatest(actions.tournamentChangeAction, tournament.TournamentChangeActionSaga);
	yield takeLatest(actions.userLogoutSuccess, tournament.TournamentToDefaultStoreSaga);
	yield takeLatest(actions.fetchSnapshot, tournament.TournamentGetSnapshotSaga);
	yield takeLatest(actions.saveMargin, tournament.TournamentSaveMarginSaga);
	yield takeLatest(actions.setTournamentSelectedGroupId, tournament.TournamentSetSelectedGroupIdSaga);
	/**
	 * Leagues
	 */
	yield takeLatest(actions.createLeague, leagues.postLeagueCreateSaga);
	yield takeLatest(actions.getLeagues, leagues.getMyListLeaguesSaga);
	yield takeLatest(actions.showLeague, leagues.getLeagueByIdSaga);
	yield takeLatest(actions.updateLeague, leagues.postLeagueUpdateSaga);
	yield takeLatest(actions.leaveLeague, leagues.postLeagueLeaveSaga);
	yield takeLatest(actions.getLeaguesForJoin, leagues.getJoinListLeaguesSaga);
	yield takeLatest(actions.getLeagueLadderData, leagues.getLadderDataSaga);
	yield takeLatest(actions.getLeagueLadder, leagues.getLeagueLadderDataSaga);
	yield takeLatest(actions.joinToLeague, leagues.postJoinToLeagueSaga);
	yield takeLatest(actions.searchAndJoin, leagues.searchAndJoinSaga);

	yield takeLatest(actions.inviteViaEmail, social_share.postInvitesSendViaEmail);
	// yield takeLatest(actions.socialShare, social_share.socialShareSaga);
	yield takeLatest(actions.errorsGlobalError, errors.errorsManagerSaga);

	/**
	 * Contact US
	 */
	yield takeLatest(actions.saveContactUs, user.postContactUsSaga);

	yield takeLatest(actions.unsubscribe, unsubscribe.UnsubscribeSaga);
}
