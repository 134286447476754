import styled from 'styled-components';

export const HeaderNavWrapper = styled.div`
	display: flex;
	background: #fff;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
`;

export default HeaderNavWrapper;