import * as React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../assets/css/theme';
import remove_icon from '../../assets/img/icons/remove-icon.png';
import lock_icon from '../../assets/img/icons/lock-icon.png';
import correct_icon from '../../assets/img/icons/correct-icon.png';
import wrong_icon from '../../assets/img/icons/wrong-icon.png';
import { below } from '../../assets/css/media';
import { every } from 'lodash';

const Cell = styled.div`
	max-width: 100%;
	color: ${theme.secondary};
	height: 80px;
	cursor: pointer;
	position: relative;
	background: #ededed;
	margin-bottom: 13px;
	border: 2px solid #ededed;
`;

const PlayerCardWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 2px;
	background: #fff;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	flex: 1;
	max-width: 60%;
	height: 100%;
	${below.desktop`
		${({ isOnField }: any) => (isOnField && css`
			flex: 1 0 auto;
			max-width: 100%;
			margin-bottom: .5em;
		`)}
	`}
`;

const PlayerName = styled.div`
	flex: 1;
	text-align: left;
	font-family: ${theme.base_bold_font};
	margin-left: 5px;
	color: #252549;
`;

const FirstName = styled.div`
	font-size: 14px;
`;

const LastName = styled.div`
	font-size: 17px;
`;

const SquadName = styled.div`
	font-size: 10px;
	color: #7E858A;
	font-family: ${theme.base_font};
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	z-index: 1;
	position: relative;
`;

const RightSectionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: auto;
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 97%;
	height: 100%;
	background: #fff;
	transform: skew(-18deg, 0deg);
	box-shadow: 2px 0 10px -2px rgba(138, 138, 138,0.75);
	${below.desktop`
		width: 93%;
	`}
`;

const PlayerNumber = styled.div`
	background: #333F48;
	height: 100%;
	color: #fff;
	font-family: ${theme.base_bold_font};
	font-size: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	${below.desktop`
		font-size: 14px;
		padding: 0 4px;
	`}
`;

const Icon = styled.img`
	width: 35px;
	margin-right: 13px;
	margin-left: auto;
`;

const Partner = styled.div`
	text-transform: uppercase;
	color: #333F48;
	font-size: 13px;
`;

const ProvisionalWrapper = styled.div`
	text-align: center;
	${below.desktop`
		font-size: 10px;
	`}
`;
const ProvisionalTitle = styled.div`
	color: #E8351A;
`;
const ProvisionalText = styled.div`
	color: #7E858A;
`;

const CorrectWrongStyles = ({ isCorrect, isFinal }: IFinalState) => {
	const correct = isFinal && isCorrect ? `background: #7ED321;` : '';
	const wrong = isFinal && !isCorrect ? `background: #D0021B;` : '';
	return [correct, wrong];
};

const Points = styled.div`
	background: #E8351A;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #fff;
	font-size: 20px;
	margin: 0 30px 0 10px;
	div {
		margin-bottom: 2px;
	}
	span {
		font-size: 8px;
		font-family: ${theme.base_font};
	}
	${({ isCorrect, isFinal }: IFinalState) => CorrectWrongStyles({ isCorrect, isFinal })}
	${below.desktop`
		width: 40px;
		height: 40px;
		margin-right: 10px;
	`}
`;


const CorrectWrongLabel = styled.div`
	background: #7E858A;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 13px;
	margin-left: auto;
	img {
		width: 54%
		${({ isCorrect }: IFinalState) => !isCorrect && `width: 38%`}
	}
	${({ isCorrect, isFinal }: IFinalState) => CorrectWrongStyles({ isCorrect, isFinal })}
`;

const DriverHeadShot = styled.div`
	display: flex;
	align-items: center;
	margin-right: 20px;
	position: relative;
	height: 100%;
	flex: 0 0 116px;
	${below.desktop_large`
		flex: 0 0 94px;
	`}
	${below.desktop`
		margin-right: 10px;
		flex: 0 0 105px;
	`}
	${below.phone`
		flex: 0 0 94px;
	`}
`;

// const BG = styled.div`
// 	position: absolute;
// 	top: -15px;
//     left: -17px;
//     width: 119%;
//     height: 147%;
//     background: #000;
//     transform: rotate(11deg);
// `;

const DriverImage = styled.img`
	width: 78px;
	position: relative;
	z-index: 1;
	align-self: flex-end;
	${below.desktop_large`
		width: 60px;
	`}
	${below.desktop`
		width: 70px;
	`}
	${below.phone`
		width: 60px;
	`}
`;

const DriverNumber = styled.div`
	font-family: ${theme.base_bold_font};
	font-style: italic;
	font-size: 30px;
	position: relative;
	z-index: 1;
	${below.desktop`
		font-size: 20px;
	`}
`;

interface IFinalState {
	isFinal: boolean;
	isCorrect: boolean;
}

interface IProps {
	removeCallback: () => void;
	playerNumber: string;
	isLocked?: boolean;
	driver: any;
	isEndurance: number;
	showResults: boolean;
	isProvisional: boolean;
	isFinal: boolean;
	podiumPosition: number;
}

const max_podium = 3;
const one_point = 1;
const two_point = 2;

const removeIcon = (props: IProps, callback: () => void) => every([!props.isLocked, !props.showResults]) &&
	<Icon src={remove_icon} onClick={callback}/>;
const lockIcon = (props: IProps) => props.isLocked && !props.showResults && <Icon src={lock_icon}/>;
const provisionalText = (props: IProps) => props.isProvisional && (
	<ProvisionalWrapper>
		<ProvisionalTitle>Provisional results.</ProvisionalTitle>
		<ProvisionalText>Final results will be available soon.</ProvisionalText>
	</ProvisionalWrapper>
);
const isOnePoint = (props: IProps) => {
	const { driver } = props;
	const { result = {} } = driver;
	return result.position <= max_podium;
};
const isTwoPoint = (props: IProps) => {
	const { driver, podiumPosition } = props;
	const { result = {} } = driver;
	return result.position === podiumPosition;
};

const points = (props: IProps) => {
	const { showResults } = props;
	let point = 0;

	if (isOnePoint(props)) {
		point = one_point;
	}
	if (isTwoPoint(props)) {
		point = two_point;
	}
	return showResults ? (
		<Points
			isFinal={props.isFinal}
			isCorrect={isOnePoint(props) || isTwoPoint(props)}
		>
			<div>{point}</div>
			<span>PTS</span>
		</Points>
	) : null
};
const correctWrongLabel = (props: IProps) => {
	const { showResults } = props;
	const isCorrect = isOnePoint(props) || isTwoPoint(props);
	return showResults ? (
		<CorrectWrongLabel
			isCorrect={isCorrect}
			isFinal={props.isFinal}
		>
			<img src={isCorrect ? correct_icon : wrong_icon} alt=""/>
		</CorrectWrongLabel>
	) : null;
};

export const PlayerCard = (props: IProps) => {
	const { driver, removeCallback } = props;
	const driver_url = `${process.env.REACT_APP_DRIVERS_URL}${driver.id}.png`;
	return (
		<Cell>
			<Wrapper>
				<PlayerNumber>
					{props.playerNumber}
				</PlayerNumber>
				<PlayerCardWrapper>
					<DriverHeadShot>
						<DriverImage src={driver_url} alt=""/>
						<DriverNumber>{driver.number}</DriverNumber>
					</DriverHeadShot>
					<PlayerName>
						<FirstName>{driver.first_name}</FirstName>
						<LastName>{driver.last_name}</LastName>
						{props.isEndurance && driver.endurance_partner ?
							<Partner>/ {driver.endurance_partner}</Partner> : null}
						<SquadName>{driver.squad_name}</SquadName>
					</PlayerName>
				</PlayerCardWrapper>
				<RightSectionWrapper>
					{provisionalText(props)}
					{removeIcon(props, removeCallback)}
					{lockIcon(props)}
					{points(props)}
					{correctWrongLabel(props)}
				</RightSectionWrapper>
			</Wrapper>
			<Background/>
		</Cell>
	)
};

