import { css } from 'styled-components';

interface IMediaSizes {
	readonly phone: number | any,
	readonly tablet: number | any,
	readonly desktop: number | any,
	readonly desktop_large: number | any,
}

export const sizes: IMediaSizes = {
	phone: 576,
	tablet: 768,
	desktop: 960,
	desktop_large: 1240
};

export const above: any = Object.keys(sizes).reduce((acc: any, label: string) => {
	acc[label] = (literals: TemplateStringsArray, ...args: any[]) => css`
	  @media (min-width: ${sizes[label]}px) {
		${css(literals, ...args)}
	  }
	`;
	return acc;

}, {} as any);

export const below: any  = Object.keys(sizes).reduce((acc: number, label: string) => {
    acc[label] = (literals: TemplateStringsArray, ...args: any[]) => css`
	  @media (max-width: ${sizes[label] - 1}px) {
		${css(literals, ...args)}
	  }
	`;
	return acc;
}, {} as any);


export default above;
