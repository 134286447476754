import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IRequestData } from '../../types';
import { ILeagueState } from './my_leagues';

export interface ILeagueCreateReduser extends IRequestData {
	readonly is_league_created?: boolean;
	readonly league: ILeagueState | undefined;
}

const defaultState: ILeagueCreateReduser = {
	is_data_requested: false,
	is_league_created: false,
	league: undefined,
};

export const league_create = createReducer<ILeagueCreateReduser>({}, defaultState)
	.on(actions.createLeague, (state: ILeagueCreateReduser) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.createLeagueSuccess, (state: ILeagueCreateReduser, payload: ILeagueState) => ({
		...state,
		league: payload,
		is_data_requested: false,
		is_league_created: true,

	}))
	.on(actions.createLeagueFailed, (state: ILeagueCreateReduser) => ({
		...state,
		is_data_requested: false,
	}))
	.on(actions.clearLeagueCreate, (state: ILeagueCreateReduser) => ({
		...defaultState,
	}));
