import * as React   from 'react';
import * as actions from '../../modules/actions';
import * as _       from 'lodash';
import styled       from 'styled-components';
import { connect }  from 'react-redux';

import {
	ContentWithSidebar,
	Field,
	FormCreator,
	Notice,
	PageTitle, PointsSystem,
	SecondaryButtonLeftSide,
	SelectStyled,
	SidebarAdComponent,
	WithSubNav
} from '../../components';
import { HelpMenuItems as items_menu } from '../../components/Navigations/menuItems';

import { IFormProps, ISendRequest, IStoreState } from '../../modules/types';
import theme                                     from '../../assets/css/theme';
import { IContactUsReduser, IUserReducerState }  from '../../modules/reducers/user';
import { IContactUSSagaAction }                  from '../../modules/sagas/user';

interface ISuccess {
	readonly active?: boolean
}

const MessageSuccess = styled.div`
	display: block;
	background: ${theme.primary};
	width: 100%;
	text-align: center;
	font-family: ${theme.base_bold_font};
	color: #fff;
	padding: 1em;
	margin-bottom: 2em;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.3s ease;
	box-sizing: border-box;
	font-size: 0;
	${( { active }: ISuccess ) => (active ? `
		max-height: 100%;
		font-size: 1em;
	` : '')};
`;

const TextArea = styled( Field )`
	padding: 1em;
	color: ${theme.base_text_color};
	width: 100%;
	height: 150px;
	&::-webkit-input-placeholder {
		color: ${theme.base_text_color};
	}
	&::-moz-placeholder {
		color: ${theme.base_text_color};
	}
	&:-ms-input-placeholder {
		color: ${theme.base_text_color};
	}
	&:-moz-placeholder {
		color: ${theme.base_text_color};
	}
`;


interface IMapState {
	contact_us: IContactUsReduser,
	user: IUserReducerState
}

type Dispatch = ISendRequest<IContactUSSagaAction>;
type Props = IMapState & Dispatch;

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>])

class ContactUsComponent extends React.Component<Props> {

	constructor( props: Props ) {
		super( props );

		_.bindAll( this, [
			'renderedFields',
			'handleSubmitUpdate'
		] )
	}

	/**
	 * Method to process restore user's fields form submission
	 */
	public handleSubmitUpdate( values: IContactUSSagaAction ) {
		const { send } = this.props;

		send( values );
	};


	/**
	 * Get ContactUS form
	 */
	public renderedFields( props: IFormProps, values: IContactUSSagaAction ) {
		let TAB_INDEX = 0;
		const { handleChange } = props;
		const { name, email } = values;
		const { contact_us: { is_success } } = this.props;

		return (
			<>
				<Notice>
					Please note: If your query has been answered in our FAQ and Game Guidelines
					sections of
					the site we do not guarantee a response. Please take the time to review our FAQs
					and
					Guidelines carefully first.
				</Notice>
				<Field
					type="text"
					id={_.uniqueId( 'name' )}
					name='name'
					defaultValue={name && name.trim()}
					placeholder="Enter your name *"
					title="Invalid name"
					pattern="^[a-zA-Z\s\-]{1,80}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<Field
					type="email"
					name='email'
					autoComplete='email'
					placeholder="Email *"
					tabIndex={++TAB_INDEX}
					defaultValue={email && email.trim()}
					onChange={handleChange}
					required={true}
					disabled={!!email}
				/>
				<SelectStyled
					required={true}
					name='category'
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
				>
					<option value="">
						Choose a category
					</option>
					<option value="gameplay">
						Gameplay
					</option>
					<option value="leagues">
						Leagues
					</option>
					<option value="error">
						Error
					</option>
					<option value="signin">
						Password & Sign In
					</option>
				</SelectStyled>

				<TextArea
					as="textarea"
					name="question"
					id=""
					placeholder="Type your question..."
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<SecondaryButtonLeftSide
					type="submit"
					disabled={is_success}
				>CONTACT US
				</SecondaryButtonLeftSide>
			</>
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		const { user, contact_us: { is_success } } = this.props;
		const { first_name, last_name, email } = user;
		return (
			<WithSubNav backLink="/" backTitle='HELP' items_menu={items_menu}>
				<ContentWithSidebar
					sidebar_components={[ Ads ]}
				>
					<PageTitle>Contact Us</PageTitle>
					<FormCreator
						onSubmit={this.handleSubmitUpdate}
						render={this.renderedFields}
						initialState={{
							name: `${first_name} ${last_name}`,
							email,
						}}
					/>
					<MessageSuccess active={is_success}>
						Thank you - we have received your query.
						One of our support team will be in touch in due course.
					</MessageSuccess>
				</ContentWithSidebar>
			</WithSubNav>
		)
	}
}

const mapStateToProps = ( state: IStoreState ): IMapState => {
	return {
		contact_us: state.contact_us,
		user      : state.user,
	}
};

const mapDispatchToProps: Dispatch = {
	send: actions.saveContactUs
};

export const ContactUs = connect( mapStateToProps, mapDispatchToProps )( ContactUsComponent );

export default ContactUs