import { SagaIterator }              from 'redux-saga';
import { call, put }                 from 'redux-saga/effects';
import { ISagaAction, NumberOrNone } from '../../types';

export const RoundsFetchSaga = function*(
	action: ISagaAction<NumberOrNone>
): SagaIterator {

	const rounds = yield call(action.meta.api.JSON_SPORT.rounds);

	if (rounds.error) {
		yield put(action.meta.actions.fetchRoundsJSONFailed({ message: rounds.error }));
		return;
	}

	yield put(action.meta.actions.fetchRoundsJSONSuccess(rounds));

};

export default RoundsFetchSaga;
