import { SagaIterator }              from 'redux-saga';
import { call, put }                 from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { ISagaAction, NumberOrNone } from '../../types';
import ApiErrors                     from '../../utils/Api/ApiErrors';

export const UnsubscribeSaga = function*(
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const data = action.payload;
	console.log('123sad')
	const unsubscribe = yield call(action.meta.api.User.unsubscribe, data);

	const is_error = !isEmpty( unsubscribe.errors );
	if ( is_error ) {
		throw new ApiErrors(unsubscribe.errors[0].text, unsubscribe.errors[0].code)
	}

	yield put(action.meta.actions.unsubscribeSuccess());
};

export default UnsubscribeSaga;
