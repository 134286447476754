import * as _     from 'lodash';
import * as React from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled      from 'styled-components';
import theme       from '../../assets/css/theme';

import {
	Field,
	FormGlobalTitle,
	FormWrapper,
	LoadingSpinner,
	FormCreator,
	SecondaryButton
} from '../../components';

import * as actions from '../../modules/actions'

import {
	IUserFormState,
	ILoginProps,
	IStoreState,
	IFormProps,
	IEmail,
	IPassword
} from '../../modules/types'

const ForgotPassword = styled( NavLink )`
	display: block;
	text-align: right;
	font-size: 14px;
	color: #7E858A;
	font-family: ${theme.secondary_font};
	width: 90%;
	margin: 0 auto 12px;
`;

const RegisterButton = styled( NavLink )`
	display: block;
`;

const FormTitle = styled( FormGlobalTitle )`
	font-family: ${theme.base_bold_font};
	font-size: 25px;
	color: ${theme.base_text_color};
	padding: 1em 0;
	line-height: 25px;
`;

const LoginFiled = styled(Field)`
	width: 90%;
	margin: 0 auto 12px;
`;

const LoginBtn = styled(SecondaryButton)`
	width: 90%;
`;

class LoginFormComponent extends React.Component<ILoginProps, IUserFormState> {

	public state = {
		email   : '',
		password: '',
	};

	constructor( props: ILoginProps ) {
		super( props );
		_.bindAll( this, [
			'handleSubmitForm',
			'renderedFields',
		] )
	}

	/**
	 * Method to process login form submission
	 */
	public handleSubmitForm( values: IUserFormState ) {
		const { userLogin } = this.props;

		userLogin( values );
	};

	/**
	 * Get Login Form Fields
	 */
	public renderedFields( props: IFormProps, values: IEmail & IPassword ) {
		let TAB_INDEX = 0;
		const { handleChange } = props;
		const { email, password } = values;

		return (<>
			<FormGlobalTitle>
				Already have a<br/> Supercars Tipping account?
			</FormGlobalTitle>
			<LoginFiled
				type="email"
				id='email'
				name='email'
				placeholder="Email *"
				title="Invalid email address"
				defaultValue={email}
				tabIndex={++TAB_INDEX}
				onChange={handleChange}
				required={true}
			/>
			<LoginFiled
				type="password"
				id='password'
				name='password'
				placeholder="Password *"
				defaultValue={password}
				pattern="^.{8,}$"
				title="Min length 8"
				tabIndex={++TAB_INDEX}
				onChange={handleChange}
				required={true}
			/>
			<ForgotPassword to="/forgot-password">Forgot your password?</ForgotPassword>
			<LoginBtn type='submit' disabled={false}>LOG IN</LoginBtn>
			<FormTitle>
				Or new to Supercars Tipping?<br/>Register today
			</FormTitle>
			<RegisterButton to="/registration-first-step">
				<LoginBtn disabled={false}>REGISTER</LoginBtn>
			</RegisterButton></>)
	}

	/**
	 * @ignore
	 */
	public render() {
		const { is_data_requested } = this.props;

		if ( is_data_requested ) {
			return (
				<FormWrapper>
					<LoadingSpinner with_cover_bg={true} position="center"/>
				</FormWrapper>
			)
		}

		return (
			<FormCreator
				as={FormWrapper}
				onSubmit={this.handleSubmitForm}
				render={this.renderedFields}
			/>

		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
		is_data_requested: _.get( state, 'user.is_data_requested', false )
	});

const mapDispatchToProps = {
	userLogin: actions.userLogin,
};

export const LoginForm = connect( mapStateToProps, mapDispatchToProps )( LoginFormComponent );
export default LoginForm;