import { createReducer }             from 'redux-act';
import * as actions                  from '../../actions';

export interface ITournamentState {
	selected_group_id: number;
	predictions: IPrediction[];
	is_pool_visible: boolean;
	player_box_to_select: {
		question_id: number;
		cell: number;
	} | {};
	is_data_requested: boolean;
	snapshot: ISnapshot;
	is_saved: boolean;
}

export interface ISnapshot {
	overall_points: number | null;
	overall_rank: number | null;
	round: number;
	round_points: number | null;
}

export interface IPredictionDriver {
	id: number,
	position: number
}

export interface IPrediction {
	race_id: number,
	podium: any[],
	margin: number
}

export interface ITournamentSave {
	race_id: number;
	podium: IPrediction[];
	margin: number;
}

const initialState: ITournamentState = {
	selected_group_id   : 0,
	predictions         : [],
	is_pool_visible     : false,
	player_box_to_select: {},
	is_data_requested   : false,
	is_saved: false,
	snapshot            : {
		overall_points: null,
		overall_rank: null,
		round: 1,
		round_points: null,
	}
};

export const tournament = createReducer<ITournamentState>( {}, initialState );

tournament.on( actions.setTournamentSelectedGroupIdSuccess, ( state: ITournamentState, payload: number ) => ({
	...state,
	selected_group_id: payload,
	is_saved: false,
}) );

tournament.on( actions.setTournamentDefaults, ( state: ITournamentState, payload: IPrediction[] ) => ({
	...state,
	...payload
}) );

tournament.on( actions.addTournamentFreePlayerSuccess, ( state: ITournamentState, payload: IPrediction[] ) => ({
	...state,
	...payload
}) );

tournament.on( actions.setPlayerPoolVisible, ( state: ITournamentState, payload: { is_visible: boolean, question_id: number, cell: number } ) => {
	return ({
		...state,
		is_pool_visible     : payload.is_visible,
		player_box_to_select: {
			question_id: payload.question_id,
			cell       : payload.cell
		}
	})
} );

tournament.on( actions.saveMarginSuccess, ( state: ITournamentState, payload: IPrediction[] ) => ({
	...state,
	...payload
}) );

tournament.on( actions.saveTournamentPredictions, ( state: ITournamentState ) => ({
	...state,
	is_data_requested: true
}) );

tournament.on( actions.saveTournamentPredictionsSuccess, ( state: ITournamentState, payload: IPrediction[] ) => ({
	...state,
	predictions      : payload,
	is_data_requested: false,
	is_saved: true,
}) );

tournament.on( actions.fetchTournamentPredictions, ( state: ITournamentState ) => ({
	...state,
	is_data_requested: true
}) );

tournament.on( actions.fetchTournamentPredictionsSuccess, ( state: ITournamentState, payload: IPrediction[] ) => ({
	...state,
	predictions      : payload,
	is_data_requested: false,
	is_saved: false,
}) );

tournament.on( actions.setTournamentDefaultStore, ( state: ITournamentState, payload: any ) => {
	return (
		{
			...initialState,
			...payload,
			is_saved: false,
		}
	)
} );

tournament.on( actions.fetchSnapshotSuccess, ( state: ITournamentState, payload: ISnapshot ) => ({
	...state,
	snapshot: payload,
}) );

