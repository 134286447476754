import styled from 'styled-components';
import theme from '../../assets/css/theme';

export const Notice = styled.p`
	display: block;
	padding: 0.75em 0;
	text-align: left;
	font-size: 0.75em;
	line-height: 1.4;
	color: ${theme.base_text_color};
	font-family: ${theme.base_font};
	opacity: 0.7;
	
	a {
		color: #E81C19;
	}
`;

export default Notice;