import { IModelUser } from '../../types/user';

export const saveUserToStorage = (user: IModelUser, session_id?: string):void => {
	document.cookie = "session_id=" + session_id;
	localStorage.setItem('user', JSON.stringify(user));
	localStorage.setItem('is_authorized', 'true');
};

export const clearUserFromStorage = ():void => {
	document.cookie = "";
	localStorage.removeItem('user');
	localStorage.removeItem('is_authorized');
};

export const getUserObjectFromStorage = ():IModelUser => {
	try {
		const local_storage_user = localStorage.getItem('user') || '{}';

		return JSON.parse(local_storage_user);
	} catch ( e ) {
		console.log(e);
		clearUserFromStorage();

		const user =  localStorage.getItem('user') || '{}';
		return JSON.parse(user);
	}

};
