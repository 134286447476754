import styled from 'styled-components';
import { Field } from './Field';

import search_icon from '../../assets/img/icons/search-icon.png';

export const SearchField = styled( Field )`
	width: 90%;
	margin: auto;
	background-image: url(${search_icon});
	background-repeat: no-repeat;
	background-position: center right;
`;

export default SearchField;
