import { ISagaAction, IStoreState } from '../../types';
import { ITournamentSave }          from '../../reducers/tournament';
import { SagaIterator }             from 'redux-saga';
import {call, put, take, select }             from 'redux-saga/effects';
import { getSelectedGroup }         from '../../utils/tournament';

export const ResultsFetchSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {

	while(true) {
		yield take(action.meta.actions.setTournamentSelectedGroupIdSuccess);
		yield FetchResultsProcess(action);
	}

};

export const ResultsInitialFetchSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {
	while(true) {
			yield take( action.meta.actions.setTournamentDefaults );
			yield FetchResultsProcess( action );
	}
};

export const FetchResultsProcess = function*(action: ISagaAction<ITournamentSave>): any {

	const { selected_group_id, groups } = yield select( ( store: IStoreState ) => ({
		selected_group_id   : store.tournament.selected_group_id,
		groups: store.groups.groups
	}) );

	const selected_group: any = getSelectedGroup(selected_group_id, groups);

	if(selected_group.status === 'complete') {

		try {
			const result = yield call( action.meta.api.JSON_SPORT.results, selected_group.id );

			yield put( action.meta.actions.fetchResultsSuccess( result ) );

		}
		catch ( e ) {
			yield put( action.meta.actions.fetchResultsFailed( ) );
		}

	}
};