import * as React                             from 'react';
import { Ladder }                             from './Ladder';
import { RouteComponentProps }                from 'react-router';
import { connect }                            from 'react-redux';
import { IStoreState }                        from '../../modules/types';
import { ILeagueManageReduser }               from '../../modules/reducers/leagues';
import {
	ContentWithSidebar, FetchJsons, PointsSystem,
	SidebarAdComponent,
	WithSubNav
} from '../../components';
import { LeaguesNextMenuItems as items_menu } from '../../components/Navigations/menuItems'

interface IMapStateProps {
	readonly model_manage: ILeagueManageReduser
}

type Props = IMapStateProps & RouteComponentProps;

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>]);

const StandingsLeagueComponent = ( { model_manage, ...props }: Props ) => (
	<WithSubNav backLink="/leagues" backTitle={model_manage.league.name} items_menu={items_menu}>
		<FetchJsons/>
		<ContentWithSidebar no_mobile_padding={true}
		                    sidebar_components={[ Ads ]}
		>
			<Ladder {...props}/>
		</ContentWithSidebar>
	</WithSubNav>
);

const mapStateToProps = ( state: IStoreState ): IMapStateProps => ({
	model_manage: state.league_manage,
});

export const StandingsLeague = connect( mapStateToProps )( StandingsLeagueComponent );
export default StandingsLeague;