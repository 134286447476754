import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IRequestData } from '../../types';

export interface IInvitesReducer extends IRequestData {
	readonly is_data_requested: boolean;
	readonly is_success: boolean;
}

const defaultState = {
	is_data_requested: false,
	is_success: false,
};

export const social_share = createReducer<IInvitesReducer>({}, defaultState)
	.on(actions.inviteViaEmail, () => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(actions.inviteViaEmailSuccess, () => ({
		is_data_requested: false,
		is_success: true,
	}))
	.on(actions.inviteViaEmailFailed, () => defaultState );

export default social_share;
