import * as _                    from 'lodash';
import { SagaIterator }          from 'redux-saga';
import { call, put }             from 'redux-saga/effects';
import { ISagaAction }           from '../../types';
// import { shareFB, shareTwitter } from '../../utils/social_share';
import ApiErrors                 from '../../utils/Api/ApiErrors';

export interface IEmail {
	readonly email: string,
}

export interface IEmailInvites {
	readonly league_id: number,
	readonly invites: IEmail[]
}

// export interface ISocialShare {
// 	[x: string]: any
// }

export const postInvitesSendViaEmail = function* (action: ISagaAction<IEmailInvites>): SagaIterator {
	try {
		const { errors } = yield call(action.meta.api.Joins.invite, action.payload);

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}
		yield put(action.meta.actions.inviteViaEmailSuccess());

	} catch (e) {
		yield put(action.meta.actions.inviteViaEmailFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};

// export const socialShareSaga = function* (action: ISagaAction<ISocialShare>): SagaIterator {
//
// 	const { type, ...state } = action.payload;
//
// 	// const data = yield select(getYCIDataForSharing);
// 	console.log(type);
// 	if(type === 'tw'){
// 		shareTwitter({ ...state });
// 	}
//
// 	if(type === 'fb'){
// 		shareFB({ ...state });
// 	}
// };