import {
	find,
	isEmpty,
	pick,
	findIndex
} from 'lodash';
import { SagaIterator } from 'redux-saga';
import {
	call,
	put,
	select,
	take
} from 'redux-saga/effects';
import {
	IPrediction,
	ITournamentSave
} from '../../reducers/tournament';
import {
	ISagaAction,
	IStoreState,
	NumberOrNone
} from '../../types';
import {
	getSelectedGroupId,
	manageBodyClass,
	mergeQuestionsWithPredictions
} from '../../utils';
import ApiErrors from '../../utils/Api/ApiErrors';


export const TournamentAddFreePlayerSaga = function* (
	action: ISagaAction<{ driver_id: number }>
): SagaIterator {
	const { predictions, player_box_to_select, selected_group_id} = yield select( ( store: IStoreState ) => ({
		predictions         : store.tournament.predictions,
		selected_group_id   : store.tournament.selected_group_id,
		player_box_to_select: store.tournament.player_box_to_select
	}) );

	const { driver_id = 0 } = action.payload;
	const race_predictions: any = find( predictions, { race_id: selected_group_id } );

	if(race_predictions && race_predictions.podium.indexOf(driver_id) > -1) {
		return;
	}

	predictions.map( ( prediction: IPrediction ) => {
		if ( prediction.race_id === selected_group_id ) {

			if( player_box_to_select.cell) {
				prediction.podium[ player_box_to_select.cell ] = {
					id: driver_id,
					position: player_box_to_select.cell + 1
				};
			} else {
				const first_empty = findIndex(prediction.podium, podium => !podium.id);
				if (first_empty > -1) {
					prediction.podium[ first_empty ] = {
						id: driver_id,
						position: first_empty + 1
					};
				}
			}
		}
		return prediction;
	} );

	yield put( action.meta.actions.addTournamentFreePlayerSuccess( {
		predictions,
		player_box_to_select: {},
		is_pool_visible     : false,
	} ) );

	manageBodyClass( false );

};

export const TournamentRemoveFreePlayerSaga = function* (
	action: ISagaAction<{ driver_id: number, driver_index: number }>
): SagaIterator {
	const { predictions, selected_group_id} = yield select( ( store: IStoreState ) => ({
		predictions         : store.tournament.predictions,
		selected_group_id   : store.tournament.selected_group_id,
		player_box_to_select: store.tournament.player_box_to_select
	}) );
	const { driver_index = -1 } = action.payload;

	predictions.map( ( prediction: IPrediction ) => {
		if ( prediction.race_id === selected_group_id ) {
			prediction.podium[ driver_index ] = {};
		}
		return prediction;
	} );

	yield put( action.meta.actions.addTournamentFreePlayerSuccess( {
		predictions,
		player_box_to_select: {
			cell: driver_index
		},
		is_pool_visible     : false,
	} ) );

	manageBodyClass( false );
};

export const TournamentSaveMarginSaga = function* (
	action: ISagaAction<{ margin: number }>
): SagaIterator {
	const { predictions, selected_group_id} = yield select( ( store: IStoreState ) => ({
		predictions         : store.tournament.predictions,
		selected_group_id   : store.tournament.selected_group_id,
	}) );
	const { margin = +'00.0000' } = action.payload;

	predictions.map( ( prediction: IPrediction ) => {
		if ( prediction.race_id === selected_group_id ) {
			prediction.margin = margin;
		}
		return prediction;
	} );

	yield put( action.meta.actions.saveMarginSuccess( {
		predictions,
	} ) );
};

export const TournamentChangeActionSaga = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const options: any = action.payload;
	const change_actions = {
		setPlayerPoolVisible         : {
			action: action.meta.actions.setPlayerPoolVisible,
			pick  : [ 'is_visible', 'cell' ]
		},
		addTournamentFreePlayer      : {
			action: action.meta.actions.addTournamentFreePlayer,
			pick  : [ 'driver_id' ]
		},
		removeTournamentFreePlayer     : {
			action: action.meta.actions.removeTournamentFreePlayer,
			pick  : [ 'driver_id', 'driver_index' ]
		},
		saveMargin: {
			action: action.meta.actions.saveMargin,
			pick  : [ 'margin' ]
		}
	};

	const change_action = change_actions[ options.action ];
	const pick_options = pick( options, change_action.pick );
	yield put( change_action.action( pick_options ) );
	/*
	* For auto save.
	* */
	if ( options.auto_save ) {
		const { tournament } = yield select();
		const predictions = find( tournament.predictions, { race_id: tournament.selected_group_id } ) || {};
		yield put( action.meta.actions.saveTournamentPredictions( predictions ) )
	}

};

export const TournamentSaveGroupSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {

	const { tournament } = yield select();
	const answers = action.payload;
	if ( !answers || !answers.race_id ) {
		return;
	}

	try {
		const { result, errors } = yield call( action.meta.api.Tournament.save, answers );

		const is_error = !isEmpty( errors );
		if ( is_error ) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put( action.meta.actions.saveTournamentPredictionsSuccess( mergeQuestionsWithPredictions( tournament.predictions, result ) ) );

	}
	catch ( e ) {
		console.log( e );
		yield put( action.meta.actions.errorsGlobalError( e ) );
	}

	localStorage.setItem( 'predictions', '' );
};

export const TournamentGetPredictionsSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {

	yield take( action.meta.actions.fetchJSONSSuccess );

	const { tournament, groups } = yield select();
	try {
		const { result, errors } = yield call( action.meta.api.Tournament.predictions );

		const is_error = !isEmpty( errors );
		if ( is_error ) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}
		yield put( action.meta.actions.fetchTournamentPredictionsSuccess( mergeQuestionsWithPredictions( tournament.predictions, result ) ) );

		// const available_groups = getAvailableGroups(groups.groups, tournament.predictions);
		const selected_group_id = getSelectedGroupId( groups.groups, tournament.selected_group_id );

		// console.log(available_groups);

		yield put( action.meta.actions.setTournamentDefaults( {
			selected_group_id
		} ) );

		// yield put(action.meta.actions.fetchGroupsJSONSuccess({
		// 	groups: available_groups,
		// }));

	}
	catch ( e ) {
		console.log( e );
		yield put( action.meta.actions.errorsGlobalError( e ) );
	}

};


export const TournamentToDefaultStoreSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {
	const { tournament, groups } = yield select();
	const predictions = tournament.predictions;
	const cleared = predictions.map( ( prediction: IPrediction ) => ({
		...prediction,
		options : [],
		values  : [],
		squad_id: [],
	}) );

	yield put( action.meta.actions.setTournamentDefaultStore( {
		predictions      : cleared,
		selected_group_id: getSelectedGroupId( groups.groups, tournament.selected_group_id )
	} ) )
};

export const TournamentGetSnapshotSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {
	try {
		const options = action.payload ? {
			race_id: action.payload
		} : {};

		const { result, errors } = yield call( action.meta.api.Tournament.snapshot, options );

		const is_error = !isEmpty( errors );
		if ( is_error ) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put( action.meta.actions.fetchSnapshotSuccess( result ) );

	}
	catch ( e ) {
		console.log( e );
		yield put( action.meta.actions.errorsGlobalError( e ) );
	}

};

export const TournamentSetSelectedGroupIdSaga = function* (
	action: ISagaAction<ITournamentSave>
): SagaIterator {
	try {
		console.log(action);
		yield call(TournamentGetSnapshotSaga, action);
		yield put( action.meta.actions.setTournamentSelectedGroupIdSuccess( action.payload ) );
	} catch ( e ) {
		yield put( action.meta.actions.errorsGlobalError( e ) );
	}
};