import * as React from 'react';

import {connect}              from 'react-redux';
import {Redirect}             from "react-router";
import {MastHead, WithSubNav} from '../components';
import * as selectors         from '../modules/selectors'

import {IAuthState, IStoreState}     from '../modules/types';
import {isOffSeason}                 from "../modules/utils/dom_helpers";
import {GameTagLine, LandingWrapper} from './Landing';
import {RegistrationFormRecover}     from './Registration/RegistrationFormRecover';

/**
 * Registration page component
 */

class RegistrationRecoverPageComponent extends React.Component<IAuthState> {
	/**
	 * @ignore
	 */
	public render() {
		if(isOffSeason) {
			return  <Redirect to='/'/>
		}
		return (
			<WithSubNav backLink="/logout" backTitle='LOGOUT'>
				<LandingWrapper>
					<MastHead>
						<GameTagLine>
							Welcome to the official tipping competition of the Repco Supercars Championship
						</GameTagLine>
					</MastHead>
					<div>
						<RegistrationFormRecover />
					</div>
				</LandingWrapper>
			</WithSubNav>
		)
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.authStatus(state)
});

export const RegistrationRecover = connect(mapStateToProps, null)(RegistrationRecoverPageComponent);

export default RegistrationRecover;
