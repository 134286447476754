import styled from 'styled-components';
import arrow from '../../assets/img/icons/arrow-down.png';
import theme from '../../assets/css/theme';

export const SelectStyled = styled.select`
	height: 40px;
	width: 100%;
	font-weight: 500;
	background: #fff url(${arrow}) no-repeat right 10px center;
	box-shadow: none;
	color: #7E858A;
	text-align: left;
	transition: all 0.1s ease;
	outline: 0;
	outline-offset: 0;
	padding: 0 0.5em;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	text-align-last: left;
	border: 1px solid #EDEDED;
	margin-bottom: 12px;
	appearance: none;
	border-radius: 0;
	font-family: ${theme.secondary_font}, sans-serif;
	font-size: 14px;
	background-size: 24px;
	&:disabled {
		opacity: 0.75;
	}
	&::-ms-expand {
		display: none;
	}
`;

export default SelectStyled;
