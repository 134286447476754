import * as _       from 'lodash';
import * as React   from 'react';
import { connect }  from 'react-redux';
import { NavLink }  from 'react-router-dom';
import styled       from 'styled-components';
import theme        from '../../assets/css/theme';
import * as actions from '../../modules/actions';
import * as moment  from 'moment';

import { IFormProps, IRegFormState, IRegistrationsProps, IStoreState } from '../../modules/types'

import {
	Field,
	FormCreator,
	FormGlobalTitle,
	FormWrapper,
	LoadingSpinner,
	RegularButton,
	SecondaryButton,
	SelectStyled,
	GroupOptions,
	TickBoxStyled,
	// Notice
}                                           from '../../components';
import { ICountriesReducerState, ICountry } from '../../modules/reducers/countries';
import { ISquadsReducerState }              from '../../modules/reducers/squads';
import { IModelUser }                       from '../../modules/types/user';

const CancelButton = styled( NavLink )`
	display: block;
	${RegularButton} {
		background-color: white;
		color: ${theme.primary};
		border:1px solid ${theme.primary};
	}
`;

const RegistrationWrapper = styled.div`
	padding: 0 2em;
`;

const SecondaryButtonWithMargin = styled( SecondaryButton )`
	margin-bottom: 0.5em;
`;

const BirthDayWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const DOBSelect = styled( SelectStyled )`
	width: 32%;
`;

const TermsConditions = styled( NavLink )`
	color: #E81C19;
`;

interface IProps extends IRegistrationsProps {
	countries: ICountriesReducerState;
	squads: ISquadsReducerState;
	user: IModelUser,
	fetchCountriesJSON: () => void;
	fetchSquadsJSON: () => void;
}

class RegistrationFormComponent extends React.Component<IProps, IRegFormState> {

	public state = {
		team_name     : '',
		country       : '',
		postcode      : '',
		team_supported: '',
		gender        : '',
		birthday      : '',
		terms         : 0,
		day           : '1',
		month         : '1',
		year          : moment()
			.format( 'YYYY' ),
		current_year  : moment()
			.format( 'YYYY' ),
		mobile        : ''
	};

	constructor( props: IProps ) {
		super( props );

		_.bindAll( this, [
			'handleSubmitForm',
			'renderedFields',
		] )
	}

	private get countryOptions() {
		const { countries = [] } = this.props;
		return countries.map( ( country: ICountry, i: number ) => (
			<option key={`countries-${i}`} value={country.code}>
				{country.name}
			</option>
		) );
	}

	private get monthOptions() {
		const month_names = moment.months();
		const month_options = month_names.map( ( month: any, key: any ) => ({
			id  : key + 1,
			name: month
		}) );
		return month_options.map( ( month: any, i: number ) => (
			<option key={`month-${i}`} value={month.id}>
				{month.name}
			</option>
		) );
	}

	private get yearOptions() {
		const { current_year } = this.state;
		const minYears = 100;
		const last_year = Number( current_year ) - minYears;
		const years_list = [];
		for ( let i = Number( current_year ); i >= last_year; i-- ) {
			years_list.push( {
				id  : i,
				name: i
			} )
		}
		return years_list.map( ( year: any, i: number ) => (
			<option key={`year-${i}`} value={year.id}>
				{year.name}
			</option>
		) );
	}

	/**
	 * @ignore
	 */
	public dayOptions( month: any, year: any ) {
		const days_count = moment( `${year}-${month}`, 'YYYY-M' )
			.daysInMonth();
		const days_list = [];
		for ( let i = 1; i <= days_count; i++ ) {
			days_list.push( {
				id  : i,
				name: i
			} )
		}
		return days_list.map( ( day: any, i: number ) => (
			<option key={`year-${i}`} value={day.id}>
				{day.name}
			</option>
		) );
	}

	/**
	 * @ignore
	 */
	public componentWillMount(): void {
		this.props.fetchCountriesJSON();
	}

	/**
	 * Method to process registration form submission
	 */
	public handleSubmitForm( values: IRegFormState ) {
		const { userRegister, user } = this.props;
		const { day, month, year } = values;
		const birthday = moment( `${year}-${month}-${day}`, 'YYYY-M-D' )
			.toDate();
		const registration_values = _.pick( values, [
			'team_name',
			'country',
			'postcode',
			'team_supported',
			'gender',
			'terms',
			'mobile'
		] );
		const newUser = Object.assign( user, { ...registration_values }, { birthday } );
		userRegister( newUser );
	};

	/**
	 * Get Form Fields for Registration form
	 */
	public renderedFields( props: IFormProps, values: IRegFormState ) {
		let TAB_INDEX = 0;
		const { handleChange } = props;
		const {
			team_name = '',
			country = '',
			postcode = '',
			day = '',
			month = '',
			year = '',
			mobile = ''
		} = values;
		return (
			<RegistrationWrapper>
				<FormGlobalTitle>
					Let's get you registered
				</FormGlobalTitle>
				<Field
					type="text"
					id={_.uniqueId( 'team_name' )}
					name='team_name'
					placeholder="Create a username*"
					title="Invalid user name"
					defaultValue={team_name}
					pattern="^[a-zA-Z\s\d\-]{1,40}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<SelectStyled
					name="country"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
					value={country}
				>
					<option key='default' value='' disabled={true}>
						Please enter your country of residence
					</option>
					{this.countryOptions}
				</SelectStyled>
				{country === 'AU' && (
					<Field
						type="text"
						id={_.uniqueId( 'postcode' )}
						name='postcode'
						placeholder="Postcode*"
						title="Invalid postcode"
						defaultValue={postcode}
						pattern="^[\d]{1,4}$"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
					/>
				)}
				<Field
					type="text"
					id={_.uniqueId( 'mobile' )}
					name='mobile'
					placeholder="Mobile number*"
					title="Invalid mobile number"
					defaultValue={mobile}
					pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<BirthDayWrapper>
					<DOBSelect
						name="day"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
						value={day}
					>
						{this.dayOptions( month, year )}
					</DOBSelect>
					<DOBSelect
						name="month"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
						value={month}
					>
						{this.monthOptions}
					</DOBSelect>
					<DOBSelect
						name="year"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
						value={year}
					>
						{this.yearOptions}
					</DOBSelect>
				</BirthDayWrapper>
				<GroupOptions>
					<TickBoxStyled
						id={_.uniqueId()}
						name='gender'
						onChange={handleChange}
						tabIndex={++TAB_INDEX}
						tick_position="left"
						type="radio"
						value="male"
						required={true}
					>
						Male
					</TickBoxStyled>
					<TickBoxStyled
						id={_.uniqueId()}
						name='gender'
						onChange={handleChange}
						tabIndex={++TAB_INDEX}
						tick_position="left"
						type="radio"
						value="female"
						required={true}
					>
						Female
					</TickBoxStyled>
				</GroupOptions>
				<TickBoxStyled
					id={_.uniqueId()}
					name='terms'
					onChange={handleChange}
					required={true}
					tabIndex={++TAB_INDEX}
				>
					I have read and agree to the
					<TermsConditions to="/help/terms" target="_blank"> Terms and Conditions*</TermsConditions>
				</TickBoxStyled>

				<SecondaryButtonWithMargin type='submit'
				                           disabled={false}>REGISTER</SecondaryButtonWithMargin>
				<CancelButton to='/registration-first-step'>
					<RegularButton disabled={false}>BACK</RegularButton>
				</CancelButton>
			</RegistrationWrapper>
		)
	}

	/**
	 * @ignore
	 */
	public render() {
		const {
			team_name = '',
			country = '',
			postcode = '',
			gender = '',
			terms = 0,
			day = '',
			month = '',
			year = '',
			mobile = '',
		} = this.state;

		const { is_data_requested } = this.props;

		return is_data_requested ? (
			<FormWrapper>
				<LoadingSpinner with_cover_bg={true} position="center"/>
			</FormWrapper>
		) : (
			<FormCreator
				as={FormWrapper}
				onSubmit={this.handleSubmitForm}
				render={this.renderedFields}
				initialState={{
					team_name,
					country,
					postcode,
					day,
					month,
					year,
					gender,
					terms,
					mobile
				}}
			/>
		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
	is_data_requested: _.get( state, 'user.is_data_requested', false ),
	user             : state.user,
	countries        : state.countries,
});

const mapDispatchToProps = {
	userUpdateSuccess : actions.userUpdateSuccess,
	userRegister      : actions.userRegister,
	fetchCountriesJSON: actions.fetchCountriesJSON,
};


const mergeProps = ( stateProps: any, dispatchProps: any, ownProps: any ) => {
	const countries = stateProps.countries;
	const sorted_countries = _.chain( countries )
		.sortBy( ( country: ICountry ) => country.code !== 'CA' )
		.sortBy( ( country: ICountry ) => country.code !== 'US' )
		.value();

	return {
		...dispatchProps,
		...stateProps,
		...ownProps,
		countries: sorted_countries
	};
};


export const RegistrationFormSecond = connect( mapStateToProps, mapDispatchToProps, mergeProps )( RegistrationFormComponent );
export default RegistrationFormSecond;
