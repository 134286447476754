import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import landingLogo from '../../assets/img/landing_logo.png';
// import sponsorLogo from '../../assets/img/sponsor-white.png';
import { Content, Icon } from '../../components';
import { below } from '../../assets/css/media';

const MastHeadBackGround = styled.div`
    text-align: center;
    padding-top: 3em;
    padding-bottom: 0.5em;
    position: relative;
    z-index: 1;
    ${below.desktop`
        padding: 0;
	`}
`;

const MastHeadWrapper = styled.div`
	display: flex;
	position: relative;
`;

const MastHeadLogo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 1em 0 2em 0;
	img:first-child {
		max-width: 352px;
	}

	${below.desktop`
        img:first-child{
           max-width: 160px;
        }
	`}
`;

const MastHeadHeader = styled.div`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #20262A;
	text-transform: uppercase;
	a {
		padding: 1em;
		display: flex;
		align-items: center;
	}

	span {
		color: #fff;
	 	margin-right: 1em;
		font-family: ${theme.base_bold_font};
		font-size: 15px;
	}

	${below.desktop`
        span{
            display: none;
        }
	`}
`;

// const Separator = styled.div`
// 	width: 1px;
//     height: 100px;
//     background: #fff;
//     margin: 0 1em;
// `;

// const Sponsor = styled.div`
//     display: flex;
//     flex-direction: column;
//     text-align: left;
//     color: #fff;
//     span {
//     	margin-bottom: 10px;
//     }
//     img {
//     	max-width: 220px;
//     }
//     ${below.desktop`
//         img{
//            max-width: 130px;
//         }
// 	`}
// `;

interface IMastHead {
	readonly children?: React.ReactNode;
}

export const MastHead = ({children}: IMastHead) => (
	<MastHeadBackGround>
		<MastHeadWrapper>
			<Content>
				<MastHeadHeader>
					<Link to={'/help/guidelines'}>
						<span>how to play</span>
						<Icon name={'info'} size={'20'} color={'#7E858A'}/>
					</Link>
				</MastHeadHeader>
				<MastHeadLogo>
					<div>
						<img src={landingLogo} alt="logo"/>
					</div>
					{/* <Separator />
					<Sponsor>
						<span>POWERED BY:</span>
						<img src={sponsorLogo} alt="sponsor-logo"/>
					</Sponsor> */}
				</MastHeadLogo>
			</Content>
		</MastHeadWrapper>
		{children}
	</MastHeadBackGround>
);

export default MastHead;
