import * as React                   from 'react';
import { Link, LinkProps, NavLink, generatePath } from 'react-router-dom';

export const LeagueJoinLink = (props: LinkProps) =>
	<Link to="/leagues/join" {...props}/>;

export const LeagueCreateLink = (props: LinkProps) =>
	<Link to="/leagues/create" {...props}/>;

export const HomeRegistrationLink = (props: LinkProps) =>
	<NavLink to="/registration" {...props}/>;

export const HowToPlayLink = (props: LinkProps) =>
	<NavLink to="/help/guidelines" {...props}/>;

export const getLeaguesPath = (prefix_page: string, id: number) =>
	(generatePath(`/leagues/:id/${prefix_page}`, { id }));

export const LeagueInviteEmailLink = (id: number) => (props: LinkProps) =>
	<Link to={generatePath('/leagues/:id/invite/email', { id })} {...props}/>;

export const LeagueInviteLink = (id: number) => (props: LinkProps) =>
	<Link to={generatePath('/leagues/:id/invite', { id })} {...props}/>;

export const LeagueStandingsLink = (id: number) => (props: LinkProps) =>
	<Link to={generatePath('/leagues/:id/standings', { id })} {...props}/>;