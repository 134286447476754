import * as React                                             from 'react';
// import * as moment                                            from 'moment';
import * as _                                                 from 'lodash';
import { Redirect, RouterProps }                              from 'react-router';
import { connect }                                            from 'react-redux';
import { IFormProps, IRequestData, IShowLeague, IStoreState } from '../../modules/types';
import * as actions                                           from '../../modules/actions';
import { LeaguesNextMenuItems as items_menu }                 from '../../components/Navigations/menuItems'

import {
	ContentWithSidebar, FetchJsons,
	Field,
	FormCreator,
	FormGroup,
	LeagueFormWrapper,
	LoadingSpinner,
	OptionsStyled,
	PageTitle, PointsSystem,
	SecondaryButtonLeftSide,
	SelectStyled, SidebarAdComponent,
	WithSubNav,
} from '../../components';

import { ILeagueManageReduser, IRound, IRoundsReducerState } from '../../modules/reducers';

import { ILeagueLeaveReduser } from '../../modules/reducers/leagues';

interface ILeagueCreateState {
	id: number;
	name: string;
	privacy: string;
	start_round: number;
}

interface IDispatchProps extends IShowLeague {
	update: ( league: ILeagueCreateState ) => void;
	leave: ( league_id: number ) => void;
	clear_league: () => void;
}


interface IMapState {
	model_manage: ILeagueManageReduser,
	model_leave: ILeagueLeaveReduser,
	rounds: IRoundsReducerState
}

interface IOwnProps extends IRequestData {
	league_id: number,
	is_disabled: boolean,
	is_redirect: boolean,
}

interface ISetState {
	[ name: string ]: string | number;
}

type Props = IDispatchProps & IMapState & IOwnProps;
type State = ILeagueCreateState & ISetState;

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>])

class SettingsLeagueComponent extends React.Component<Props, State> {

	constructor( props: Props ) {
		super( props );

		_.bindAll( this, [
			'handleSubmitForm',
			'renderedFields'
		] )
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount() {
		const { show_league, league_id } = this.props;
		show_league( league_id );
	}

	/**
	 * Clear state of create league
	 */
	public componentWillUnmount() {
		const { clear_league } = this.props;

		clear_league();
	}

	/**
	 * Method to process registration form submission
	 */
	public handleSubmitForm( values: State ) {
		const { update, leave, model_manage: { league }, league_id } = this.props;

		if ( league.is_commissioner ) {
			update( {
				...values,
				id: league_id
			} );
		}
		else {
			leave( league_id )
		}
	};

	private get roundsOptions() {
		const { rounds } = this.props;

		return rounds.map( ( round: IRound, i: number ) => (
			<option key={i} value={round.id}>
				{`Day ${round.id}`}
			</option>
		) )
	}

	/**
	 * @ignore
	 */
	public renderedFields( props: IFormProps, values: State ) {
		const MAX_LEAGUE_NAME = 40;
		let TAB_INDEX = 0;
		const { is_disabled, model_manage: { league } } = this.props;
		const { handleChange } = props;
		return (<>
			<FormGroup
				title="What is your mini league's name?"
				tooltip="Max 40 characters. Inappropriate league names will be deleted and replaced with your team name."
			>
				<Field
					type="text"
					id='name'
					name='name'
					placeholder="Enter a name for your league*"
					title="Max 40 characters. Inappropriate league names will be deleted and replaced with your team name."
					defaultValue={values.name}
					pattern="^{3,40}$"
					tabIndex={++TAB_INDEX}
					maxLength={MAX_LEAGUE_NAME}
					onChange={handleChange}
					disabled={is_disabled}
					required={true}
				/>
			</FormGroup>

			<FormGroup
				title="What is your mini league's privacy setting?"
				tooltip="Private leagues can only be joined with a
					 league PIN provided by the Chairman.
					 Public leagues are visible to everyone and
					  will not require a PIN or invite"
			>
				<OptionsStyled
					name="privacy"
					tabIndex={TAB_INDEX}
					type="radio"
					items={[ 'public', 'private' ]}
					defaultValue={values.privacy}
					onChange={handleChange}
					disabled={is_disabled}
					required={true}
				/>
			</FormGroup>

			<FormGroup
				title="When would you like your mini league to start?"
				tooltip="This is when the League will close for
					 entry and start scoring. You can select any future
					 round as your preferred start round."
			>
				<SelectStyled
					name="start_round"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					defaultValue={`${values.start_round}`}
					disabled={is_disabled}
					required={true}
				>
					{this.roundsOptions}
				</SelectStyled>
			</FormGroup>

			<SecondaryButtonLeftSide type='submit' margin={`0 0 12px`}>
				{league.is_commissioner ? 'update my league' : 'Leave this league'}
			</SecondaryButtonLeftSide>
		</>)
	}

	private get formCreateLeague() {

		const { is_data_requested } = this.props;
		const { model_manage } = this.props;
		const {
			name,
			privacy,
			start_round,
		} = model_manage.league;

		const initialState = {
			name,
			privacy,
			start_round,
			is_executed: start_round
		};
		return is_data_requested ? null : (
			<FormCreator
				as={LeagueFormWrapper}
				onSubmit={this.handleSubmitForm}
				render={this.renderedFields}
				initialState={initialState}
			/>
		);
	}

	/**
	 * @ignore
	 */
	public render() {

		const {
			is_data_requested,
			is_redirect,
			model_manage: { league }
		} = this.props;

		if ( is_redirect ) {
			return <Redirect to="/leagues"/>
		}

		return (
			<WithSubNav backLink="/leagues" backTitle={league.name} items_menu={items_menu}>
				<FetchJsons/>
				<ContentWithSidebar
					sidebar_components={[Ads]}
				>
					<PageTitle>
						League Settings
					</PageTitle>
					{is_data_requested ? (
						<LoadingSpinner with_cover_bg={true} position="center"/>
					) : null}
					{this.formCreateLeague}
				</ContentWithSidebar>
			</WithSubNav>
		)
	}
}

const mapStateToProps = ( state: IStoreState ): IMapState => ({
	model_manage: state.league_manage,
	model_leave : state.league_leave,
	rounds      : state.rounds,
});

const mapDispatchToProps: IDispatchProps = {
	clear_league: actions.clearLeagueCreate,
	show_league : actions.showLeague,
	update      : actions.updateLeague,
	leave       : actions.leaveLeague,
};

const mergeProps = ( stateProps: IMapState, dispatchProps: IDispatchProps, ownProps: RouterProps ) => {
	const { rounds, model_manage, model_leave } = stateProps;
	const league_id = _.get( ownProps, 'match.params.id' );
	const requests = [
		model_manage,
		model_leave,
	];

	const is_disabled = !_.eq( _.get( model_manage, 'league.status' ), 'scheduled' )
		|| !model_manage.league.is_commissioner;

	return {
		...stateProps,
		...ownProps,
		...dispatchProps,
		rounds           : _.filter( rounds, r => r.status === 'scheduled' ),
		league_id,
		is_disabled,
		is_data_requested: _.some( requests, r => r.is_data_requested ),
		is_redirect      : model_leave.is_success || model_manage.is_error
	};
};

export const SettingsLeague = connect( mapStateToProps, mapDispatchToProps, mergeProps )( SettingsLeagueComponent );