import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface IMatch {
	away_score: number | null;
	away_squad_id: number | null;
	away_squad_name: string | null;
	away_squad_odds: number;
	away_squad_odds_id: string;
	away_squad_short_name: string;
	completed_at: string;
	date: string;
	home_score: number | null;
	home_squad_id: number | null;
	home_squad_name: string | null;
	home_squad_odds: number;
	home_squad_odds_id: string;
	home_squad_short_name: string;
	id: number;
	is_first: number;
	is_last: number;
	is_margin_game: number;
	match: number;
	real_round: number;
	round: number;
	status: string;
	venue_id: number;
	venue_name: string;
}

export interface IRound {
	bye_squads: number[];
	end: string;
	id: number;
	is_bye: number;
	lifted_at: number;
	matches: IMatch[];
	start: string;
	status: string;
}

export type IRoundsReducerState = IRound[];

const initialState: IRoundsReducerState = [];

export const rounds = createReducer<IRoundsReducerState>( {}, initialState );

rounds.on( actions.fetchRoundsJSONSuccess, ( state: IRoundsReducerState, payload: IRoundsReducerState ) => (payload) );
rounds.on( actions.fetchRoundsJSONFailed, () => (initialState) );

