import * as React                                       from 'react';
import { PointsSystem, SidebarAdComponent, WithSubNav } from '../../components';
import { HelpMenuItems as items_menu }                  from '../../components/Navigations/menuItems';
import { connect }                                      from 'react-redux';
import actions                                          from '../../modules/actions';
import { IStoreState }                                  from '../../modules/types';
import { IGuideline }                                   from '../../modules/reducers/help';
import { Help }                                         from './';

class HelpGuideClass extends React.Component<any, any> {
	get guidelines() {
		const { guidelines } = this.props;
		return guidelines.map( ( guideline: IGuideline, key: number ) => (
			<div className='help-item' key={`guide-${key}`}>
				<div className='title'>{guideline.title}</div>
				<div className='content' dangerouslySetInnerHTML={{ __html: guideline.content }}/>
			</div>
		) )
	}

	/**
	 * @ignore
	 */
	public componentWillMount() {
		this.props.fetchGuidelines();
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<WithSubNav backLink="/" backTitle='HELP' items_menu={items_menu}>
				<Help>
					<section>
						<div className='help-title'>
							Guidelines
						</div>
						{this.guidelines}
					</section>
					<aside>
						<SidebarAdComponent/>
						<PointsSystem key='points'/>
					</aside>
				</Help>
			</WithSubNav>

		);
	}
}


const mapStateToProps = ( state: IStoreState ) => ({
	guidelines: state.help.guidelines
});

const mapDispatchToProps = {
	fetchGuidelines: actions.fetchGuidelines
};

export const HelpGuide = connect( mapStateToProps, mapDispatchToProps )( HelpGuideClass );

export default HelpGuide;