import { find, last }         from 'lodash';
import { SagaIterator }              from 'redux-saga';
import { call, put, select }         from 'redux-saga/effects';
import { ISagaAction, NumberOrNone } from '../../types';

export const isActualRound = ( { status }: { status: string } ) => (
	status === 'active' || status === 'scheduled'
);

export const GroupsFetchSaga = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {

	const groups = yield call( action.meta.api.JSON_SPORT.groups );
	const { tournament } = yield select();

	if ( groups.error ) {
		yield put( action.meta.actions.fetchGroupsJSONFailed( { message: groups.error } ) );
		return;
	}
	const actual_group: any = find( groups, isActualRound ) || last( groups );

	const selected_group_id = !tournament.selected_group_id ? actual_group.id : tournament.selected_group_id;

	const default_predictions = groups.map( (group: any) => (
		{
			race_id: group.id,
			margin: 0,
			podium: [{}, {}, {}]
		}
	) );

	yield put( action.meta.actions.setTournamentDefaults( {
		predictions: default_predictions,
		selected_group_id
	} ) );

	yield put( action.meta.actions.fetchGroupsJSONSuccess( {
		groups,
		actual_group,
	} ) );

};

export default GroupsFetchSaga;
