import * as _ from 'lodash';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { above, below } from "../../assets/css/media";
import theme from "../../assets/css/theme";

import { connect } from "react-redux";
import { authStatus } from "../../modules/selectors";
import { IStoreState } from "../../modules/types";

import logo from '../../assets/img/header_logo.png';
import logoMobile from '../../assets/img/logo-header-mobile.jpg';

import { manageBodyClass } from '../../modules/utils/dom_helpers'

import {
	Content,
	Icon,
	NavigationMobile
} from '../';

const HeaderWrapper = styled.div`
	background: ${theme.header_bg};
	color: white;
	padding: 0.5em 0;
	border-bottom: 3px solid ${theme.primary};
	${below.desktop`
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 3;
	`}
`;

const LogoImage = styled.img`
	height: 60px;
	${below.desktop`
		display: none;
	`}
`;

const LogoImageSmall = styled.img`
	max-width: 114px;
	${above.desktop`
		display: none;
	`}
`;

// const SponsorImage = styled.img`
// 	max-width: 135px;
// 	${below.desktop`
// 		margin-right: auto;
// 	`}
// `;

const ContentHeader = styled(Content)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.5em;
	${below.desktop`
		padding: 0 0.5em;
	`}
	${above.desktop`
		svg{
			display: none
		}
	`}
`;
const IconWrapper = styled.div`
	cursor: pointer;
	user-select: none;
	svg{
		cursor: pointer;
		user-select: none;
	}
	${below.desktop`
		margin-right: auto;
	`}
`;

const Separator = styled.div`
	min-height: 70px;
	width: 1px;
	background: #EDF0F2;
	margin: 0 5px 0 13px;
	${above.desktop`
		display: none;
	`}
`;

const HeaderPadding = styled.div`
	${below.desktop`
		padding-top: 89px;
	`}
`;

const LogoLink = styled(Link)`
	${above.desktop`
		margin-right: auto;
	`}
`;

interface IModelHeader {
	readonly is_logged_in: boolean;
	readonly force_show?: boolean;
}

class HeaderComponent extends React.Component<RouteComponentProps & IModelHeader> {

	public state = {
		is_open_mobile_menu: false,
	};

	/**
	 * Change state when  the location changes
	 */
	public componentDidMount() {
		const { history } = this.props;
		history.listen(e => this.handleToggleMenu(false));
	}

	/**
	 * Make state is open or hide
	 */
	public handleToggleMenu = (state: boolean): void => {
		manageBodyClass(state);
		try {
			this.setState({
				is_open_mobile_menu: state
			});
		} catch (e) {
			console.log(e)
		}

	};

	/**
	 * @ignore
	 */
	public render() {
		const { is_open_mobile_menu } = this.state;
		const { is_logged_in, force_show = false} = this.props;

		return is_logged_in || force_show ? (
			<HeaderPadding>
				<HeaderWrapper>
					<ContentHeader no_mobile_padding={true}>
						<IconWrapper onClick={_.partial(this.handleToggleMenu, !is_open_mobile_menu)}>
							{is_open_mobile_menu ? (
								<Icon name={'close-icon'} color={'#E8351A'}/>
							) : (
								<Icon name={'bars-solid'} color={'#E8351A'}/>
							)}

						</IconWrapper>
						<LogoLink to="/">
							<LogoImage src={logo} alt='super cars header logo'/>
							<LogoImageSmall src={logoMobile} alt='super cars header logo'/>
						</LogoLink>
						<Separator />
					</ContentHeader>
				</HeaderWrapper>

				<NavigationMobile
					close={_.partial(this.handleToggleMenu, false)}
					is_open_mobile_menu={is_open_mobile_menu}/>

			</HeaderPadding>
		) : null
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: authStatus(state),
});

export const Header = withRouter(connect(mapStateToProps)(HeaderComponent));
export default Header;
