import styled from 'styled-components';
import { below } from '../../assets/css/media';
import theme from '../../assets/css/theme';

interface IPageTitle {
	align?: 'center' | 'flex-start' | 'flex-end'
}

export const PageTitle = styled.div`
	font-size: 1.75em;
	padding: 1em 0;
	font-family: ${theme.base_bold_font};
	color: ${theme.base_text_color};
	display: flex;
	justify-content: ${({ align }: IPageTitle) => align || 'flex-start'};
	text-align: ${({ align }: IPageTitle) => align || 'left'};
	${below.desktop`
		font-size: 1.375em;
	`}
`;

export default PageTitle;