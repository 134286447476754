import * as React from 'react';
import styled from 'styled-components';
// import theme                                            from '../assets/css/theme';
import { above } from '../assets/css/media';
import theme from "../assets/css/theme";
import { MastHead } from '../components';
import { IGroupReducerState } from '../modules/reducers/groups';
import { IRoundsReducerState } from '../modules/reducers/rounds';
import { ISquadsReducerState } from '../modules/reducers/squads';
import { ITournamentState } from '../modules/reducers/tournament';
import { isOffSeason } from "../modules/utils/dom_helpers";
// import { Bling as GPT }                 from 'react-gpt';
import {
	LandingWrapper,
	GameTagLine
} from './Landing';
import { LoginForm } from './Login/LoginForm';


const LoginWrapper = styled.div`
	${above.desktop`
		padding-bottom: 30px;
	`}
`;
const ComingSoon = styled.div`
	color: #fff;
	padding: 1em;
    font-size: 2em;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    font-family: ${theme.base_bold_font};
    
`;

interface IMapStateProps {
	readonly groups: IGroupReducerState
	readonly squads: ISquadsReducerState
	readonly rounds: IRoundsReducerState
	readonly tournament: ITournamentState
}

interface IDispatchProps {
	fetchAllJSON: () => void
}

interface IMergedProps {
	readonly groups: IGroupReducerState;
	readonly squads: ISquadsReducerState;
	readonly playerPoolSquads: ISquadsReducerState;
	readonly fetchAllJSON: () => void;
	readonly tournament: ITournamentState;
	readonly isPoolAvailable: boolean;
}

type Props = IMapStateProps & IMergedProps & IDispatchProps

// GPT.configure( { renderWhenViewable: false } );

/**
 * Home page component
 */
class HomeComponent extends React.Component<Props> {

	/**
	 * @ignore
	 */
	public render() {
		return (
			<LandingWrapper>
				<MastHead>
					<GameTagLine>
						{isOffSeason ? `
							The Official Tipping Competition of the Repco Supercars Championship will be returning for 2022!
						` : `
							Welcome to the official tipping competition of the Repco Supercars Championship
						`}

					</GameTagLine>
				</MastHead>

				{isOffSeason ? (
					<ComingSoon>
						Supercars Tipping will be returning soon.
					</ComingSoon>
				) : (
					<LoginWrapper>
						<LoginForm/>
					</LoginWrapper>
				)}


				{/*{process.env.REACT_APP_COMING_SOON && (*/}
					{/*<ComingSoon>Coming Soon</ComingSoon>*/}
				{/*) || (*/}
					{/*<LoginWrapper>*/}
						{/*<LoginForm/>*/}
					{/*</LoginWrapper>*/}
				{/*)}*/}

			</LandingWrapper>
		)
	}
}

export const Home = HomeComponent;

export default Home;
