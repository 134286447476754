import * as _ from 'lodash';
import * as React from 'react';
import { matchPath, withRouter } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { above } from '../../assets/css/media';
import theme from '../../assets/css/theme';
import { NavItem as Item } from '../NavigationElements';
import { Content } from '../';
import { Items as items_menu } from './menuItems';
import SubMenuComponent from './SubMenu';

interface IOpenMenuState {
	readonly is_open?: boolean;
}

const CenterMobile = styled.div`
	display: flex;
	text-align: center;
	width: 100%;
	flex-wrap: wrap;
	font-family: ${theme.mobile_menu_font};
	a {
		flex: 1 1 100%;
	}
`;

const UserControls = styled(CenterMobile)`
	align-self: flex-end;
`;

const HeaderNavWrapper = styled.div`
	background: #fff;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
	transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	position: fixed;
	padding-top: 59px;
	width: 100%;
	top: 0;
	display: flex;
	overflow-y: auto;
	flex-wrap: wrap;
	padding-left: 0;
	box-shadow: 0 5px 5px 0 rgba(0,0,0,0.5);
	height: 100%;
	left: -100%;
	z-index: 2;
	max-width: 320px;
	scroll-behavior: smooth;
	${({ is_open }: IOpenMenuState) => (is_open ? 'left: 0' : '')}
`;

const NavLinkLogout = styled(Link)`
	background-color: red;
	color: #FFFFFF;
	font-weight: bold;
	font-size: 14px;
	padding: 1em; 
	text-transform: uppercase;
`;

const HeaderBgnWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	${ above.desktop`
		display: none;
	` }
`;

interface INavState {
	readonly is_open_mobile_menu: boolean;
	readonly close?: () => void;
}

type Props = INavState;

class NavigationMobileComponent extends React.Component<Props & RouteComponentProps> {

	public is_start: number = 0;
	public state = {
		leagues: this.isMatchPath('/leagues')
	};

	/**
	 * Set start position of cursor to detect move's state
	 */
	public handleTouch = (evt: React.SyntheticEvent): void => {
		this.is_start = _.get(this.getTouches(evt), '[0].clientX', false);
	};

	/**
	 * Detecting left swipe and close mobile menu
	 */
	public handleTouchMove = (evt: React.SyntheticEvent): void => {
		if (this.is_start) {
			const xup: number = _.get(evt, 'touches[0].clientX', 0);
			const { close } = this.props;
			const padding_left_offset = 100;
			const is_left = padding_left_offset < this.is_start - xup;

			if (is_left && _.isFunction(close)) {
				close();
			}
		}
	};

	/**
	 * Check is match path of locations and path parameter
	 */
	public isMatchPath(path?: string) {
		const { location } = this.props;
		return !!matchPath(location.pathname, { path })
	}

	/**
	 * Change state by key for each drop down
	 */
	public toggleState = (e: React.SyntheticEvent) => {
		const action = _.get(e, 'currentTarget.dataset.action', '');
		const state = _.get(this.state, action, false);

		if (action.length) {
			this.setState({
				[action]: !state
			})
		}

	};

	/**
	 * Method for getting state by key of dropdown
	 */
	public isActive(key: string): boolean {
		return this.isMatchPath('/leagues') || this.state[key]
	}

	public get siteMapMenu() {

		return (
			<CenterMobile>
				{items_menu.map((item: any, i) => (
					item.subMenu ? (
						<SubMenuComponent key={i} item={item} mobile={true}/>
					): (
						<Item key={i} exact={item.exact} to={item.link} {...item.options}>{item.name}</Item>
					)
				))}
			</CenterMobile>
		)
	}

	public get userControlsMenu() {
		return (
			<UserControls>
				<Item exact={true} to="/my-account">
					My Account
				</Item>
				<NavLinkLogout to="/logout">
					Log out
				</NavLinkLogout>
			</UserControls>
		)
	}

	/**
	 * Get touches coordinate
	 */
	public getTouches(evt: React.SyntheticEvent) {
		return _.get(evt, 'touches') || _.get(evt, 'originalEvent.touches');
	}

	/**
	 * @ignore
	 */
	public render() {
		const { is_open_mobile_menu } = this.props;
		return (
			<HeaderBgnWrapper>

				<Content>
					<HeaderNavWrapper
						onTouchStart={this.handleTouch}
						onTouchMove={this.handleTouchMove}
						is_open={is_open_mobile_menu}
					>
						{this.siteMapMenu}
						{this.userControlsMenu}
					</HeaderNavWrapper>
				</Content>

			</HeaderBgnWrapper>
		)
	}
}

export const NavigationMobile = withRouter(NavigationMobileComponent);

export default NavigationMobile;
