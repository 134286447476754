import styled from 'styled-components';
import { SecondaryButton } from '../'
import { below } from '../../assets/css/media';

interface IMargin {
	readonly margin?: string
}

export const SecondaryButtonLeftSide = styled(SecondaryButton)`
	max-width: 300px;
	margin: ${({ margin }: IMargin) => (margin || '2.5em auto 2.5em 0')};
	text-transform: uppercase;

	${below.desktop`
		font-size: 1em;
	`}
`;

export default SecondaryButtonLeftSide;