import 'core-js/es6/';
import styled, { createGlobalStyle } from 'styled-components';
import core from './assets/css/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import {
	Footer,
	GlobalErrors,
	Header,
	Logout,
	NotAuthRoute,
	PostponedBanner,
	PrivateRoute,
	ScrollToTop
} from './components'
import store from './modules/store';
import Home from './pages/Home';
import ForgotPassword from './pages/ForgotPasswordForm';
import RecoverPasswordForm from './pages/RecoverPasswordForm';
import NotFound from './pages/NotFound';
import RegistrationFirst from './pages/RegistrationFirst';
import RegistrationRecover from './pages/RegistrationRecover';
import RegistrationSecond from './pages/RegistrationSecond';
import HomePrivate from './pages/HomePrivate';
import MyAccount from './pages/MyAccount';
import {
	InvitesLeague,
	InvitesLeagueEmails,
	JoinLeagues,
	LeagueCreate,
	MyLeagues,
	SettingsLeague,
	StandingsLeague
} from './pages/Leagues';
import { ContactUs, HelpFAQ, HelpGuide, HelpPrizes, HelpTerms } from './pages/Help/';
import { Rankings } from './pages/Rankings';
import Unsubscribe from './pages/Unsubscribe';

const GlobalStyle = createGlobalStyle`${core}`;

const AppWrapper = styled.div`
	overflow: hidden;
`;
const StandingRedirect = () => <Redirect to="/rankings/season"/>;

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
			<AppWrapper>
				<ScrollToTop>
					<GlobalStyle/>
					<PostponedBanner/>
					<Header/>
					<Switch>

						<NotAuthRoute exact={true} path="/" component={Home}/>
						<NotAuthRoute exact={true} path="/registration-first-step"
						              component={RegistrationFirst}/>

						<NotAuthRoute exact={true} path="/registration-second-step"
						              component={RegistrationSecond}/>
						<NotAuthRoute exact={true} path="/forgot-password"
						              component={ForgotPassword}/>
						<NotAuthRoute exact={true} path="/password-recovery/:token"
						              component={RecoverPasswordForm}/>

						<PrivateRoute exact={true} path="/registration-recover"
						              component={RegistrationRecover}/>
						<PrivateRoute exact={true} path="/logout" component={Logout}/>
						<PrivateRoute exact={true} path="/test" component={Home}/>
						<PrivateRoute exact={true} path="/leagues" component={MyLeagues}/>
						<PrivateRoute exact={true} path="/leagues/create" component={LeagueCreate}/>
						<PrivateRoute exact={true} path="/leagues/created" component={MyLeagues}/>
						<PrivateRoute exact={true} path="/leagues/join" component={JoinLeagues}/>
						<PrivateRoute exact={true} path="/leagues/join/:code"
						              component={JoinLeagues}/>
						<PrivateRoute exact={true} path="/leagues/:id/settings"
						              component={SettingsLeague}/>
						<PrivateRoute exact={true} path="/leagues/:id/standings"
						              component={StandingsLeague}/>
						<PrivateRoute exact={true} path="/leagues/:id/invite"
						              component={InvitesLeague}/>
						<PrivateRoute exact={true} path="/leagues/:id/invite/email"
						              component={InvitesLeagueEmails}/>
						<PrivateRoute exact={true} path="/tipping" component={HomePrivate}/>
						<PrivateRoute exact={true} path="/rankings" component={
							StandingRedirect
						}/>
						<PrivateRoute exact={true} path="/rankings/:type" component={Rankings}/>

						<PrivateRoute exact={true} path="/my-account" component={MyAccount}/>

						<Route exact={true} path="/help/guidelines" component={HelpGuide}/>
						<Route exact={true} path="/help/terms" component={HelpTerms}/>
						<Route exact={true} path="/help/faq" component={HelpFAQ}/>
						<Route exact={true} path="/help/contact-us" component={ContactUs}/>
						<Route exact={true} path="/help/prizes" component={HelpPrizes}/>
						<Route exact={true} path="/unsubscribe" component={Unsubscribe}/>
						<Route component={NotFound}/>
					</Switch>
					<Footer/>
					<GlobalErrors/>
				</ScrollToTop>
			</AppWrapper>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

