import * as React                        from 'react';
import styled                            from 'styled-components';
// import TournamentDropDown                from './TournamentDropDown';
import { IPrediction, ITournamentState } from '../../modules/reducers/tournament';
import { connect }                from 'react-redux';
import * as actions               from '../../modules/actions';
import { IStoreState }            from '../../modules/types';
// import TournamentOptions          from './TournamentOptions';
// import TournamentSlider           from './TournamentSlider';
// import { IQuestion }              from '../../modules/reducers/groups';
// import { times, constant}         from 'lodash';
// import { TournamentOptionsMatch } from './TournamentOptionsMatch';
import { ISquad }                 from '../../modules/reducers/squads';

const QuestionWrapper = styled.div``;

const QuestionText = styled.div`
	font-family: TheSun-HeavyCondensed, sans-serif;
	font-size: 22px;
	padding: 1em 0;
	text-align: left;
	color: #fff;
`;

interface IQuestionProps  {
	title: string;
	prediction: IPrediction;
	tournament: ITournamentState;
	changeAction: (options: any) => void;
	isAutoSave: boolean;
	squads: ISquad[];
	locked: number;
}

export interface IQuestionComponentOptions {
	prediction?: IPrediction;
	id?: number;
	isLocked?: boolean;
	isShowResult?: boolean;
	isCorrect?: boolean;
	points?: number;
}

/*
* Test
* */
class QuestionClass extends React.Component<IQuestionProps, any> {
	get questionComponent() {
		return null;
		// const { prediction, tournament, squads, locked } = this.props;
		// const question_options: IQuestionComponentOptions = {
		// 	prediction,
		// 	id,
		// 	isLocked: Boolean(locked),
		// 	isShowResult: Boolean(prediction.has_answer && locked),
		// 	isCorrect: Boolean(prediction.correct),
		// 	points: prediction.question_points
		// };

		// const view_type = view + '_' + result;
		//
		// const QuestionComponents = {
		// 	dropdown_multiple_feed_squad: (
		// 			<TournamentDropDown
		// 				initialList={times(view_number, constant(0))}
		// 				callback={this.dropDownCallBack}
		// 				selectedBox={tournament.player_box_to_select}
		// 				{...question_options}
		// 			/>
		// 	),
		// 	dropdown_feed_squad: (
		// 		<TournamentDropDown
		// 			initialList={times(view_number, constant(0))}
		// 			callback={this.dropDownCallBack}
		// 			selectedBox={tournament.player_box_to_select}
		// 			{...question_options}
		// 		/>
		// 	),
		// 	binary_cms: (
		// 		<TournamentOptions
		// 			options={options}
		// 			callback={this.binaryCallback}
		// 			selectedValue={prediction.options[0]}
		// 			{...question_options}
		// 		/>
		// 	),
		// 	slider_feed_stat: (
		// 		<TournamentSlider
		// 			minValue={slider_range_from}
		// 			maxValue={slider_range_to}
		// 			value={prediction.values[0]}
		// 			callback={this.sliderCallback}
		// 			{...question_options}
		// 		/>
		// 	),
		// 	binary_feed_match: (
		// 		<TournamentOptionsMatch
		// 			match={match || {}}
		// 			squads={squads}
		// 			callback={this.binaryMatchCallback}
		// 			selectedSquadID={prediction.squad_id[0]}
		// 			{...question_options}
		// 		/>
		// 	)
		// };
		// return QuestionComponents[view_type] || null;
	}
	/**
	 * @ignore
	 */
	public render() {
		const { title } = this.props;
		return (
			<QuestionWrapper>
				<QuestionText>
					{title}
				</QuestionText>
				{this.questionComponent}
			</QuestionWrapper>
		)
	}
	/*
	* @ignore
	* */
	// private readonly dropDownCallBack = (player_cell_index: number, is_visible: boolean) => {
	// 	const { id, changeAction, locked } = this.props;
	//
	// 	if(locked ) {
	// 		return;
	// 	}
	// 	const visibility_options = is_visible ? {
	// 			is_visible: true,
	// 			question_id: id,
	// 			cell: player_cell_index,
	// 			action: 'setPlayerPoolVisible',
	// 			auto_save: false,
	// 		} : {
	// 		action: 'setPlayerPoolVisible'
	// 	};
	//
	// 	changeAction(visibility_options);
	// };
	/*
	* Binary callback
	* */
	// private readonly binaryCallback = (option_id: number) => {
	// 	const { id: question_id, changeAction, isAutoSave, locked } = this.props;
	// 	if(locked ) {
	// 		return;
	// 	}
	// 	changeAction({
	// 		question_id,
	// 		option_id,
	// 		action: 'setTournamentBinaryValue',
	// 		auto_save: isAutoSave,
	// 	});
	// };
	/*
	* Binary callback
	* */
	// private readonly sliderCallback = (slider_value: number) => {
	// 	const { id: question_id, changeAction, isAutoSave, locked } = this.props;
	// 	if(locked ) {
	// 		return;
	// 	}
	// 	changeAction({
	// 		question_id,
	// 		slider_value,
	// 		action: 'setTournamentSliderValue',
	// 		auto_save: isAutoSave,
	// 	});
	// };
	/*
	* Binary callback
	* */
	// private readonly binaryMatchCallback = (player_id: number) => {
	// 	const { id: question_id, changeAction, isAutoSave, locked } = this.props;
	// 	if(locked ) {
	// 		return;
	// 	}
	// 	changeAction({
	// 		question_id,
	// 		player_id,
	// 		action: 'setTournamentBinaryMatchValue',
	// 		auto_save: isAutoSave,
	// 	});
	// };
}

const mapStateToProps = (state: IStoreState) => ({
	tournament: state.tournament,
	squads: state.squads,
});

const mapDispatchToProps = {
	changeAction: actions.tournamentChangeAction
};

export const Question = connect(mapStateToProps, mapDispatchToProps)(QuestionClass);

export default Question;