import styled  from 'styled-components';
import Content from '../../components/Content';
import theme from '../../assets/css/theme';

export const Help = styled(Content)`
	padding: 20px 20px;
	display: flex;
	justify-content: space-between;
    
    section {
	    background: #fff;
	    border-radius: 5px;
	    box-shadow: 0 2px 10px 0 rgba(0,0,0,.35);
	    overflow: hidden;
    
	    .help-title {
	        font-size: 1.75em;
	        font-family: TheSun-HeavyCondensed, sans-serif;
		    padding: 25px 15px 20px;
		    color: #383d43;
		    display: flex;
		    justify-content: space-between;
	        border-bottom: 1px solid rgba(109,116,124,0.25);
	        background: #f9f9f9;
	    }
	    
	    .help-item {
	        padding: 1em;
	    }
	    
		.title {
			font-family: TheSun-HeavyCondensed, sans-serif;
			font-size: 1.5em;
			margin-bottom: .5em;
		}
		
		.content {
			margin-bottom: 1em;
		}
		
		h1, h2 {
			font-family: TheSun-HeavyCondensed, sans-serif;
		}
		
		h1 {
			font-size: 2em;
		}
		
		h2 {
			font-size: 2.5em;
		}
		
		i {
			font-style: italic;
		}
		
		b {
			font-weight: bold;
		}
		
		a {
			color: ${theme.primary}
		}
		
		table {
			width: 100%;
		}
		
		td {
			border: 1px solid rgb(204, 204, 204);
			padding: 5px;
			text-align: center;
		}
		
		ul {
			margin: 10px 0;
			list-style: disc;
			padding-left: 16px;
		}
	
		ol {
			margin: 10px 0;
			list-style: decimal;
			padding-left: 16px;
		}
		
		img {
			max-width: 100%;
			height: auto;
		}
	}
`;