import { IIcons } from '../Icons';

export interface IItem {
	readonly name: string,
	readonly link: string,
	readonly exact: boolean,
	readonly className?: string,
	readonly generate_path?: string,
}

export interface IItemWithSubMenu extends IItem {
	readonly subMenu: IItem[]
	readonly secondaryMenu?: IItem[]
	readonly options?: {
		isActive: () => boolean;
	},
	readonly icon?: IIcons;
}

export const LeaguesNextMenuItems: IItem[] = [
	{
		name: 'Standings',
		link: '/leagues/:id/standings',
		generate_path: 'id',
		exact: true
	},
	{
		name: 'invites',
		link: '/leagues/:id/invite',
		generate_path: 'id',
		exact: false
	},
	{
		name: 'Settings',
		link: '/leagues/:id/settings',
		generate_path: 'id',
		exact: true
	}
];

export const HelpMenuItems: IItem[] = [
	{
		name: 'Guidelines',
		link: '/help/guidelines',
		exact: true
	},
	{
		name: 'FAQ',
		link: '/help/faq',
		exact: true
	},
	{
		name: 'Terms & Conditions',
		link: '/help/terms',
		exact: true
	},
	{
		name: 'PRIZES',
		link: '/help/prizes',
		exact: true
	},
	{
		name: 'Contact Us',
		link: '/help/contact-us',
		exact: true
	},
];


export const Items: (IItemWithSubMenu | IItem)[] = [
	{
		name: 'Tipping',
		link: '/tipping',
		exact: true,
	},
	{
		name: 'LEAGUES',
		link: '/leagues',
		exact: true,
		subMenu: [
			{
				name: 'create a league',
				link: '/leagues/create',
				exact: true
			},
			{
				name: 'join a league',
				link: '/leagues/join',
				exact: true
			},
		],
	},
	{
		name: 'Rankings',
		link: '/rankings/season',
		exact: true
	},
	{
		name: 'Prizes',
		link: '/help/prizes',
		exact: true
	},
	{
		name: 'HELP',
		link: '/help/guidelines',
		exact: false
	},
];

export const user_controls_item: IItemWithSubMenu = {
		name: 'Settings',
		link: '/my-account',
		exact: true,
		icon: 'cog',
		subMenu: [
			{
				name: 'logout',
				link: '/logout',
				className: 'logout',
				exact: false
			},
		]
	}
;
