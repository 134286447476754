import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { partial } from 'lodash';

const is_postponed_banner_visible = !!JSON.parse(process.env.REACT_APP_POSTPONED || "false");

const PostponedBannerWrapper = styled.div`
	width: 100%;
	background: #E8351A;
	color: #FFFFFF;
	padding: 10px;
	font-family: ${theme.base_bold_font};
	text-align: center;
	line-height: 20px;
	height: 100%;
	
	>span{
		margin-left: 5px;
		cursor: pointer;
	}
`;

export const PostponedBanner: React.FC = () => {
	const [is_visible, setVisibilityState] = useState(true);

	if (!is_postponed_banner_visible) {
		return null;
	}

	return (
		is_visible ? <PostponedBannerWrapper>
			Supercars can't wait to go racing again. When we do, we'll update Supercars Tipping with all the revised
			race details as soon as we can so you can keep tipping. In the meantime, please keep yourselves and your
			families safe and well and we'll see you soon.
			<span onClick={partial(setVisibilityState, false)}>[Close]</span>
		</PostponedBannerWrapper> : null
	);
};

export default PostponedBanner;
