import * as _                from 'lodash';
import { SagaIterator }      from 'redux-saga';
import { call, put }         from 'redux-saga/effects';
import { ISagaAction }       from '../../types';
import { saveUserToStorage } from '../../utils/user';
import ApiErrors             from '../../utils/Api/ApiErrors';

export interface IRecoverRequest {
	readonly token: string,
	readonly password: string
}

export const postUserRecoverSaga = function* (action: ISagaAction<IRecoverRequest>): SagaIterator {
	try {
		const { result, errors } = yield call(action.meta.api.User.change_password, {
			token: _.get(action.payload, 'token', ''),
			password: _.get(action.payload, 'password', '')
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			yield put(action.meta.actions.userRecoverPasswFailed(errors[0].code));
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		saveUserToStorage(result.user, result.session_id);
		yield put(action.meta.actions.userLoginSuccess(result.user));

		yield put(action.meta.actions.userRecoverPasswSuccess());

	} catch (e) {
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};