import * as React from 'react';
import * as selectors from '../modules/selectors'

import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { MastHead } from '../components';
import { isOffSeason } from "../modules/utils/dom_helpers";
import { RegistrationFormFirst } from './Registration/RegistrationFormFirst';
import { LandingWrapper, GameTagLine } from './Landing';
import { IAuthState, IStoreState } from '../modules/types';

/**
 * Registration page component
 */

class RegistrationPageComponent extends React.Component<IAuthState> {
	/**
	 * @ignore
	 */

	public render() {
		if(isOffSeason) {
			return  <Redirect to='/'/>
		}
		return (
			<LandingWrapper>
				<MastHead>
					<GameTagLine>
						Welcome to the official tipping competition of the Repco Supercars Championship
					</GameTagLine>
				</MastHead>
				<div>
					<RegistrationFormFirst />
				</div>
			</LandingWrapper>
		)
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.authStatus(state)
});

export const RegistrationFirst = connect(mapStateToProps, null)(RegistrationPageComponent);

export default RegistrationFirst;
