import * as React       from 'react';
import styled           from 'styled-components';
import long_ad          from '../../assets/img/long-ad.png';
import long_ad_festive from '../../assets/img/long-ad-festive.jpg';
import mob_ad_festive from '../../assets/img/mob-ad-festive.jpg';
import sidebar_ad       from '../../assets/img/sidebar-ad.png';
import festive_sidebar_ad from '../../assets/img/sidebar-ad-festive.jpg';
import { below, above } from '../../assets/css/media';

const LongAd = styled.a`
	display: block;
	width: 93%;
	margin: 1em auto;
	img {
		width: 100%;
	}
`;

const Mobile = styled.img`
	${above.desktop`
		display: none;
	`}
`;
const Desktop = styled.img`
	${below.desktop`
		display: none;
	`}
`;

const IS_FESTIVE = false;

const desktopImage = IS_FESTIVE ? long_ad_festive : long_ad;
const mobileImage = IS_FESTIVE ? mob_ad_festive : long_ad;

export const LongAdComponent = () => (
	<LongAd href='https://www.bunnings.com.au/trade' target='_blank'>
		<Desktop src={desktopImage} alt="Ad image"/>
		<Mobile src={mobileImage} alt="Ad image"/>
	</LongAd>

);


const SideBarAd = styled.a`
	display: block;
	width: 100%;
	margin-bottom: 1em;
	img {
		width: 100%;
	}
`;

const sideBarAd = IS_FESTIVE ? festive_sidebar_ad : sidebar_ad

export const SidebarAdComponent = () => (
	<SideBarAd href=' https://www.bunnings.com.au/trade'>
		<img src={sideBarAd} alt="Ads"/>
	</SideBarAd>
);
