import * as React              from 'react';
import * as _                  from 'lodash';
import { connect }             from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled, { css }         from 'styled-components';

import { LoadingSpinner, SelectStyled } from '../../../components'
import { IStoreState }                                      from '../../../modules/types';
import * as actions                                         from '../../../modules/actions';
import {
	ILadderItem,
	ILeagueManageReduser,
	Ladder as LadderType
}                                                           from '../../../modules/reducers/leagues';
import { getActualRoundID }                                 from '../../../modules/selectors';

import theme                   from '../../../assets/css/theme';
import drop_down_green         from '../../../assets/img/icons/dropdown-green.png';
import { IRoundsReducerState } from '../../../modules/reducers/rounds';
import { below }               from '../../../assets/css/media';
import { NavLink }             from 'react-router-dom';


interface IMapStateProps {
	readonly model_manage: ILeagueManageReduser
	readonly league_ladder: LadderType
	readonly actual_round_id: number
	readonly rounds: IRoundsReducerState
}

interface IOwnProps extends RouteComponentProps {
	readonly league_id?: number;
	readonly is_by_round?: boolean;
	readonly is_mass_league?: boolean;
	readonly is_rankings?: boolean;
}

interface IMergedProps extends IOwnProps {
	readonly is_need_to_fetch: boolean;
}

interface IDispatchProps {
	getLadder: ( p: any ) => void;
	clearLadder: () => void;
	loadMore: () => void;
}

// const slideIn = keyframes`
//   from {
//     transform: translateY(-25%)
//   }
//
//   to {
//     transform: translateY(0);
//   }
// `;

const Name = styled.div`
	display: flex;
	font-family: ${theme.base_bold_font};
`;

const Rank = styled.div`
	padding: 0 1em;
	text-align: center;
	font-family: ${theme.base_bold_font};
`;

const Points = styled( Rank )``;

interface IItemRow {
	readonly is_own?: boolean
}

const LadderStyled = styled.div`
	background: ${theme.ladder_bg_item};
`;

const ItemLadder = styled.div`
	background: ${( { is_own }: IItemRow ) => (is_own ? '#7E858A' : theme.ladder_bg_item)};
	display: flex;
	align-items: center;
	height: 60px;
	border: 1px solid #EDF0F2;
	font-family: ${theme.base_font};
	color: ${( { is_own }: IItemRow ) => (is_own ? '#fff' : '#333F48')};
`;

const LoadMore = styled.div`
	display: block;
	padding: 2em 0 ;
	width: 100%;
	text-align: center;
	
`;

const FooterLadder = styled.div`
	background: ${theme.ladder_bg_item};
	padding: 2em 1em 1em;
	color: white;
	text-align: center;
	font-family: ${theme.base_font};
	cursor: pointer;
	p {
		padding-top: 0.25em;
	}
`;

const LadderHeader = styled.div<IEnduro>`
	background: ${({isEnduro}) => isEnduro ? '#497495' : '#333F48'};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #fff;
`;

const LadderCell = styled.div`
	font-family: ${theme.base_font};
	width: 14%;
	font-size: 12px;
    letter-spacing: 2px;
	text-align:  ${({align}: ILadderHeaderItem) => align || 'center' };
	flex: ${({flex}: ILadderHeaderItem) => flex || 'inherit' }
	${below.desktop`
		width: ${({mobile_width}: ILadderHeaderItem) => mobile_width || '17%' }
	`}
`;

const TopSection = styled.div`
	display: flex;
	align-items: stretch;
	${below.desktop`
		flex-direction: column;
	`}
`;
const LeftSection = styled.div`
	flex: 1;
	${below.desktop`
		padding: 0 20px
	`}
`;
const RightSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const Title = styled.div`
	margin-bottom: 20px;
	font-family: ${theme.base_bold_font};
	font-size: 25px;
	color: #333F48
	${below.desktop`
		margin: 0 0 20px 0;
	`}
`;

const SubTitle = styled.div`
	font-size: 14px;
	line-height: 20px;
	padding-bottom: 22px;
`;
const Tabs = styled.div`
	display: flex;
	margin-top: auto;
	${below.desktop`
		width: 100%;
	`}
`;
const TabLink = styled(NavLink)<ITab>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 36px;
	color: #fff;
	text-transform: uppercase;
	font-family: ${theme.base_bold_font};
	font-size: 12px;
	&.active {
		text-decoration: underline;
	}
	${({background}) => css`
		background: ${background || '#333F48'};
	`}
	${below.desktop`
		flex: 1;
	`}
`;
interface ITab {
	background?: string;
}
const Select = styled(SelectStyled)<IEnduro>`
	${({isEnduro}) => isEnduro && css`
		width: 80%;
		${below.desktop`
			width: 95%;
			margin: auto;
			margin-bottom: 40px
		`}
	`}
`;
interface IEnduro {
	isEnduro: number;
}

interface ILadderHeaderItem {
	flex?: number;
	align?: string;
	font?: string;
	mobile_width?: string;
}

type Props = IMapStateProps & IMergedProps & IDispatchProps & IOwnProps;

class LadderComponent extends React.Component<Props> {

	public state = {
		is_round_view: true,
		selected_round: 0,
		first_fetch: false,
		type: 'season'
	};
	private readonly roundDropDown: React.RefObject<HTMLSelectElement> = React.createRef();
	constructor( props: Props ) {
		super( props );
		_.bindAll( this, [
			'handleLoadMore',
			'fetchLadder'
		] )
	}

	/**
	 * Clear state of create league
	 */
	public componentWillUnmount() {
		const { clearLadder } = this.props;
		clearLadder();
	}

	/**
	 * Fetch league by id
	 */
	public componentWillMount(): void {
		const { getLadder, league_id, is_mass_league, actual_round_id, rounds, match, is_rankings } = this.props;
		const { params }: any = match;
		const is_enduro = params.type === 'enduro' ? 1 : 0;
		if(rounds.length && !this.state.first_fetch) {
			getLadder( {
				id   : league_id,
				is_mass_league,
				round: this.initial_round_id(actual_round_id, is_enduro),
				enduro: is_enduro,
				is_rankings
			} );
			this.setState( {
				is_round_view: !!actual_round_id,
				first_fetch: true,
				type: params.type
			} );
		}
	}

	/**
	 * get round id
	 */
	public initial_round_id(actual_round_id: number, is_enduro: number) {
		const enduro_round = 12;
		const round_id =is_enduro && actual_round_id <= enduro_round ? enduro_round : actual_round_id;
		return round_id;
	}

	/**
	 * Fetch league by id
	 */
	public componentWillReceiveProps(nextProps: Props) {

		const { getLadder, league_id, is_mass_league, actual_round_id, rounds, match, is_rankings } = nextProps;
		const { params }: any = match;
		const is_enduro = params.type === 'enduro' ? 1 : 0;
		if(params.type !== this.state.type || (rounds.length && !this.state.first_fetch)) {
			// console.log(this.initial_round_id(actual_round_id))
			this.props.clearLadder();
			getLadder( {
				id   : league_id,
				is_mass_league,
				round: this.initial_round_id(actual_round_id, is_enduro),
				enduro: is_enduro,
				is_rankings
			} );
			this.setState( {
				is_round_view: !!actual_round_id,
				first_fetch: true,
				type: params.type
			} );
		}

	}

	public get ladder() {

		const { league_ladder: { items, is_data_requested } } = this.props;
		const { is_round_view } = this.state;

		if ( is_data_requested ) {
			return <LoadingSpinner with_cover_bg={true} position="center"/>
		}

		return items && items.map( ( item: ILadderItem | null, i: number ) => item && (
			<ItemLadder is_own={item.is_own} key={i}>
				<LadderCell mobile_width='13%'>
					<Rank>
						{_.get( item, is_round_view ? 'round_rank' : 'overall_rank' ) || '0'}
					</Rank>
				</LadderCell>
				<LadderCell flex={1} align='left'>
					<Name>{`${item.first_name} ${item.last_name}`}</Name>
				</LadderCell>
				<LadderCell>
					{item.round_points}
				</LadderCell>
				<LadderCell>
					<Points>{item.overall_points}</Points>
				</LadderCell>
				<LadderCell>
					{item.overall_margin}
				</LadderCell>
			</ItemLadder>
		) )
	}

	get roundOptions() {
		const { rounds } = this.props;
		const is_enduro = this.isEnduro;
		const completed_rounds = _.filter(rounds, { status: 'complete'});
		const completed_enduro_rounds = _.filter(rounds, round => round.status === 'complete' && ['12', '13', '14', '15'].indexOf(String(round.id)) > -1);
		const dropdown_rounds = is_enduro ? completed_enduro_rounds : completed_rounds;
		return (
			<>
				<option value="0">Overall</option>
				{
					dropdown_rounds.map(round => (
						<option value={round.id} key={`round-${round.id}`}>Round {round.id}</option>
					))
				}
			</>
		);
	}

	get roundsDropDown() {
		const { actual_round_id, match } = this.props;
		const { params }: any = match;
		return (
			<Select value={this.state.selected_round || actual_round_id} onChange={this.changeRound} ref={this.roundDropDown as any} isEnduro={params.type}>
				{this.roundOptions}
			</Select>
		);
	}

	get ladderHeader() {
		return <LadderHeader isEnduro={this.isEnduro}>
			<LadderCell mobile_width='13%'>
				RANK
			</LadderCell>
			<LadderCell flex={1} align='left'>
				PLAYER NAME
			</LadderCell>
			<LadderCell>
				RD POINTS
			</LadderCell>
			<LadderCell>
				TOTAL POINTS
			</LadderCell>
			<LadderCell>
				MARGIN
			</LadderCell>
		</LadderHeader>
	}

	get tabs() {
		return this.props.is_rankings ? (
			<Tabs>
				<TabLink to='/rankings/season'>Season</TabLink>
				<TabLink to='/rankings/enduro' background={'#497495'}>Endurance</TabLink>
			</Tabs>
		) : null;
	}

	get titles() {
		const { match } = this.props;
		const { params }: any = match;
		const titles = {
			season: {
				title: 'Season Rankings',
				sub_title: 'Season Ranking points apply to every race of the 2022 Repco Supercars Championship.'
			},
			enduro: {
				title: 'Endurance Rankings',
				sub_title: 'Endurance Rankings are for Supercheap Auto Bathurst 1000, Vodafone Gold Coast 600 & Penrite Oil Sandown 500.'
			},
		};
		const title = titles[params.type];
		return (
			<>
				<Title>
					{ title.title }
				</Title>
				<SubTitle>
					{ title.sub_title }
				</SubTitle>
			</>
		)
	}

	get header() {
		const hide_tabs = true;
		return (
			<TopSection>
				{
					this.props.is_rankings && <LeftSection>
						{this.titles}
					</LeftSection>
				}
				<RightSection>
					{this.roundsDropDown}
					{!hide_tabs && this.tabs}
				</RightSection>
			</TopSection>
		);
	}

	get isEnduro() {
		const { match } = this.props;
		const { params }: any = match;
		return params.type === 'enduro' ? 1 : 0;
	}

	/**
	 * @ignore
	 */
	public changeRound = () => {
		const dropdown: any = this.roundDropDown.current || {};
		const value = dropdown.value;
		this.setState({
			selected_round: value
		});
		if(value) {
			this.fetchLadder(+value);
		} else {
			this.fetchLadder();
		}
	};

	/**
	 * @ignore
	 */
	public fetchLadder( round_id?: number ) {
		const { getLadder, league_id, clearLadder, is_mass_league, is_rankings } = this.props;
		clearLadder();
		getLadder( {
			id   : league_id,
			round: round_id ? round_id : 0,
			is_mass_league,
			enduro: this.isEnduro,
			is_rankings
		} );

		this.setState( {
			is_round_view: !!round_id
		} )
	}

	/**
	 * @ignore
	 */
	public render() {

		const { league_ladder } = this.props;
		return (
			<>
				{this.header}
				{this.ladderHeader}
				<LadderStyled>
					{this.ladder}
				</LadderStyled>
				{league_ladder.next && (
					<FooterLadder onClick={this.handleLoadMore}>
						<img src={drop_down_green} alt=""/>
						<p>Load more</p>
					</FooterLadder>
				)}
				<LoadMore/>
			</>
		)
	}

	private handleLoadMore() {
		const { loadMore } = this.props;
		loadMore();
	}
}

const mapStateToProps = ( state: IStoreState ) => (
	{
		model_manage   : state.league_manage,
		league_ladder  : state.league_ladder,
		rounds: state.rounds
	}
);

const mapDispatchToProps: IDispatchProps = {
	getLadder  : actions.getLeagueLadderData,
	clearLadder: actions.clearLeagueLadder,
	loadMore   : actions.getLeagueLadderDataMore,
};

const mergeProps = ( stateProps: IMapStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps ): Props => {
	const league_id = _.get( ownProps, 'league_id', 0 ) || _.get( ownProps, 'match.params.id', 0 );
	const is_need_to_fetch = !_.eq( stateProps.model_manage.league.id, parseInt( league_id, 10 ) );
	const default_round = getActualRoundID( stateProps ) || 0;
	const min_enduro_round = 12;
	const actual_round = ownProps.is_rankings && default_round < min_enduro_round ? 0 : default_round;
	return {
		...stateProps,
		...ownProps,
		...dispatchProps,
		league_id,
		is_need_to_fetch,
		actual_round_id: actual_round,
	};
};


export const Ladder = connect( mapStateToProps as any
	, mapDispatchToProps, mergeProps )( LadderComponent );
