import * as React       from 'react';
import { ILeagueState } from '../../../modules/reducers';
import styled           from 'styled-components';
import { Link }         from 'react-router-dom';

import settings_image from '../../../assets/img/icons/settings-dark.png';
import invite_image   from '../../../assets/img/icons/email-dark.png';
import about_image    from '../../../assets/img/icons/right-circle-arrow.png';

import { getLeaguesPath, LeagueData, LeagueInfo } from '../../../components';
import { ITimerFunctions }                        from '../../../modules/types';

interface ILeagueModel extends ITimerFunctions {
	league: ILeagueState
}

const LeagueWrapper = styled.div`
	margin-bottom: 1.5em;
`;

const ChairmanFeatures = styled.div`
	display: flex;
`;

const Icon = styled(Link)`
	width: 2em;
	height: 2em;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin-left: 10px;
`;

const SettingsLink = styled(Icon)`
	background-image: url(${settings_image});
`;

const InviteLink = styled(Icon)`
	background-image: url(${invite_image});
`;

const AboutLink = styled(Icon)`
	background-image: url(${about_image});
`;


export const League = ({ league, onCompleteTimer }: ILeagueModel) => league ? (
		<LeagueWrapper>
			<LeagueInfo league={league} is_name_link={true}>
				<ChairmanFeatures>
					{league.is_commissioner && (<>
							<SettingsLink to={getLeaguesPath('settings', league.id)}/>
							<InviteLink to={getLeaguesPath('invite', league.id)}/>
						</>
						)}
					<AboutLink to={getLeaguesPath('standings', league.id)}/>
				</ChairmanFeatures>
			</LeagueInfo>

			<LeagueData
				onCompleteTimer={onCompleteTimer}
				league={league}
			/>
		</LeagueWrapper>
	)
	: (
		null
	);