import * as React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../assets/css/theme';
import lock_icon from '../../assets/img/icons/lock-icon.png';
import { isMobile } from '../../modules/utils/dom_helpers';
import { below } from '../../assets/css/media';
import { every, some } from 'lodash';

const MarginWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 3em;
`;

const TimeBox = styled.div`
	height: 80px;
	width: 18%;
	border: 2px solid #EDEDED;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #7E858A;
	font-family: ${theme.base_bold_font};
	font-size: 15px;
	line-height: 22px;
`;

const TimeInput = styled.input`
	text-align: center;
	width: 70%;
	border: none;
	font-family: ${theme.base_bold_font};
	color: #7E858A;
	font-size: 15px;
	margin-left: 15px;
	&:focus {
		outline-width: 0;
	}
	${below.desktop`
		margin-left: 0;
	`}
`;

const TimePlaceholder = styled.div``;

const ResultBox = styled(TimeBox)`
	width: 60%;
	height: 80px;
	position: relative;
	${below.desktop`
		width: 52%;
		text-align: center;
	`}
	${({ isTransparent, isLocked, isProvisional, isFinal }: IResultBox) => {
	const opacity = isTransparent && css`
				opacity: .6;
			`;
	const locked = isLocked && css`
				width: 100%;
				background: #EDEDED;
			`;
	const provisional = some([isProvisional, isFinal]) && css`
				background: transparent;
				border: 2px solid #EDEDED;
			`;
	const final = isFinal && css`
			width: 48%;
			${below.desktop`
				width: 47%;
			`}
		`;
	return [opacity, locked, provisional, final];
}}
`;


const Image = styled.img`
	width: 35px;
	position: absolute;
	right: 13px;
`;

interface IResultBox {
	isTransparent: boolean;
	isLocked: boolean;
	isProvisional: boolean;
	isFinal: boolean;
}

interface IProps {
	callback: (seconds: string, milliseconds: string) => void;
	margin: string;
	isLocked: boolean;
	isProvisional: boolean;
	isFinal: boolean;
	actualMargin: string;
}

export class TimeMargin extends React.Component<IProps> {
	private readonly maxSeconds = 2;
	private readonly maxMilliSeconds = 4;
	private readonly secondsRef: React.RefObject<HTMLInputElement> = React.createRef();
	private readonly millisecondsRef: React.RefObject<HTMLInputElement> = React.createRef();

	get predictedText() {
		const { isLocked, isProvisional, isFinal } = this.props;
		let text = 'YOU PREDICT';
		if (isLocked) {
			text = 'YOU HAVE PREDICTED';
		}
		if (isProvisional) {
			text = 'YOUR PREDICTED MARGIN';
		}
		if (isFinal) {
			text = isMobile() ? 'YOUR' : 'YOUR PREDICTED MARGIN';
		}
		return text;
	}

	get lockedIcon() {
		const { isLocked, isProvisional, isFinal } = this.props;
		return every([!isProvisional, !isFinal, isLocked]) && (<Image src={lock_icon} alt='lock-icon'/>)
	}

	get resultBox() {
		const { isLocked, isProvisional, isFinal, margin } = this.props;
		return (
			<ResultBox
				isTransparent={!Boolean(+this.props.margin) && !isLocked}
				isLocked={isLocked}
				isProvisional={isProvisional}
				isFinal={isFinal}
			>
				<TimePlaceholder>{this.seconds(margin) || '00'}: {this.milliseconds(margin) || '0000'}</TimePlaceholder>
				<TimePlaceholder>{this.predictedText}</TimePlaceholder>
				{this.lockedIcon}
			</ResultBox>
		);
	}

	get actualResultBox() {
		const { isFinal, actualMargin } = this.props;
		return isFinal && (
			<ResultBox
				isTransparent={false}
				isLocked={false}
				isProvisional={true}
				isFinal={true}
			>
				<TimePlaceholder>{this.seconds(actualMargin) || '00'}: {this.milliseconds(actualMargin) || '0000'}</TimePlaceholder>
				<TimePlaceholder>{isMobile() ? 'ACTUAL' : 'ACTUAL MARGIN'}</TimePlaceholder>
			</ResultBox>
		);
	}


	get boxes() {
		const { isLocked } = this.props;
		const is_hide_input = isLocked;
		return (
			<>
				{!is_hide_input && (
					<TimeBox>
						<TimeInput type='number'
						           min='0'
						           max='99'
						           placeholder='&mdash;'
						           maxLength={this.maxSeconds}
						           onChange={this.getSaveValue}
						           ref={this.secondsRef as any}
						/>
						<TimePlaceholder>{isMobile() ? 'SECS' : 'SECONDS'}</TimePlaceholder>
					</TimeBox>
				)}
				{!is_hide_input && (
					<TimeBox>
						<TimeInput type='number'
						           min='0'
						           max='9999'
						           placeholder='&mdash;&mdash;&mdash;&mdash;'
						           maxLength={this.maxMilliSeconds}
						           onChange={this.getSaveValue}
						           ref={this.millisecondsRef as any}/>
						<TimePlaceholder>{isMobile() ? 'MSECS' : 'MILLISECONDS'}</TimePlaceholder>
					</TimeBox>
				)}
				{this.resultBox}
				{this.actualResultBox}
			</>
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<MarginWrapper>
				{this.boxes}
			</MarginWrapper>
		);
	}

	private seconds(margin: string) {
		const start = 0;
		const end = margin.indexOf('.');
		const seconds = end > -1 ? this.getMarginSubstring(margin, start, end) : margin;
		return seconds.length === 1 ? '0' + seconds : seconds;
	}

	private milliseconds(margin: string) {
		const start = margin.indexOf('.');
		const end = 7;
		const seconds = start > -1 ? this.getMarginSubstring(margin, start + 1, end) : '';
		return seconds.length === 1 ? '0' + seconds : seconds;
	}

	private getMarginSubstring(time: string, start: number, end: number) {
		return time.substring(start, end);
	}

	private validateInputString(parse_string: string, end: number) {
		const start = 0;
		if (parse_string.length > end) {
			return parse_string.slice(start, end);
		}
		return parse_string;
	}

	private readonly getSaveValue = () => {
		const end_seconds = 2;
		const end_milliseconds = 4;
		const seconds = this.secondsRef.current;
		const milliseconds = this.millisecondsRef.current;
		const seconds_val = seconds ? seconds.value : '';
		const milliseconds_val = milliseconds ? milliseconds.value : '';
		const parse_seconds = this.validateInputString(seconds_val, end_seconds);
		const parse_milliseconds = this.validateInputString(milliseconds_val, end_milliseconds);
		if (seconds) {
			seconds.value = parse_seconds;
		}
		if (milliseconds) {
			milliseconds.value = parse_milliseconds;
		}
		this.props.callback(parse_seconds, parse_milliseconds);
	}
}
