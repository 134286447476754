import * as React                                 from 'react';
import * as _                                     from 'lodash';
import * as actions                               from '../../../modules/actions';
import styled                                     from 'styled-components';
import { connect }                                from 'react-redux';
import { ISendRequest, IShowLeague, IStoreState } from '../../../modules/types';

import {
	ContentWithSidebar,
	Field,
	InviteContent,
	LeagueInviteLink,
	LoadingSpinner,
	PageTitle,
	RegularButtonLight,
	SecondaryButton,
	WithSubNav,
} from '../../../components';

import { IEmail, IEmailInvites } from '../../../modules/sagas/social_share';
import theme                     from '../../../assets/css/theme';
import { below }                 from '../../../assets/css/media';

import { LeaguesNextMenuItems as items_menu } from '../../../components/Navigations/menuItems'
import { ILeagueManageReduser }               from '../../../modules/reducers/leagues';
import { IInvitesReducer }                    from '../../../modules/reducers/social_share';
import { RouteComponentProps }                from 'react-router';

const InviteContentEmail = styled( InviteContent )`
	position: relative;
	max-width: 340px;
	${RegularButtonLight},
	${SecondaryButton}{
		max-width: 300px;
	}
	p {
		color: ${theme.primary};
	}
`;

const ButtonsContent = styled(InviteContent)`
	margin-top: 3em;

	${SecondaryButton} {
		margin-bottom: 0.5em;
	}

	${below.desktop`
		max-width: 100%
	`}
`;


interface IInvitesState {
	readonly invites: IEmail[]
}

interface IMapProps {
	readonly model_manage: ILeagueManageReduser
	readonly social_share: IInvitesReducer
}

interface IDispatcProps extends IShowLeague, ISendRequest<IEmailInvites> {
	readonly clear: () => void;
}

interface IMergedProps {
	readonly league_id: number
	readonly is_data_requested: boolean;
	readonly is_need_to_fetch: boolean
}

type Props = IMapProps & IDispatcProps & IMergedProps;

class InvitesLeagueEmailComponent extends React.Component<Props, IInvitesState>{

	public state = {
		invites: []
	};

	constructor(props: Props) {
		super(props);
		_.bindAll(this, [
			'handleChange',
			'handleAddField',
			'handleSubmit',
		])
	}

	private get backLink() {
		const { league_id } = this.props;
		return `/leagues/${league_id}/invite`;
	}

	/**
	 * @ignore
	 */
	public get emailFields(): React.ReactNode{
		let TAB_INDEX = 0;

		const { invites } = this.state;
		const { league_id, social_share, is_data_requested } = this.props;

		return (
			<InviteContentEmail>
				{is_data_requested ? <LoadingSpinner with_cover_bg={true} position="center"/> : null}
				<form onSubmit={this.handleSubmit}>
					{invites.map((invite: IEmail, i:number) => (
						<Field
							key={i}
							type="email"
							name={`email_${i}`}
							placeholder="Enter Email address"
							tabIndex={++TAB_INDEX}
							value={invite.email}
							onChange={this.handleChange(i)}
							required={+i === 0}
						/>
					))}
					<RegularButtonLight onClick={this.handleAddField}>add more</RegularButtonLight>

					<ButtonsContent>
						<SecondaryButton type="submit">SEND INVITES</SecondaryButton>
						<RegularButtonLight as={LeagueInviteLink(league_id)} >Cancel</RegularButtonLight>
					</ButtonsContent>
				</form>
				{social_share.is_success ? (
					<p>Messages were sent</p>
				) : null}

			</InviteContentEmail>
		)
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount(){
		const { show_league, league_id, is_need_to_fetch } = this.props;

		this.setDefaultField();
		if(is_need_to_fetch) {
			show_league(league_id);
		}
	}

	/**
	 * Triggered during unmount of component
	 */
	public componentWillUnmount(){
		this.clear();
	}

	/**
	 * Record all email addresses in state
	 */
	public handleChange(id: number) {
		return (e: React.SyntheticEvent<EventTarget>) => {

			const target = e.target;
			const value = _.result(target, 'value');
			const { invites: state_invites } = this.state;
			const invites: IEmail[] = (state_invites as IEmail[]).map((invite: any, sidx:number): IEmail =>
				id === sidx ? { ...invite, email: value } : invite
			);

			this.setState({
				invites,
			}, this.clear);
		};
	}


	/**
	 * To add one more email's field
	 */
	public handleAddField(){

		const invite: IEmail = {
			email: ''
		};
		const { invites = [] } =  this.state;

		this.setState({
			invites: (invites as IEmail[]).concat(invite),
		}, this.clear);
	}

	/**
	 * To add one more email's field
	 */
	public handleSubmit(e: React.SyntheticEvent<EventTarget>){
		const { send } = this.props;
		const { invites } = this.state;
		const result = _.filter(invites, (invite: IEmail) => !!invite.email);
		const { league_id } = this.props;

		if(result.length) {
			send({
				league_id,
				invites: result
			})
		}

		e.preventDefault();
	}

	/**
	 * Pre fill default fields
	 */
	public setDefaultField(){
		const MIN_COUNT_FIELDS = 3;
		const invite: IEmail = {
			email: ''
		};
		const default_invites = [];

		for(let i = 0; i < MIN_COUNT_FIELDS; i++){
			default_invites.push(invite);
		}

		this.setState({
			invites: default_invites,
		});

	}


	/**
	 * @ignore
	 */
	public render(){

		const { model_manage: { league } } = this.props;

		return (
			<WithSubNav backLink={this.backLink} backTitle="Email Invites" items_menu={items_menu}>
				<ContentWithSidebar>
					<PageTitle align="center">
						Invite someone to join {league.name}
					</PageTitle>
					{this.emailFields}
				</ContentWithSidebar>
			</WithSubNav>

		)
	}

	private clear() {
		const { clear } = this.props;

		clear();
	}
}


const mapStateToProps = (state: IStoreState): IMapProps => ({
	model_manage: state.league_manage,
	social_share: state.social_share
});

const mapDispatchToProps: IDispatcProps = {
	show_league: actions.showLeague,
	send: actions.inviteViaEmail,
	clear: actions.inviteViaEmailClear,
};

const mergeProps = (stateProps: IMapProps, dispatchProps: IDispatcProps, ownProps: RouteComponentProps) => {
	const league_id = _.get(ownProps, 'match.params.id');
	const is_need_to_fetch = !_.eq(stateProps.model_manage.league.id, parseInt(league_id, 10));
	const {  model_manage, social_share } = stateProps;

	return {
		...stateProps,
		...ownProps,
		...dispatchProps,
		league_id,
		is_need_to_fetch,
		is_data_requested: _.some([social_share, model_manage], r => r.is_data_requested),
	};
};


export const InvitesLeagueEmails = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InvitesLeagueEmailComponent);