import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface IResult {
	driver_id: number;
	position: number;
	time: number;
}

export type IResultsReducerState = IResult[];

const initialState: IResultsReducerState = [];

export const results = createReducer<IResultsReducerState>( {}, initialState );

results.on( actions.fetchResultsSuccess, ( state: IResultsReducerState, payload: IResultsReducerState ) => (payload) );
results.on( actions.fetchResultsFailed, () => (initialState) );