import * as React              from 'react';
import { Ladder }              from './Leagues/Ladder';
import { RouteComponentProps } from 'react-router';
import {
	ContentWithSidebar, FetchJsons, PointsSystem,
	SidebarAdComponent,
	WithMainNav
}                              from '../components';
// import styled                  from 'styled-components';
// import theme                   from '../assets/css/theme';
// import { below }               from '../assets/css/media';

type Props = RouteComponentProps;

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>]);
export const Rankings = ( props: Props ) => (
	<WithMainNav>
		<FetchJsons/>
		<ContentWithSidebar no_mobile_padding={true}
		                    sidebar_components={[ Ads ]}
		>
			<Ladder is_mass_league={false} {...props} is_rankings={true}/>
		</ContentWithSidebar>
	</WithMainNav>
);

export default Rankings;