import * as React from 'react';
import { NavigationMain } from './NavigationMain';

interface IProps {
	readonly children: React.ReactNode
}

export const WithMainNav = ({ children }: IProps) => (
	<>
		<NavigationMain/>
		{children}
	</>
);

export default WithMainNav;