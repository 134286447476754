import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { ICollectionState, IRequestData } from '../../types';

export interface ILeagueState extends IRequestData {
	is_commissioner?: boolean,
	readonly id: number
	readonly user_id: number
	readonly num_teams: number
	readonly round_points: number
	readonly start_round: number
	readonly start_at: string
	readonly overall_rank: number
	readonly overall_points: number
	readonly name: string
	readonly privacy: string
	readonly status: string
	readonly first_name: string,
	readonly last_name: string,
	readonly class: string,
	readonly code: string,
}

export type MyLeagues = ICollectionState<ILeagueState> & IRequestData;

const defaultState: MyLeagues = {
	items: [],
	next: false,
	is_data_requested: false,
};

export const my_leagues = createReducer<MyLeagues>({}, defaultState)
	.on(actions.getLeagues, (state: MyLeagues) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.clearLeagues, () => ({
		...defaultState,
		is_data_requested: false,
	}))
	.on(actions.getLeaguesSuccess, (state: MyLeagues, payload: MyLeagues) => ({
		...state,
		items: [
			...state.items,
			...payload.items
		],
		next: payload.next,
		is_data_requested: false,
	}))
	.on(actions.getLeaguesFailed, (state: MyLeagues) => ({
		...state,
		is_data_requested: false,
	}));