import * as React from 'react';
import styled, { css } from 'styled-components';
import { below } from '../../assets/css/media';
import theme from '../../assets/css/theme';
import { IQuestionComponentOptions } from '../Tournament';
import { IDriver } from '../../modules/reducers/drivers';
import lock_icon from '../../assets/img/icons/lock-icon.png';

import add_icon from '../../assets/img/icons/add-icon.png';
import { every } from 'lodash';

const PlayerCardWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 2px;
	background: #fff;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	height: 80px;
	border: 1px solid #EDEDED;
	${below.desktop`
		${({ isOnField }: any) => (isOnField && css`
			flex: 1 0 auto;
			max-width: 100%;
			margin-bottom: .5em;
		`)}
	`}

	${({ isLocked }: ICardWrapper) => {
	const locked = isLocked && css`
			opacity: .6;
		`;
	return [locked];
}}
`;


interface ICardWrapper {
	isLocked: boolean;
}

const PlayerName = styled.div`
	flex: 1;
	text-align: left;
	font-family: ${theme.base_bold_font};
	margin-left: 5px;
	color: #252549;
`;

const FirstName = styled.div`
	font-size: 14px;
`;

const LastName = styled.div`
	font-size: 17px;
	text-transform: uppercase;
`;

const SquadName = styled.div`
	font-size: 10px;
	color: #7E858A;
	font-family: ${theme.base_font};
`;

const Icon = styled.img`
	width: 35px;
	margin-right: 5px;
`;

const Partner = styled.div`
	text-transform: uppercase;
	color: #333F48;
	font-size: 13px;
`;

const DriverHeadShot = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 20px;
	position: relative;
	flex: 0 0 116px;
	${below.desktop_large`
		flex: 0 0 94px;
	`}
	${below.desktop`
		flex: 0 0 105px;
	`}
	${below.phone`
		flex: 0 0 94px;
	`}
`;

// const BG = styled.div`
// 	position: absolute;
// 	top: -15px;
//     left: -17px;
//     width: 119%;
//     height: 147%;
//     background: #000;
//     transform: rotate(11deg);
// `;

const DriverImage = styled.img`
	max-height: 100%;
	position: relative;
	z-index: 1;
	align-self: flex-end;
	${below.desktop_large`
		width: 60px;
	`}
	${below.desktop`
		width: 70px;
	`}
	${below.phone`
		width: 60px;
	`}
`;

const DriverNumber = styled.div`
	font-family: ${theme.base_bold_font};
	font-style: italic;
	font-size: 30px;
	position: relative;
	z-index: 1;
`;

interface IProps extends IQuestionComponentOptions {
	callback: () => void;
	driver: IDriver;
	isLocked?: boolean;
	isEndurance: number;
}

export const PlayerPoolCard = (props: IProps) => {
	const { driver, callback, isLocked = false } = props;
	const driver_url = `${process.env.REACT_APP_DRIVERS_URL}${driver.id}.png`;
	return (
		<PlayerCardWrapper
			onClick={callback}
			isLocked={isLocked}
		>
			<DriverHeadShot>
				<DriverImage src={driver_url} alt=""/>
				<DriverNumber>{driver.number}</DriverNumber>
			</DriverHeadShot>
			<PlayerName>
				<FirstName>{driver.first_name}</FirstName>
				<LastName>{driver.last_name}</LastName>
				{every([props.isEndurance, driver.endurance_partner]) ?
					<Partner>/ {driver.endurance_partner}</Partner> : null}
				<SquadName>{driver.squad_name}</SquadName>
			</PlayerName>
			{!props.isLocked && <Icon src={add_icon} alt='add icon'/>}
			{props.isLocked && <Icon src={lock_icon} alt='lock icon'/>}
		</PlayerCardWrapper>
	)
};

