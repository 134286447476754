import * as React from 'react'
import styled from 'styled-components'

import { Content } from '../';
import theme from '../../assets/css/theme';
import { below } from '../../assets/css/media';
import dropdown from '../../assets/img/icons/dropdown.png'

const LeagueNavContent = styled( Content )`
	${below.desktop`
		padding: 0;
	`}
`;

const BackLinkWrapper = styled.div`
	background: ${theme.primary};
	font-size: 1.125em;
	color: #fff;
	font-family: ${theme.base_bold_font};
	display: flex;
	align-items: center;
	a {
		padding: 1em;
		position: relative;
		display: flex;
		width: 100%;
		align-items: center;
		box-sizing: border-box;
		&:before {
			content: '';
			width: 15px;
			height: 15px;
			display: inline-block;
			background-image: url(${dropdown});
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			transform: rotate(90deg);
			margin-right: 0.75em;
		}
	}
`;

interface IBackLink {
	children: React.ReactNode
}

export const BackLink = ({ children }: IBackLink) => (
	<BackLinkWrapper>
		<LeagueNavContent>
			{children}
		</LeagueNavContent>
	</BackLinkWrapper>
);

export default BackLink;