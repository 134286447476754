import styled                   from 'styled-components';
import * as React               from 'react';
import { Content }              from '../Content';
import { ReactNode } from 'react';

const WrapperWithSidebar = styled(Content)`
	display: flex;
	justify-content: space-between;
	margin-top: 1.5em;
	section {
		position: relative;
	}
`;

interface IWrapperSkeleton {
	readonly children: React.ReactNode;
	readonly empty_sidebar?: boolean;
	readonly no_mobile_padding?: boolean;
	readonly sidebar_components?: ReactNode[]
}

export const ContentWithSidebar = ({ children, empty_sidebar, no_mobile_padding, sidebar_components = [] }: IWrapperSkeleton) => (
	<WrapperWithSidebar no_mobile_padding={no_mobile_padding}>
		<section>
			{children}
		</section>
		<aside>
			{empty_sidebar ? null : sidebar_components.map(component => (component)) }
		</aside>
	</WrapperWithSidebar>
);