import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

// export interface IQuestionOption {
// 	id: number;
// 	title: string;
// }
//
// export interface IQuestion {
// 	readonly id: number;
// 	readonly options: IQuestionOption[];
// 	readonly result: string;
// 	readonly result_feed_info: any;
// 	readonly slider_range_from: number;
// 	readonly slider_range_to: number;
// 	readonly title: string;
// 	readonly view: string;
// 	readonly view_number: number;
// 	readonly match: IGroupMatch;
// 	readonly has_answer: boolean;
// }
//
// export interface IGroupMatch {
// 	away_squad_id: number;
// 	away_squad_name: string;
// 	date: string;
// 	home_squad_id: number;
// 	home_squad_name: string;
// 	id: number;
// 	status: string;
// }
//
export interface IGroup {
	readonly id: number;
	readonly date_start: string;
	readonly endurance: number;
	readonly finalized: number;
	readonly name: string;
	readonly number: number;
	readonly published: number;
	readonly round: number;
	readonly status: string;
	readonly drivers: number[];
	readonly margin: number;
}

export interface IGroupReducerState {
	groups: IGroup[],
	actual_group: IGroup | undefined
}

const initialState: IGroupReducerState = {
	groups      : [],
	actual_group: undefined
};

export const groups = createReducer<IGroupReducerState>( {}, initialState );

groups.on( actions.fetchGroupsJSONSuccess, ( state: IGroupReducerState, payload: IGroupReducerState ) => ({
	...state,
	...payload
}) );

groups.on( actions.fetchGroupsJSONFailed, () => ({
	...initialState,
}) );

