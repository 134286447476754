/*
* This component was provided by QSIC
* */
import * as React from 'react';

export type IIcons =
	'bars-solid' |
	'triangle' |
	'facebook' |
	'twitter' |
	'close-icon' |
	'arrow-down' |
	'info' |
	'caret-left' |
	'caret-right' |
	'caret-up' |
	'plus-circle' |
	'cross-circle' |
	'darts-arrow' |
	'select' |
	'lock' |
	'check' |
	'times' |
	'chevron-down' |
	'chevron-up' |
	'cog' |
	'chevron-left' |
	'chevron-right';

interface IProps {
	name: IIcons;
	onClick?: (e?: React.SyntheticEvent<EventTarget>) => void
	color?: string;
	size?: number | string;
	strokeWidth?: number | string;
}

/**
 * SVG components for icons
 */
export class Icon extends React.Component<IProps, { icon: string, name: string }> {
	private readonly size = 20;
	private readonly strokeWidth = 0;
	/**
	 * @ignore
	 */
	public render() {
		const { color = 'currentColor', size = this.size, strokeWidth = this.strokeWidth, name = 'default_path' } = this.props;
		const icon = icons[name];
		return <svg
			viewBox={icon.viewBox}
			strokeWidth={strokeWidth}
			stroke={color}
			color={color}
			fill={color}
			strokeLinecap={'round'}
			width={size}
			height={size}
			dangerouslySetInnerHTML={{__html: icon.d}}
		/>;
	}
}

const icons = {
	'bars-solid': {
		d: `<path  fill="currentColor" d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z' />`,
		viewBox: '0 0 448 512'
	},
	'facebook': {
		d: `<path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/>`,
		viewBox: '0 0 264 512'
	},
	'twitter': {
		d: `<path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>`,
		viewBox: '0 0 512 512'
	},
	'close-icon': {
		d: `<path d="M14.1,11.3c-0.2-0.2-0.2-0.5,0-0.7l7.5-7.5c0.2-0.2,0.3-0.5,0.3-0.7s-0.1-0.5-0.3-0.7l-1.4-1.4C20,0.1,19.7,0,19.5,0  c-0.3,0-0.5,0.1-0.7,0.3l-7.5,7.5c-0.2,0.2-0.5,0.2-0.7,0L3.1,0.3C2.9,0.1,2.6,0,2.4,0S1.9,0.1,1.7,0.3L0.3,1.7C0.1,1.9,0,2.2,0,2.4  s0.1,0.5,0.3,0.7l7.5,7.5c0.2,0.2,0.2,0.5,0,0.7l-7.5,7.5C0.1,19,0,19.3,0,19.5s0.1,0.5,0.3,0.7l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3  s0.5-0.1,0.7-0.3l7.5-7.5c0.2-0.2,0.5-0.2,0.7,0l7.5,7.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4c0.2-0.2,0.3-0.5,0.3-0.7  s-0.1-0.5-0.3-0.7L14.1,11.3z"/>`,
		viewBox: '0 0 21.9 21.9'
	},
	'arrow-down': {
		d: `<path d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424   C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428   s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"/>`,
		viewBox: '0 0 292.362 292.362'
	},
	'info': {
		d: `<path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>`,
		viewBox: '0 0 512 512'
	},
	'caret-left': {
		d: `<path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path>`,
		viewBox: '0 0 192 512'
	},
	'caret-right': {
		d: `<path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path>`,
		viewBox: '0 0 192 512'
	},
	'caret-up': {
		d: `<path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path>`,
		viewBox: '0 0 320 512'
	},
	'plus-circle': {
		d: `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Gameplay/Question/PlayerPool" transform="translate(-241.000000, -23.000000)" fill="#308392">
            <g id="Icon/Add" transform="translate(241.000000, 23.000000)">
                <path d="M19.0650095,17.7244528 L23.1261074,13.6633549 L23.7569576,13.0325047 L22.416401,11.6919481 L21.7855508,12.3227983 L17.7244528,16.3838962 L13.702783,12.3622264 L13.0325047,11.6919481 L11.6919481,13.0325047 L12.3622264,13.702783 L16.3838962,17.7244528 L12.3227983,21.7855508 L11.6919481,22.416401 L13.0325047,23.7569576 L13.6633549,23.1261074 L17.7244528,19.0650095 L21.7461227,23.0866793 L22.416401,23.7569576 L23.7569576,22.416401 L23.0866793,21.7461227 L19.0650095,17.7244528 Z M17.5,35 C7.83501688,35 0,27.1649831 0,17.5 C0,7.83501688 7.83501688,0 17.5,0 C27.1649831,0 35,7.83501688 35,17.5 C35,27.1649831 27.1649831,35 17.5,35 Z" id="Icons/Remove" transform="translate(17.500000, 17.500000) rotate(45.000000) translate(-17.500000, -17.500000) "></path>
            </g>
        </g>
    </g>`,
		viewBox: '0 0 35 35'
	},
	'cross-circle': {
		d: `<path d="M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0
		C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778
		c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828
		c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828
		l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z"/>`,
		viewBox: '0 0 51.976 51.976'
	},
	'darts-arrow': {
		d: `<g id="Icon/Selected" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Gameplay/Bullseye" transform="translate(0.000000, 1.000000)" fill-rule="nonzero">
            <circle id="Oval" stroke="#343232" stroke-width="3" fill="#057545" cx="17" cy="17" r="15.5"></circle>
            <circle id="Oval-6" fill="#E81C19" cx="17.5" cy="16.5" r="8.5"></circle>
        </g>
        <g id="Icon-Dart-Small-Shadow" transform="translate(13.000000, 2.000000)" fill="#000000" fill-opacity="0.05">
            <g transform="translate(16.644552, 10.598432) rotate(65.000000) translate(-16.644552, -10.598432) translate(12.644552, -5.901568)" id="Combined-Shape">
                <path d="M4.48148148,10 L4.99848485,29.8917048 C4.99949056,29.9110024 5,29.9304387 5,29.95 C5,30.529899 4.55228475,31 4,31 C3.44771525,31 3,30.529899 3,29.95 C3,29.9304387 3.00050944,29.9110024 3.00151515,29.8917048 L3.51851852,10 L3,10 L3,9.42982456 L-9.09494702e-13,7.71929825 L-9.09494702e-13,0 L4,2.19298246 L4,2.2 L8,0 L8,7.80701754 L5,9.45175439 L5,10 L4.48148148,10 Z" fill-rule="nonzero"></path>
            </g>
        </g>
        <g id="Icon-Dart-Small" transform="translate(12.000000, -6.000000)">
            <g transform="translate(14.495689, 14.495689) rotate(45.000000) translate(-14.495689, -14.495689) translate(10.495689, -2.004311)">
                <polygon id="Path-5" fill="#E81C19" fill-rule="nonzero" points="0 0 0 7.71929825 4 10 4 2.19298246"></polygon>
                <polygon id="Path-5" fill="#B91818" fill-rule="nonzero" points="4 10 4 2.2 8 0 8 7.80701754"></polygon>
                <rect id="Rectangle" fill="#911918" fill-rule="nonzero" x="3" y="9" width="2" height="1"></rect>
                <path d="M4.99848485,29.8917048 C4.99949056,29.9110024 5,29.9304387 5,29.95 C5,30.529899 4.55228475,31 4,31 C3.44771525,31 3,30.529899 3,29.95 C3,29.9304387 3.00050944,29.9110024 3.00151515,29.8917048 L3.51851852,10 L4.48148148,10 L4.99848485,29.8917048 Z" id="Combined-Shape" fill="#D8D8D8" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>`,
		viewBox: '0 0 35 35',
	},
	'select': {
		d: `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon/Choose" transform="translate(0.000000, -1.000000)" fill-rule="nonzero">
            <g id="Gameplay/Bullseye" transform="translate(0.000000, 1.000000)">
                <circle id="Oval" stroke="#343232" stroke-width="3" fill="#22616D" cx="17" cy="17" r="15.5"></circle>
                <circle id="Oval-6" fill="#308392" cx="17.5" cy="16.5" r="8.5"></circle>
            </g>
        </g>
    </g>`,
		viewBox: '0 0 34 34'
	},
	'lock': {
		d: `<path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>`,
		viewBox: '0 0 448 512'
	},
	'check': {
		d: `<path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>`,
		viewBox: '0 0 512 512'
	},
	'times': {
		d: `<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>`,
		viewBox: '0 0 352 512'
	},
	'chevron-down': {
		d: `<path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>`,
		viewBox: '0 0 448 512'
	},
	'chevron-up': {
		d: `<path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path>`,
		viewBox: '0 0 448 512'
	},
	'cog': {
		d: `<path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path>`,
		viewBox: '0 0 512 512'
	},
	'chevron-left': {
		d: `<path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>`,
		viewBox: '0 0 320 512'
	},
	'chevron-right': {
		d: `<path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>`,
		viewBox: '0 0 320 512'
	},
	'default_path': ''
};