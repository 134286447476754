import * as _                  from 'lodash';
import { SagaIterator }        from 'redux-saga';
import { call, put }           from 'redux-saga/effects';
import { IEmail, ISagaAction } from '../../types';
import ApiErrors               from '../../utils/Api/ApiErrors';


export const postUserSendEmailSaga = function* (action: ISagaAction<IEmail>): SagaIterator {
	try {
		const { errors } = yield call(action.meta.api.User.send_token, {
			email: _.get(action,'payload.email', '')
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put(action.meta.actions.userForgotPasswSuccess());

	} catch (e) {
		yield put(action.meta.actions.userForgotPasswFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};