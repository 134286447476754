import * as React              from 'react';
import { connect }             from 'react-redux';
import styled                  from 'styled-components';
import {
	Content,
	RegularButton,
	SecondaryButton,
	Tournament,
	PlayerPool,
	GameBar,
	WithMainNav,
	SidebarAdComponent,
} from '../components'
import * as actions                   from '../modules/actions';
import { IGroupReducerState }         from '../modules/reducers/groups';
import { IStoreState }                from '../modules/types';
import { below }                      from '../assets/css/media';
import { ISquadsReducerState }        from '../modules/reducers/squads';
import { ITournamentState }           from '../modules/reducers/tournament';
import { getSelectedGroup, isMobile } from '../modules/utils';
import { IUserReducerState }          from '../modules/reducers/user';

const HoweWrapper = styled.div`
	${below.desktop`
		${SecondaryButton} {
			margin-bottom: 0.5em;
		}
	`}

	${SecondaryButton},
	${RegularButton} {
		max-width: 240px;
	}
`;

const TournamentWrapper = styled( Content )`
	display: flex;
	padding: 2em 0;
	justify-content: space-between;
	position: relative;
	${below.desktop`
		padding: 0
	`}
`;

interface IProps {
	readonly groups: IGroupReducerState;
	readonly squads: ISquadsReducerState;
	readonly playerPoolSquads: ISquadsReducerState;
	readonly user: IUserReducerState;
	readonly fetchAllJSON: () => void;
	readonly fetchPredictions: () => void;
	readonly fetchSnapshot: () => void;
	tournament: ITournamentState;
	isPoolAvailable: boolean;
}

/**
 * Home page component
 */
class TournamentComponent extends React.Component<IProps> {

	public scrollRef: React.RefObject<HTMLDivElement>;

	constructor( props: any ) {
		super( props );
		this.scrollRef = React.createRef();
	}

	get playerPool() {
		const { tournament } = this.props;
		return <PlayerPool
			isVisibleMobile={tournament.is_pool_visible}
			isAutoSave={true}
		/>
	}

	/**
	 * Fetch all data before mount
	 */
	public componentWillMount() {
		this.props.fetchPredictions();
		this.props.fetchAllJSON();
		this.props.fetchSnapshot();
	}

	get isTournamentVisible() {
		const { tournament } = this.props;
		return !isMobile() ||
			(!tournament.is_pool_visible && isMobile());
	}

	/**
	 * @ignore
	 */
	public render() {
		const { groups, user, tournament } = this.props;

		return (
			<WithMainNav>
				<HoweWrapper>
					<TournamentWrapper>
						{this.isTournamentVisible ? (
							<section>
								<GameBar
									selectedGroup={getSelectedGroup(tournament.selected_group_id, groups.groups)}
									teamName={user.team_name}
									snapshot={tournament.snapshot}
								/>
								<Tournament
									groups={groups}
									isAutoSave={true}
								/>
							</section>
						) : null}
						<aside>
							<SidebarAdComponent />
							{this.playerPool}
						</aside>
						{isMobile() && this.playerPool}
					</TournamentWrapper>
				</HoweWrapper>
			</WithMainNav>
		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
	groups    : state.groups,
	squads    : state.squads,
	rounds    : state.rounds,
	tournament: state.tournament,
	user      : state.user,
});

const mapDispatchToProps = {
	fetchAllJSON    : actions.fetchJSONS,
	fetchPredictions: actions.fetchTournamentPredictions,
	fetchSnapshot   : actions.fetchSnapshot
};

export const HomePrivate = connect( mapStateToProps, mapDispatchToProps )( TournamentComponent );

export default HomePrivate;
