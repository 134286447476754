import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IRequestData } from '../../types';

export interface IForgotPassReducer extends IRequestData {
	is_success: boolean,
}

const defaultState = {
	is_data_requested: false,
	is_success: false
};

export const user_forgot_password = createReducer<IForgotPassReducer>({}, defaultState)
	.on(actions.userForgotPassw, () => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(actions.userForgotPasswSuccess, () => ({
		is_success     : true,
		is_data_requested: false,
	}))
	.on(actions.userForgotPasswFailed, () => defaultState);