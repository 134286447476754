import * as React                from 'react';
import * as _                    from 'lodash';
import { matchPath, withRouter } from 'react-router';
import { RouteComponentProps }   from 'react-router-dom';
import styled, { css }           from 'styled-components';
import theme                     from '../../assets/css/theme';
import { Icon }          from '../';
import { above, below }          from '../../assets/css/media';
import { NavItem as Item }       from '../NavigationElements';
import { IItemWithSubMenu }      from './menuItems';

interface IItemsStyle {
	readonly active: boolean;
}

const Items = styled.div`
	flex-wrap: wrap;
	display: ${( { active }: IItemsStyle ) => ('flex')};
	max-height: 0;
	overflow:hidden;
	z-index: 3;
	background: #333F48;
	
	${( { active }: IItemsStyle ) => (active ? `${css`
		max-height: 100vh;
	`}` : '')}

	${above.desktop`
		position: absolute;
		top: 100%;
	`}
	${below.desktop`
		transition: max-height 0.5s ease-in;
	`}
	${Item} {
		background: transparent;
		color: #fff;
		&.active{
			background: ${theme.primary};
		}
		&.logout {
			background: #333F48;
		}
	}

`;

interface ISubMenuStyle {
	readonly is_open?: boolean;
	readonly active?: boolean;
}

const SubMenuName = styled.div<{ is_open: boolean }>`
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	${Item}{

		height: 100%;
		padding-right: 0;
		background: ${({ is_open }: any) => (is_open ? theme.primary : '')};
		color: ${({ is_open }: any) => (is_open? '#fff' : '')};
		min-width: 125px;
		flex: 1 1 100%;
		span {
			position: relative;
			padding-right: 20px;
		}
		svg{
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			color: ${({ is_open }: any) => (is_open?  '#fff' : theme.primary)};
		}
		&.active{
			background: ${theme.primary};
			svg {
				color: #fff;
			}
		}
	}
`;


const SubMenuStyled = styled.div` 
	width: 100%;
	background-color: ${({ active }: ISubMenuStyle) => (active ? theme.primary : '')};
	color: ${({ active }: ISubMenuStyle) => (active ? '#fff' : '')};
	transition: max-height 0.3s ease; 
	font-family: ${theme.mobile_menu_font};
	cursor: pointer;
	position: relative;
	
	&:hover {
		${SubMenuName} {
			background: ${theme.primary};
		
			${Item}{
				color: #fff;
			}
			svg {
				color: #fff;
			}
		}
		${Items} {
			max-height: 100vh;
			//width: 100%;
		}
	}
	
	${above.desktop`
		max-width: 145px;
	`}
`;

interface ISubMenuProps {
	mobile?: boolean;
	item: IItemWithSubMenu;
}

class DropDownComponent extends React.Component<ISubMenuProps & RouteComponentProps> {

	public state = {
		is_open: false,
	};

	/**
	 * Check is match path of locations and path parameter
	 */
	public isMatchPath(path?: string) {
		const { location } = this.props;
		return !!matchPath(location.pathname, { path });
	}

	public handleCurrentState = (e: React.SyntheticEvent<EventTarget>) => {
		const { mobile } = this.props;
		const { is_open } = this.state;
		const is_active_path = !this.isActive() && mobile;

		if(is_active_path && !is_open){
			e.preventDefault();
			e.stopPropagation();
			this.toggleState(!this.state.is_open);

		} else {
			this.toggleState(false);
		}


	};

	/**
	 * Method for getting state by key of dropdown
	 */
	public isActive(): boolean {
		const { item: { link } } = this.props;
		return this.isMatchPath(link);
	}

	/**
	 * @ignore
	 */
	public render() {
		const { item, mobile } = this.props;
		const { is_open } = this.state;
		const is_active = this.isActive();
		const icon = item.icon || 'chevron-down';

		return (
			<SubMenuStyled
				active={is_active}
			>
				<SubMenuName is_open={is_active}>
					<Item exact={item.exact} to={item.link} onClick={this.handleCurrentState}>
						<span>
							{item.name}
							<Icon name={icon} color={theme.primary} size={'15'}/>
						</span>
					</Item>
				</SubMenuName>
				<Items active={mobile ? is_active : is_open}>
					{item.subMenu.map((item_sub_menu, i) => (
						<Item
							key={i}
							exact={item_sub_menu.exact}
							to={item_sub_menu.link}
							onClick={_.partial(this.toggleState, mobile)}
							className={item_sub_menu.className}
						>
							{item_sub_menu.name}

						</Item>
					))}
				</Items>
			</SubMenuStyled>

		)
	}

	/**
	 * Change state by key for each drop down
	 */
	private readonly toggleState = (state?:boolean):void => {

		this.setState({
			is_open: state
		})
	};
}

export const SubMenu = withRouter(DropDownComponent);

export default SubMenu;
