import styled from 'styled-components';
import { below } from '../../assets/css/media';

export const FormWrapper = styled.form`
	padding: 1em;
	background: #fff;
	max-width: 400px;
	margin: 0 auto;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,0.35);
	position: relative;
	min-height: 300px;
	box-sizing: border-box;
	${below.desktop`
		max-width: 100%;
		padding: 0 0 1em 0;
	`}
`;

export default FormWrapper;