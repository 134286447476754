import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const Input = styled.input`
	display: none;
`;

const OptionStyle = styled.label`
	background: transparent;
	border: 1px solid ${theme.primary};
	height: 40px;
	width: 100%;
	color: ${theme.base_text_color};
	text-align: center;
	transition: all 0.1s ease;
	padding: 0 0.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.125em;
	box-sizing: border-box;
	margin:  0 0 0.5em;
	font-family: ${theme.base_bold_font}, sans-serif;
	text-transform: uppercase;
	cursor: pointer;
	${Input}:checked + &{
		background: ${theme.primary};
		color: #fff;
	}
	${Input}:disabled + &{
		opacity: 0.65;
	}
	

`;

const RadioWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	
	& > div{
		flex: 1 1 100%;
	}
`;

interface IOption extends React.AllHTMLAttributes<HTMLInputElement>{
	readonly items: string[];
	readonly defaultValue?: string;
	readonly type: "radio" | 'checkbox';
	readonly as?: any
}

export class OptionsStyled extends React.Component<IOption> {
	/**
	 * @ignore
	 */
	public render(){
		const { items, defaultValue, type, ...props } = this.props;
		// console.log(items);
		// console.log(defaultValue);
		return (
			<RadioWrapper>
				{items.map((item: string, i: number) => (
				<div key={i}>
					<Input
						id={item}
						name={props.name}
						value={item}
						defaultChecked={defaultValue === item}
						type={type}
						{...props}
					/>
					<OptionStyle htmlFor={item}>{item}</OptionStyle>
				</div>
				))}
			</RadioWrapper>
		)
	}
}


export default OptionsStyled;
