import styled from 'styled-components';
import { below } from '../../assets/css/media';

export const HeaderGlobalWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	box-shadow: 0 3px 17px 0 rgba(0,0,0,0.3);
	background: #fff;
	${ below.desktop`
		display: none;
	` }
`;

export default HeaderGlobalWrapper;