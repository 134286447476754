import * as React      from 'react';
import styled, { css } from 'styled-components';
import theme           from '../../assets/css/theme';
import add_icon        from '../../assets/img/icons/add-icon.png';
import lock_icon       from '../../assets/img/icons/lock-icon.png';
import { below }       from '../../assets/css/media';

const Cell = styled.div`
	max-width: 100%;
	color: ${theme.secondary};
	height: 80px;
	cursor: pointer;
	position: relative;
	background: #ededed;
	margin-bottom: 13px;
	border: 2px solid #ededed;
	${( { isLocked }: ICell ) => {
			const locked = isLocked && css`
				opacity: 0.6;
			`;
			return [locked];
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	z-index: 1;
	position: relative;
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 97%;
	height: 100%;
	background: #fff;
	transform: skew(-18deg, 0deg);
	box-shadow: 2px 0 10px -2px rgba(138, 138, 138,0.75);
	${below.desktop`
		width: 93%;
	`}
`;

const PlayerNumber = styled.div`
	background: #333F48;
	height: 100%;
	color: #fff;
	font-family: ${theme.base_bold_font};
	font-size: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	${below.desktop`
		font-size: 14px;
		padding: 0 4px;
	`}
`;

const Icon = styled.img`
	width: 35px;
	margin-right: 13px;
`;

const EmptySpace = styled.div`
	flex: 1;
	padding-left: 1em;
	color: #7E858A;
	font-size: 22px;
	${below.desktop`
		font-size: 18px;
		padding-left: 10px;
	`}
`;

interface ICell {
	isLocked?: boolean;
}

interface IProps {
	callback: () => void;
	playerNumber: string;
	isLocked?: boolean
}


export const PlayerEmpty = ( props: IProps ) => (
	<Cell
		isLocked={props.isLocked}
		>
		<Wrapper onClick={props.callback}>
			<PlayerNumber>
				{props.playerNumber}
			</PlayerNumber>
			<EmptySpace>Select a driver</EmptySpace>
			{!props.isLocked && <Icon src={add_icon} />}
			{props.isLocked && <Icon src={lock_icon} />}
		</Wrapper>
		<Background />
	</Cell>
);

