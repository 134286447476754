import { IDriver, IDriverReducersState } from '../../reducers/drivers';
import { ISquadsReducerState }           from '../../reducers/squads';
import { find, sortBy }                          from 'lodash';

export const getDriverSquad = (driver: IDriver, squads: ISquadsReducerState) => {
	const squad = find(squads, {id: driver.squad_id});
	return squad ? squad.name : '';
};

export const mergeSquadsWithDrivers = ( drivers: IDriverReducersState, squads: ISquadsReducerState) => {
	return drivers.map(driver => ({
		...driver,
		squad_name: getDriverSquad(driver, squads)
	}));
};

export const sortDriversByNumber = ( drivers: IDriverReducersState) => {
	return sortBy(drivers, 'number');
};
