import { isEmpty } from 'lodash';

/**
 * Custom Errors handler
 */
export class ApiErrors extends Error {

	public static AUTH_REQUIRED = 401;
	public static USER_EXPIRED_TOKEN = 510;

	/**
	 * Should be called after each requests when we expect some errors or validations
	 */
	public static checkOnApiError( { errors }: any ) {
		const is_error = !isEmpty( errors );

		if ( is_error ) {
			throw new ApiErrors( errors[0].text, errors[0].code );
		}
	}

	public code: number;
	constructor(m: string, code: number){
		super(m);
		this.name = 'API Errors';
		this.code = code;
	}

}

export default ApiErrors
