import * as React                                      from 'react';
import * as _                                          from 'lodash';
import * as actions                                    from '../../modules/actions';
import styled                                          from 'styled-components';
import { connect }                                     from 'react-redux';
import { ICollectionState, IRequestData, IStoreState } from '../../modules/types';
import { ILeagueState }                                from '../../modules/reducers/leagues';
import { League }                                      from './MyLeagues/League'

import {
	ContentWithSidebar, FetchJsons,
	LeagueCreateLink,
	LeagueJoinLink,
	LeaguesNotFount,
	LoadingSpinner,
	PageTitle,
	SecondaryButtonLeftSide,
	SidebarAdComponent,
	WithMainNav,
	PointsSystem
} from '../../components';

const MyLeaguesTagLine = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.75em;
	${SecondaryButtonLeftSide}{
		flex: 1 1 47%;
		margin: 0;
		max-width: 47%;
	}
`;

interface IMyLeaguesModel extends IRequestData {
	readonly fetchMyListLeagues: () => void;
	readonly clearLeagues: () => void;
	readonly loadMore: () => void;
	readonly leagues: ICollectionState<ILeagueState>
}

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>]);

class MyLeaguesComponent extends React.Component<IMyLeaguesModel> {
	constructor(props: IMyLeaguesModel){
		super(props);
		_.bindAll(this, [
			'loadMore',
			'fetchLeagues'
		])
	}
	/**
	 * Clear leagues
	 */
	public componentWillUnmount(){
		const { clearLeagues } = this.props;
		clearLeagues();
	}
	/**
	 * Get All leagues
	 */
	public componentDidMount() {
		this.fetchLeagues();
	}

	public get leagues() {
		const { leagues: { items, next } } = this.props;
		const ONE_MINUTE = 60000;
		const onCompletedTimers = _.debounce(this.fetchLeagues, ONE_MINUTE);
		return <>
		{items.length ? (
			items.map((league: ILeagueState | null, i:number) => league && (
				<League key={i} league={league} onCompleteTimer={onCompletedTimers}/>
			))
		) : (
			<LeaguesNotFount>
				You are not entered in any leagues. <br />
				You can either create your own or join a league via the buttons above
			</LeaguesNotFount>
		)}
		{next ? (
			<SecondaryButtonLeftSide onClick={this.loadMore}>Load more</SecondaryButtonLeftSide>
		) : null}
		</>
	}
	/**
	 * @ignore
	 */
	public render() {
		const { is_data_requested } = this.props;
		return (
			<WithMainNav>
				<FetchJsons/>
				<ContentWithSidebar
					sidebar_components={[Ads]}
				>
					<PageTitle>
						My Leagues
					</PageTitle>

					<MyLeaguesTagLine>
						<SecondaryButtonLeftSide as={LeagueCreateLink}>Create My league</SecondaryButtonLeftSide>
						<SecondaryButtonLeftSide as={LeagueJoinLink}>
							join a league
						</SecondaryButtonLeftSide>
					</MyLeaguesTagLine>
					{is_data_requested ? <LoadingSpinner with_cover_bg={true} position="center"/> : this.leagues }
				</ContentWithSidebar>
			</WithMainNav>
		)
	}

	private loadMore() {
		const { loadMore } = this.props;

		loadMore();
	};

	private fetchLeagues() {
		const { fetchMyListLeagues } = this.props;

		fetchMyListLeagues();
	}
}

const mapStateToProps = (state: IStoreState) => ({
	leagues: _.get(state, 'my_leagues'),
	is_data_requested: _.get(state, 'my_leagues.is_data_requested'),
});

const mapDispatchToProps = {
	fetchMyListLeagues: actions.getLeagues,
	loadMore: actions.getLeaguesMore,
	clearLeagues: actions.clearLeagues
};
export const MyLeagues = connect(mapStateToProps, mapDispatchToProps)(MyLeaguesComponent);
