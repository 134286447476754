import * as React      from 'react';

// import { NavLink } from 'react-router-dom';
// import styled from 'styled-components';
// import theme from '../assets/css/theme';
import { MastHead }    from '../components';
import { IStoreState } from '../modules/types';
import * as actions    from '../modules/actions';
import { connect }     from 'react-redux';
import styled          from 'styled-components';
import theme           from '../assets/css/theme';

// const NotFoundWrapper = styled.div`
// 	border-top: 8px solid #272935;
// 	border-bottom: 8px solid #272935;
// 	padding: 10px 0 20px;
// 	width: 210px;
// 	font-family: ${theme.base_bold_font};
// 	margin: 2em auto;
// 	text-align: center;
// 	span{
// 	    font-size: 7em;
// 	}
//
// 	p {
// 	    font-size: 1.5em;
// 	}
//
// `;
//
// const Title = styled.div`
// 	font-size: 16px;
// 	text-align: center;
// 	padding: 1em;
// `;
//
// const CustomLink = styled(NavLink)`
// 	display: block;
// 	max-width: 240px;
// 	margin: 1em auto;
// 	button {
// 	    width: 100%;
// 	}
// `;

const UnsubscribeMessage = styled.div`
	text-align: center;
	font-family: ${theme.base_bold_font};
	font-size: 30px;
	margin-bottom: 40%;
`;

class UnsubscribeClass extends React.Component<any> {
	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const urlParams = new URLSearchParams(this.props.location.search);
		const email = urlParams.get('email');
		const token = urlParams.get('token');
		if(email && token) {
			this.props.unsubscribeAction(
				{
					email,
					token
				}
			);
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<div>
				<MastHead/>
				<UnsubscribeMessage>{this.props.unsubscribe}</UnsubscribeMessage>
			</div>
		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
	unsubscribe    : state.unsubscribe,
});

const mapDispatchToProps = {
	unsubscribeAction: actions.unsubscribe
};

export const Unsubscribe = connect( mapStateToProps, mapDispatchToProps )( UnsubscribeClass );


export default Unsubscribe;
