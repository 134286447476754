import * as _       from 'lodash';
import * as React   from 'react';
import { connect }  from 'react-redux';
import { NavLink }  from 'react-router-dom';
import styled       from 'styled-components';
import theme        from '../../assets/css/theme';
import * as actions from '../../modules/actions'

import { IFormProps, IRegFormState, IRegistrationsProps, IStoreState } from '../../modules/types'

import {
	ConfirmField,
	Field,
	FormCreator,
	FormGlobalTitle,
	FormWrapper,
	LoadingSpinner,
	RegularButton,
	SecondaryButton,
} from '../../components';
import { Redirect } from 'react-router';

const RegistrationWrapper = styled.div`
	padding: 0 2em;
`;

const CancelButton = styled( NavLink )`
	display: block;
	${RegularButton} {
		background-color: white;
		color: ${theme.primary};
		border:1px solid ${theme.primary};
	}
`;

const SecondaryButtonWithMargin = styled( SecondaryButton )`
	margin-bottom: 0.5em;
`;

class RegistrationFormComponent extends React.Component<IRegistrationsProps, IRegFormState> {

	public state = {
		email                : '',
		password             : '',
		first_name           : '',
		last_name            : '',
		is_stored            : false
	};

	constructor( props: IRegistrationsProps ) {
		super( props );

		_.bindAll( this, [
			'handleSubmitForm',
			'renderedFields',
		] )
	}

	/**
	 * Method to process registration form submission
	 */
	public handleSubmitForm( values: IRegFormState ) {
		const { userUpdateSuccess } = this.props;
		userUpdateSuccess( values );
		this.setState({is_stored: true});
	};

	/**
	 * Get Form Fields for Registration form
	 */
	public renderedFields(props: IFormProps, values: IRegFormState) {
		let TAB_INDEX = 0;
		const { handleChange } = props;
		const {
			email = '',
			password = '',
			first_name = '',
			last_name = '',
		} = values;
		return (
			<RegistrationWrapper>
				<FormGlobalTitle>
					LET’S GET YOU REGISTERED
				</FormGlobalTitle>
				<ConfirmField
						type="email"
						name='email'
						autoComplete='email'
						placeholder="Please enter your email address *"
						placeholderConfirm="Please confirm you email address *"
						defaultValue={email}
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
				/>
				<ConfirmField
						type="password"
						name='password'
						autoComplete='password'
						placeholder="Create a password *"
						placeholderConfirm="Please confirm your password *"
						defaultValue={password}
						pattern="^.{8,}$"
						title="Min length 8"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
				/>
				<Field
					type="text"
					id={_.uniqueId( 'first_name' )}
					name='first_name'
					placeholder="Please enter your first name *"
					title="Invalid first name"
					defaultValue={first_name}
					pattern="^[a-zA-Z\s\-]{1,40}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<Field
					type="text"
					id={_.uniqueId( 'last_name' )}
					name='last_name'
					placeholder="Please enter your last name *"
					title="Invalid last name"
					defaultValue={last_name}
					pattern="^[a-zA-Z\s\-]{1,40}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<SecondaryButtonWithMargin type='submit'
											   disabled={false}>CONTINUE</SecondaryButtonWithMargin>
				<CancelButton to='/'>
					<RegularButton disabled={false}>CANCEL</RegularButton>
				</CancelButton>
			</RegistrationWrapper>
		)
	}

	get redirect() {
		const { is_stored } = this.state;
		return is_stored && (<Redirect to='/registration-second-step'/>)
	}

	get form() {
		const {
			email = '',
			password = '',
			first_name = '',
			last_name = '',
		} = this.state;

		const { is_data_requested } = this.props;

		return is_data_requested ? (
				<FormWrapper>
					<LoadingSpinner with_cover_bg={true} position="center"/>
				</FormWrapper>
		) : (
				<FormCreator
						as={FormWrapper}
						onSubmit={this.handleSubmitForm}
						render={this.renderedFields}
						initialState={{
							email,
							password,
							first_name,
							last_name,
						}}
				/>
		)
	}

	/**
	 * @ignore
	 */
	public render() {
		return <>
			{this.redirect}
			{this.form}
		</>
	}
}

const mapStateToProps = ( state: IStoreState ) =>  ({
	is_data_requested: _.get( state, 'user.is_data_requested', false ),
});

const mapDispatchToProps = {
	userUpdateSuccess: actions.userUpdateSuccess,
	userRegister: actions.userRegister
};

export const RegistrationFormFirst = connect( mapStateToProps, mapDispatchToProps )( RegistrationFormComponent);
export default RegistrationFormFirst;