import styled from 'styled-components';
import theme from '../../assets/css/theme';

export const LeaguesNotFount = styled.div`
	font-family: ${theme.base_font};
	font-size: 1.125em;
	color: ${theme.base_text_color};
	text-align: center;
	padding: 1em 0;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
	margin-bottom: 1em;
`;

export default LeaguesNotFount