import * as React             from 'react';
import * as _                 from 'lodash';
import { Redirect } from "react-router";
import styled                 from 'styled-components';
import { connect }            from 'react-redux';
import {
	Content,
	Field,
	Header,
	PageTitle,
	SecondaryButton,
	FormCreator
} from '../components';
import * as actions           from '../modules/actions';
import {
	IEmail,
	IFormProps,
	ISendRequest,
	IStoreState
} from '../modules/types';
import { IForgotPassReducer } from '../modules/reducers/user';
import { LoadingSpinner }     from '../components/AppLoading';
import theme                  from '../assets/css/theme';
import { isOffSeason } from "../modules/utils/dom_helpers";

const Form = styled.form`
	position: relative;
	width: 50%;
`;

const MessageSuccess = styled.div`
	display: block;
	background: ${theme.primary};
	width: 50%;
	text-align: center;
	font-family: ${theme.base_bold_font};
	color: #fff;
	padding: 1em;
	margin: 2em 0;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.3s ease;
	box-sizing: border-box;
	font-size: 0;
	${( { active }: ISuccess ) => (active ? `
		max-height: 100%;
		font-size: 1em;
	` : '')};
`;

const Wrapper = styled(Content)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50vh;
`;

const HelpText = styled.div`
	color: #7E858A;
	font-size: 18px;
	font-family: ${theme.secondary_font};
	margin-bottom: 2em;
	max-width: 50%;
	text-align: center;
	line-height: 29px;
`;

interface IMapToProps {
	readonly user_forgot_password: IForgotPassReducer
}

interface ISuccess {
	readonly active?: boolean
}

type DispatchToProps = ISendRequest<string>
type Props = DispatchToProps & IMapToProps

class ForgotPasswordComponent extends React.Component<Props> {

	constructor( props: Props ) {
		super( props );
		_.bindAll( this, [
			'renderedField',
			'handleSubmitForm',
		] )
	}

	/**
	 * Method to process restore password form submission
	 */
	public handleSubmitForm( values: any ) {
		const { send } = this.props;

		send( values );
	};

	/**
	 * Get Form Field for Forgot password
	 */
	public renderedField(form_props: IFormProps, values: IEmail) {
		const { email } = values;
		const { user_forgot_password: { is_data_requested, is_success } } = this.props;
		const { handleChange } = form_props;
		return (
			<>
				{is_data_requested && <LoadingSpinner with_cover_bg={true}/>}
				<Field
					type="email"
					id={_.uniqueId( 'email' )}
					name='email'
					placeholder="Enter your registered email*"
					title="Enter your registered email*"
					defaultValue={email}
					onChange={handleChange}
					disabled={is_success}
					required={true}
				/>

				<SecondaryButton
					type="submit"
					margin={'0 auto 0 0'}
					disabled={is_success}
				>
					SEND EMAIL
				</SecondaryButton>
			</>
		)
	}

	/**
	 * @ignore
	 */
	public render() {
		const { user_forgot_password: { is_success } } = this.props;

		if(isOffSeason) {
			return  <Redirect to='/'/>
		}
		return (
			<>
				<Header force_show={true} />
				<Wrapper>
					<PageTitle>Forgot your password?</PageTitle>
					<HelpText>
						A link to reset your password will be emailed to this address. Click on the link in the email to change your password. If you need assistance, please contact us.
					</HelpText>
					<FormCreator
						as={Form}
						onSubmit={this.handleSubmitForm}
						render={this.renderedField}
					/>

					<MessageSuccess active={is_success}>
						Thank you. An email has been sent to you with a link to reset your password.
					</MessageSuccess>
				</Wrapper>
			</>
		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
	user_forgot_password: state.user_forgot_password,
});

const mapDispatchToProps: DispatchToProps = {
	send: actions.userForgotPassw
};

export const ForgotPassword = connect( mapStateToProps, mapDispatchToProps )( ForgotPasswordComponent );

export default ForgotPassword;
