import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { RouteComponentProps, withRouter, NavLink, generatePath } from 'react-router-dom';
import SubMenuComponent from './SubMenu';
import { below } from '../../assets/css/media';
import theme from '../../assets/css/theme';

import { Content } from '../Content';
import {
	NavItem as Item,
	SiteMapMenuWrapper,
	UserControls,
	HeaderNavWrapper,
	HeaderGlobalWrapper,
	BackLink
} from '../NavigationElements';

import {
	IItem,
	user_controls_item
}                      from './menuItems';
import { connect }     from 'react-redux';
import { IStoreState } from '../../modules/types';
import { authStatus }  from '../../modules/selectors';

interface IOwnProps {
	readonly is_logged_in: boolean;
	readonly backLink?: string;
	readonly backTitle?: string;
	readonly items_menu?: IItem[];

}

const HeaderWrapper = styled( HeaderGlobalWrapper )`
	flex-wrap: wrap;
	${Content} {
		flex: 1 1 100%;
		width: 100%;
	}
	${UserControls} {
		${ below.desktop`
			display: none;
		` }
	}
	${ below.desktop`
			display: block;
	` }
	&:first-child {
		background: ${theme.primary};
	}
`;

const HeaderSubNavWrapper = styled( HeaderNavWrapper )`
	margin: 0;
	${below.desktop`
		overflow: auto;
	`}
`;

const SubNavSecondMenu = styled.div``;

type Props = RouteComponentProps & IOwnProps;
class SubNavigationComponent extends React.Component<Props> {

	private get siteMapMenu() {
		const { match, items_menu } = this.props;

		if(!items_menu) {
			return null
		}
		return (
			<SiteMapMenuWrapper>
				{items_menu.map((item: any, i:number) => {
					return (
						<Item key={i}
						      exact={item.exact}
						      to={generatePath(item.link, { [item.generate_path]: _.get(match, `params.${item.generate_path}`, 'id') })}
						>{item.name}</Item>
					)
				})}
			</SiteMapMenuWrapper>
		)
	}

	private get userControlsMenu() {
		return (
			<UserControls>
				<SubMenuComponent item={user_controls_item} mobile={false}/>
			</UserControls>
		)
	}

	private get backLink() {
		const { backLink, backTitle } = this.props;

		return (
				<BackLink>
					<NavLink to={backLink || '-'}>{backTitle || '-'}</NavLink>
				</BackLink>
			)
	}

	/**
	 * @ignore
	 */
	public render() {
		const { is_logged_in, items_menu } = this.props;
		return (
			<SubNavSecondMenu>
				{this.backLink}
				{items_menu && (
					<HeaderWrapper>

						<Content>
							<HeaderSubNavWrapper>
								{this.siteMapMenu}
								{is_logged_in && this.userControlsMenu}
							</HeaderSubNavWrapper>
						</Content>

					</HeaderWrapper>
				)}

			</SubNavSecondMenu>
		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
	is_logged_in : authStatus(state),
});

export const SubNavigation = withRouter(connect(mapStateToProps)(SubNavigationComponent));

export default SubNavigation;