import * as React       from 'react';
import { Bling as GPT } from "react-gpt";
import styled           from 'styled-components';

interface IProps {
	place: string;
	adUnit: string;
}

const AdsWrapper = styled.div`
	margin-bottom: 1em;
	display: flex;
	justify-content: center;
	iframe {
		margin: auto;
	}
`;

export class GoogleTagPublisher extends React.Component<IProps, any> {
	/**
	 * @ignore
	 */
	public render() {
		const sizes = this.getSizes();
		return <AdsWrapper>
			<GPT
				adUnitPath={this.props.adUnit}
				slotSize={[sizes.w, sizes.h]}
			/>
		</AdsWrapper>;
	}
	private getSizes() {
		const { place } = this.props;
		const sizes = {
			sidebar: {
				w: 300,
				h: 250,
			},
			default_size: {
				w: 0,
				h: 0,
			},
		};
		return sizes[place] || sizes.default_size;
	}
}