import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IRequestData } from '../../types';

export interface IRecoverPassReducer extends IRequestData {
	readonly is_success: boolean,
	readonly error_code: number
}

const defaultState: IRecoverPassReducer = {
	is_data_requested: false,
	is_success: false,
	error_code: 0
};

export const user_recover_password = createReducer<IRecoverPassReducer>({}, defaultState)
	.on(actions.userRecoverPassw, () => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(actions.userRecoverPasswSuccess, (state: IRecoverPassReducer) => ({
		...state,
		is_success     : true,
		is_data_requested: false,
	}))
	.on(actions.userRecoverPasswFailed, (state: IRecoverPassReducer, payload: number) => ({
		...defaultState,
		error_code: payload
	}));