import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { below } from '../../assets/css/media';

import { Tooltip } from '../';

const FormGroupWrapper = styled.div`
	padding: 0.25em 0;
	display: flex;
	flex-direction: column;
`;

const HeaderGroup = styled.div`
	font-size: 1.125em;
	font-family: ${theme.base_bold_font};
	margin-bottom: 0.25em;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	${below.desktop`
		font-size: 1em;
	`}
`;

interface IFormGroupSkeleton {
	readonly children: React.ReactNode
	readonly title: string
	readonly tooltip?: string
}

export const FormGroup = ({ children, title, tooltip }: IFormGroupSkeleton) => (
	<FormGroupWrapper>
		<HeaderGroup>
			<span>{title}</span>
			<Tooltip><span>{tooltip}</span></Tooltip>
		</HeaderGroup>
		<div>
			{children}
		</div>
	</FormGroupWrapper>
);

export default FormGroup;