import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { below } from "../../assets/css/media";
import theme from '../../assets/css/theme';

import {
	FormCreator,
	FormWrapper as FormWrapperBase,
	LoadingSpinner,
	RegularButton,
	SecondaryButton,
	TickBoxStyled
} from '../../components';
import * as actions from '../../modules/actions';
import { isRecovered } from "../../modules/selectors";

import {
	IFormProps,
	IStoreState
} from '../../modules/types';


const CancelButton = styled( NavLink )`
	display: block;
	max-width: 300px;
	margin: 0 auto;
	${RegularButton} {
		background-color: white;
		color: ${theme.primary};
		border:1px solid ${theme.primary};
	}
`;

const RegistrationWrapper = styled.div`
	padding: 0 2em;
`;

const SecondaryButtonWithMargin = styled( SecondaryButton )`
	margin-bottom: 0.5em;
	max-width: 300px;
`;

const TermsConditions = styled( NavLink )`
	color: #E81C19;
`;

const RecoverTitle = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 16px;
	color: ${theme.base_text_color};
	padding: 1em 0;
	line-height: 25px;
	text-align: center;
	${below.desktop`
		font-size: 14px;
		padding: 1em 0;
	`}
`;

const FormWrapper = styled(FormWrapperBase)`
    max-width: 600px;
`;

interface IProps {
	recover: () => void
	is_data_requested: boolean
	is_recovered: boolean
}

class RegistrationFormRecoverComponent extends React.Component<IProps> {

	constructor( props: IProps ) {
		super( props );

		_.bindAll( this, [
			'handleSubmitForm',
			'renderedFields',
		] )
	}


	/**
	 * Method to process registration form submission
	 */
	public handleSubmitForm( ) {
		const { recover } = this.props;
		recover( );
	};

	/**
	 * Get Form Fields for Registration form
	 */
	public renderedFields( props: IFormProps ) {
		let TAB_INDEX = 0;
		const { handleChange } = props;

		return (
			<RegistrationWrapper>
				<RecoverTitle>
					Welcome back to Supercars Tipping for 2022! Please accept the 2022 <TermsConditions to="/help/terms">Terms & Conditions</TermsConditions> and you're good to go!
				</RecoverTitle>

				<TickBoxStyled
					id={_.uniqueId()}
					name='terms'
					onChange={handleChange}
					required={true}
					width="100%"
					tabIndex={++TAB_INDEX}
				>
					I have read and agree to the
					<TermsConditions to="/help/terms" target="_blank"> Terms and Conditions*</TermsConditions>
				</TickBoxStyled>

				<SecondaryButtonWithMargin type='submit'
				                           disabled={false}>CONTINUE</SecondaryButtonWithMargin>
				<CancelButton to='/logout'>
					<RegularButton disabled={false}>BACK</RegularButton>
				</CancelButton>
			</RegistrationWrapper>
		)
	}

	/**
	 * @ignore
	 */
	public render() {

		const { is_data_requested, is_recovered } = this.props;

		if (is_recovered) {
			return <Redirect to='/'/>
		}
		return is_data_requested ? (
			<FormWrapper>
				<LoadingSpinner with_cover_bg={true} position="center"/>
			</FormWrapper>
		) : (
			<FormCreator
				as={FormWrapper}
				onSubmit={this.handleSubmitForm}
				render={this.renderedFields}
				initialState={{
					terms: 0
				}}
			/>
		)
	}
}

const mapStateToProps = ( state: IStoreState ) => ({
	is_data_requested: _.get( state, 'user.is_data_requested', false ),
	is_recovered: isRecovered(state)
});

const mapDispatchToProps = {
	recover : actions.userRecover
};


export const RegistrationFormRecover = connect( mapStateToProps, mapDispatchToProps )( RegistrationFormRecoverComponent );
export default RegistrationFormRecover;
