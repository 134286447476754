import { find, partial } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { below } from '../../assets/css/media';
import theme from '../../assets/css/theme';
import tournament_masthead_enduro from '../../assets/img/tournament-masthead-enduro.jpg';

import tournament_masthead from '../../assets/img/tournament-masthead.png';
import {
	LoadingSpinner,
	LongAdComponent,
	PlayerCard,
	PlayerEmpty,
	SecondaryButton,
	ShareSection,
	TimeMargin,
	Tooltip
} from '../../components';
import * as actions from '../../modules/actions';
import { IDriver, IDriverReducersState } from '../../modules/reducers/drivers';
import { IGroup, IGroupReducerState } from '../../modules/reducers/groups';
import { IResultsReducerState } from '../../modules/reducers/results';
import { IPredictionDriver, ITournamentSave, ITournamentState } from '../../modules/reducers/tournament';
import { getDrivers } from '../../modules/selectors';
import { IStoreState } from '../../modules/types';
import { numberPostfix } from '../../modules/utils/dom_helpers';
import { getSelectedGroup, getSelectedGroupId } from '../../modules/utils/tournament';
import { GroupSwitcher } from './GroupSwitcher';


const TournamentWrapper = styled.div`
	background-color: #fff;
	position: relative;
	box-shadow: 0 -5px 10px 0 rgba(0,0,0,.35);
	padding-bottom: 1em;
	${below.desktop`
		overflow-x: hidden;    
	`}
`;

const QuestionsWrapper = styled.div`
	width: 93%;
	margin: auto;
`;

// ${below.desktop`
// 	width: 100%;
// `}

const TournamentMastHead = styled.img`
	width: 100%;
`;

const TitleWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const Title = styled.div`
	color: #333F48;
	font-size: 22px;
	font-family: ${theme.base_bold_font};
	${below.desktop`
		font-size: 18px;
		line-height: 1.2;
	`}
`;

const NextButton = styled(SecondaryButton)`
	position: relative;
	max-width: 300px;
	height: 50px;
	align-items: center;
	display: flex;
	justify-content: center;
	svg {
		position: absolute;
		right: 25px;
	}
	${below.desktop`
		max-width: 95%;
	`}
`;


interface IProps {
	readonly groups: IGroupReducerState;
	readonly drivers: IDriverReducersState;
	readonly changeAction: (options: any) => void;
	readonly fetchResults: () => void;
	readonly fetchResultsInitial: () => void;
	readonly results: IResultsReducerState;
	selected_group: IGroup;
	tournament: ITournamentState
	isForRegister: boolean;
	setTournamentSelectedGroupId: (group_id: number) => void;
	saveAnswers: (answers: ITournamentSave) => void;
	is_data_requested: boolean;
	isAutoSave: boolean;
	selected_group_id: number;
}

class TournamentClass extends React.Component<IProps, any> {
	public groupSwitcherRef: React.RefObject<any> = React.createRef();

	get predictions(): any {
		const { tournament } = this.props;
		const { predictions = [], selected_group_id } = tournament;
		return find(predictions, { race_id: selected_group_id }) || {};
	}

	get drivers() {
		const { selected_group } = this.props;
		const podium = this.predictions.podium;
		if (!podium) {
			return null;
		}
		return podium.map((driver: IPredictionDriver, index: number) =>
			driver.id ? <PlayerCard
				key={`player-${driver.id}`}
				isLocked={this.isLocked}
				driver={this.getDriver(driver.id)}
				playerNumber={(index + 1) + numberPostfix(index)}
				removeCallback={partial(this.removeDriver, driver.id, index)}
				isEndurance={selected_group.endurance}
				showResults={this.isProvisional || this.isFinal}
				isProvisional={this.isProvisional}
				isFinal={this.isFinal}
				podiumPosition={driver.position}
			/> :
			<PlayerEmpty
				key={`player-${index}-0`}
				isLocked={this.isLocked}
				playerNumber={(index + 1) + numberPostfix(index)}
				callback={partial(this.setPlayerPoolVisible, index)}
			/>);
	}

	get margin() {
		const { selected_group, results } = this.props;
		const margin = this.predictions.margin || '00.0000';
		const first_position: any = find(results, { position: 1 });
		const last_position: any = find(results, { position: 2 });
		const actual_margin = first_position && last_position ? last_position.time - first_position.time : '00.0000';
		return selected_group.margin ? (
			<>
				<TitleWrapper>
					<Title>Predict the finishing margin between 1st and 2nd for
						Race {selected_group.number}</Title>
					<Tooltip><span>Select your race margin between 1st and 2nd position</span></Tooltip>
				</TitleWrapper>
				<TimeMargin
					callback={this.saveMargin}
					margin={margin.toString()}
					isLocked={this.isLocked}
					isProvisional={this.isProvisional}
					isFinal={this.isFinal}
					actualMargin={actual_margin.toString()}
				/>
			</>
		) : null;
	}

	get isHasPodium() {
		const predictions = this.predictions;
		const { podium = [] } = predictions;
		let has_all_predictions = true;
		podium.forEach((predict: IDriver) => {
			if (!predict.id) {
				has_all_predictions = false;
			}
		});
		return has_all_predictions;
	}

	get isLocked() {
		const { selected_group } = this.props;
		return selected_group.status !== 'scheduled';
	}

	get isProvisional() {
		const { selected_group } = this.props;
		return Boolean(selected_group.status === 'complete' && !selected_group.finalized);
	}

	get isFinal() {
		const { selected_group } = this.props;
		return Boolean(selected_group.status === 'complete' && selected_group.finalized);
	}

	get CTAText() {
		const { tournament } = this.props;
		const locked_text = 'TIPS LOCKED';
		const pre_locked_text = tournament.is_saved ? (
			<div>PREDICTIONS SAVED</div>) : `SAVE TIPS`;
		return this.isLocked ? locked_text : pre_locked_text;
	}

	get getCTAButtons() {
		const is_disabled = !this.isHasPodium || this.isLocked;

		return this.isProvisional || this.isFinal ? (
			<NextButton
				onClick={this.nextGroup}
			>
				TIP NEXT RACE
			</NextButton>
		) : (
			<NextButton
				onClick={this.saveGroup}
				disabled={is_disabled}
			>
				{this.CTAText}
			</NextButton>
		);
	}

	/**
	 * @ignore
	 */
	public getDriver(driver_id: number) {
		const { drivers } = this.props;
		return find(drivers, { id: driver_id }) || {};
	}

	/**
	 * @ignore
	 */
	public componentWillMount(): void {
		this.props.fetchResultsInitial();
		this.props.fetchResults();
	}

	/**
	 * @ignore
	 */
	public render() {
		const { groups, is_data_requested, isForRegister, selected_group_id, /*selected_group*/ } = this.props;
		// const min = 12;
		// const max = 14;
		// const is_enduro = Boolean( selected_group.round >= min && selected_group.round <= max);
		const is_enduro = false;
		return (
			<>
				<TournamentWrapper>
					{is_data_requested && !isForRegister ?
						<LoadingSpinner with_cover_bg={true} position="center"/> : null}
					<GroupSwitcher
						ref={this.groupSwitcherRef as any}
						callback={this.saveStoreSelectedGroupId}
						selected_group_id={selected_group_id}
						{...groups}
					/>
					<TournamentMastHead src={is_enduro ? tournament_masthead_enduro : tournament_masthead}
					                    alt="Tournament masthead"/>

					<LongAdComponent/>
					<QuestionsWrapper>
						<TitleWrapper>
							<Title>Select your top three drivers</Title>
							<Tooltip><span>Use the driver pool on the right hand side to make your predictions for the upcoming race.</span></Tooltip>
						</TitleWrapper>
						{this.drivers}
						{this.margin}
					</QuestionsWrapper>
					{this.getCTAButtons}
				</TournamentWrapper>
				<ShareSection isCanShare={!this.isHasPodium}/>
			</>
		);
	}

	/*
	* Save selected group id to store
	* */
	private readonly saveStoreSelectedGroupId = (group_id: number) => {
		this.props.setTournamentSelectedGroupId(group_id);
	};

	/**
	 * Set player pool visible
	 */
	private readonly setPlayerPoolVisible = (player_cell_index: number) => {
		if (this.isLocked) {
			return;
		}
		const visibility_options = {
			is_visible: true,
			cell: player_cell_index,
			action: 'setPlayerPoolVisible',
			auto_save: false,
		};
		this.props.changeAction(visibility_options);
	};

	/*
	* Add Player action
	* */
	private readonly removeDriver = (driver_id: number, driver_index: number) => {
		if (this.isLocked) {
			return;
		}
		const options = {
			driver_index,
			driver_id,
			action: 'removeTournamentFreePlayer',
			auto_save: false,
		};
		this.props.changeAction(options);
	};

	/**
	 * Save margin
	 */
	private readonly saveMargin = (seconds: string, milliseconds: string) => {
		const options = {
			action: 'saveMargin',
			margin: +`${seconds}.${milliseconds}`,
			auto_save: false,
		};
		this.props.changeAction(options);
	};

	/*
	* Save selected group answers
	* */
	private readonly saveGroup = () => {
		const { tournament, saveAnswers, selected_group_id } = this.props;
		if (this.isLocked) {
			return;
		}
		const prediction = find(tournament.predictions, { race_id: selected_group_id });
		if (prediction) {
			saveAnswers(prediction);
		}
	};

	/*
	* Next selected group answers
	* */
	private readonly nextGroup = () => {
		this.groupSwitcherRef.current.setSelectedGroup('right');
	};
}

const mapStateToProps = (state: IStoreState) => ({
	tournament: state.tournament,
	is_data_requested: state.tournament.is_data_requested,
	drivers: getDrivers(state),
	results: state.results
});

const mapDispatchToProps = {
	setTournamentSelectedGroupId: actions.setTournamentSelectedGroupId,
	saveAnswers: actions.saveTournamentPredictions,
	changeAction: actions.tournamentChangeAction,
	fetchResults: actions.fetchResults,
	fetchResultsInitial: actions.fetchResultsInitial
};

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => {
	const { selected_group_id } = stateProps.tournament;
	const { groups } = ownProps.groups;

	stateProps.drivers.map((driver: IDriver) => {
		driver.result = find(stateProps.results, { driver_id: driver.id }) || {};
		return driver;
	});

	const group_id = getSelectedGroupId(groups, selected_group_id) || selected_group_id;
	return {
		...dispatchProps,
		...stateProps,
		...ownProps,
		selected_group: getSelectedGroup(group_id, groups),
		selected_group_id: group_id
	};
};

export const Tournament = connect(mapStateToProps, mapDispatchToProps, mergeProps)(TournamentClass);

export default Tournament;
