import * as React                from 'react';
import * as _                    from 'lodash';
import {
	ConfirmField, FormCreator,
	RegularButtonLight
}                                from '../'
import { IFormProps, IPassword } from '../../modules/types';

interface IRecoverFormProps {
	readonly onSubmit: (password: string) => void
	readonly button_text?: string
}

interface IRecoverFormState{
	readonly is_valid: boolean;
}

class RecoverPasswordComponent extends React.Component<IRecoverFormProps, IRecoverFormState> {

	public state = {
		is_valid: false,
	};

	constructor(props: IRecoverFormProps){
		super(props);
		_.bindAll(this, [
			'renderedFields',
			'handleSubmitForm',
			'setValidState',
		])
	}

	/**
	 * Method to process restore password form submission
	 */
	public handleSubmitForm(values: IPassword) {
		const { onSubmit } = this.props;
		onSubmit(_.get(values, 'password', ''));

		this.setState({
			is_valid: false,
		});
	};

	/**
	 * Method to process restore password form submission
	 */
	public setValidState(state: boolean) {
		this.setState({
			is_valid: state,
		})
	};

	/**
	 * Get fields
	 */
	public renderedFields(props: IFormProps){
		const { handleChange } = props;
		const { button_text } = this.props;
		const { is_valid } = this.state;
		return (
			<>
				<ConfirmField
					type="password"
					name='password'
					autoComplete='password'
					placeholder="Enter your password*"
					placeholderConfirm="Confirm password"
					pattern="^.{8,}$"
					title="Min length 8"
					onChange={handleChange}
					required={true}
					stateChange={this.setValidState}
				/>

				<RegularButtonLight
					max_width="300px"
					margin="1em 0"
					type="submit"
					disabled={!is_valid}
				>
					{button_text || 'reset password' }
				</RegularButtonLight>
			</>
		)
	}

	/**
	 * @ignore
	 */
	public render(){

		return (
			<FormCreator
				onSubmit={this.handleSubmitForm}
				render={this.renderedFields}
			/>
		)
	}
}




export const RecoverPassword = RecoverPasswordComponent;

export default RecoverPassword;