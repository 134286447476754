import * as React                                     from 'react';
import * as _                                         from 'lodash';
import styled, { css }                                from 'styled-components';
import theme                                          from '../../../assets/css/theme';
import { IJoinState, ILeagueState }                   from '../../../modules/reducers';
import { LoadingSpinner, LeagueData, LeagueInfo } from '../../../components';

const LeagueWrapper = styled.div`
	margin-bottom: 1.5em;
	position: relative;
	transition: max-height 0.3s ease;
	max-height: 100%;
	${({ visible }: ILeagueWrapper) => ( !visible ? css`
		max-height: 0;
		overflow: hidden;
	` : '' )}
	
`;

const Cover = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: ${theme.primary};
	opacity: 0.5;
	border-radius: 5px;
`;

const JoinButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 135px;
	height: 30px;
	font-size: 18px;
	letter-spacing: 0.72px;
	font-family: ${theme.base_bold_font};
	color: #fff;
	border: 1px solid ${theme.primary};
	background: ${theme.primary};
	text-transform: uppercase;
	cursor: pointer;
`;

interface ILeagueJoinModel {
	readonly league: ILeagueState;
	readonly join_state: IJoinState;
	readonly onJoin: (league_id:string) => void;
}

interface IState {
	readonly visible: boolean;
}

type ILeagueWrapper  = IState

/**
 * League Loin Item
 */
export class League extends React.Component<ILeagueJoinModel, IState> {


	public state = {
		visible: true,
	};

	constructor(props: ILeagueJoinModel){
		super(props);
		_.bindAll(this, [
			'handleJoin',
			'toggleState'
		])
	}

	/**
	 * Handle click on join button
	 */
	public handleJoin(code: string){
		const { onJoin, join_state } = this.props;

		if(!join_state || (join_state && !join_state.is_joined)) {
			onJoin(code);
		}
	}


	/**
	 * Hide elements once timer is completed
	 */
	public toggleState(){
		this.setState({
			visible: false,
		})
	}

	public get joinedCover() {
		const { join_state } = this.props;

		if(join_state) {
			return join_state.is_data_requested ? <LoadingSpinner with_cover_bg={true} position="center"/> :
				join_state.is_joined ? <Cover /> : null
		}
		return null;
	}

	get joinText() {
		const { join_state = {is_joined: false} } = this.props;
		return join_state.is_joined ? 'JOINED' : 'JOIN';
	}
	/**
	 * @ignore
	 */
	public render(){
		const { league } = this.props;
		const { visible } = this.state;

		return league ?  (
			<LeagueWrapper visible={visible}>
				{this.joinedCover}
				<LeagueInfo league={league}>
					<JoinButton onClick={_.partial(this.handleJoin, league.code)}>{this.joinText}</JoinButton>
				</LeagueInfo>

				<LeagueData
					league={{...league, status: 'scheduled'}}
					onCompleteTimer={this.toggleState}
				/>
			</LeagueWrapper>
		) : null
	}
}

export default League
