import * as React      from 'react';
import styled, { css } from 'styled-components';
import { Icon }        from '../Icons';
import { partial }     from 'lodash';
import { share }       from '../../modules/utils/social_share';

const ShareWrapper = styled.div`
	background: #333F48;
	padding: 1em 0;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,.35);
	color: #fff;
	position: relative;
`;

const ShareTitle = styled.div`
	font-size: 14px;
	font-family: TheSun-Bold, sans-serif;
	text-transform: uppercase;
	letter-spacing: .7px;
	margin-bottom: 1em;
`;

const ShareBtns = styled.div`
	display: flex;
	div {
		margin: 0 .7em;
		cursor: pointer;
	}
`;

const OpacityWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${({isDisabled}: IOpacityWrapper) => {
		const opacity = isDisabled && css`
			opacity: .2;
			`;
		return [opacity];
	}}
`;

const Cover = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
`;

interface IOpacityWrapper {
	isDisabled: boolean
}

const handleShare = (type_share: 'tw' | 'fb') => {
	share({
		sc: type_share,
		section: 'predictions',
		message: `I've made my tips for the next round. Make yours now with Supercars Tipping and see if you can climb to the top of the podium.`
	});
};

interface IProps {
	isCanShare: boolean;
}

export const ShareSection = (props: IProps) => (
	<ShareWrapper>
		<OpacityWrapper isDisabled={props.isCanShare}>
			<ShareTitle>SHARE YOUR PREDICTIONS</ShareTitle>
			<ShareBtns>
				<div onClick={partial(handleShare, 'fb')}>
					<Icon name={'facebook'} size={'24'} />
				</div>
				<div onClick={partial(handleShare, 'tw')}>
					<Icon name={'twitter'} size={'24'} />
				</div>
			</ShareBtns>
		</OpacityWrapper>
		{props.isCanShare && <Cover/>}
	</ShareWrapper>
);
