export default {
    primary: '#E8351A',
    secondary: '#20262A',
	body_bg: '#EDF0F2',
	content_width: '1240px',
	base_text_color: '#333F48',
	base_font: 'FomoRegular,sans-serif',
    base_bold_font: 'FomoBold,sans-serif',
	secondary_font: 'Source Sans Pro,sans-serif',
	buttons_color: '#302C28',
	mobile_menu_font: 'FomoBold,sans-serif',
	mobile_menu_color: '#343232',
	header_bg: '#ffffff',
	ladder_bg_item: '#fff',
	fh_footer_bg: '#1B1B1B'
};