import * as React from 'react';

import { Content } from '../';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SubMenuComponent from './SubMenu';
import {
	NavItem as Item,
	SiteMapMenuWrapper,
	UserControls,
	HeaderNavWrapper,
	HeaderGlobalWrapper,
} from '../NavigationElements';
import { Items as items_menu, user_controls_item } from './menuItems';



class NavigationMainComponent extends React.Component<RouteComponentProps> {

	private get siteMapMenu() {
		return (
			<SiteMapMenuWrapper>
				{items_menu.map((item: any, i) => (
					item.subMenu ? (
						<SubMenuComponent key={i} item={item} mobile={false}/>
					): (
						<Item key={i} exact={item.exact} to={item.link} {...item.options}>{item.name}</Item>
					)
				))}
			</SiteMapMenuWrapper>
		)
	}

	private get userControlsMenu() {
		return (
			<UserControls>
				<SubMenuComponent item={user_controls_item} mobile={false}/>
			</UserControls>
		)
	}

	/**
	 * @ignore
	 */
	public render() {

		return (
			<HeaderGlobalWrapper>

				<Content>
					<HeaderNavWrapper>
						{this.siteMapMenu}
						{this.userControlsMenu}
					</HeaderNavWrapper>
				</Content>

			</HeaderGlobalWrapper>
		)
	}
}

export const NavigationMain = withRouter(NavigationMainComponent);

export default NavigationMain;