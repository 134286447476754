import { connect } from "react-redux";
import * as actions from "../../modules/actions";

interface IFetchJsons {
    readonly fetch: () => void
}

const FetchJsonComponent = ({ fetch }: IFetchJsons) => {
	fetch();
    return null
};

const mapDispatchToProps = {
    fetch: actions.fetchJSONSOnLogin
};

export const FetchJsons = connect(null, mapDispatchToProps)(FetchJsonComponent);