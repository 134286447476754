import styled       from 'styled-components';
import * as React   from 'react';
import * as _       from 'lodash';
import * as actions from '../../modules/actions';

import { connect }                                     from 'react-redux';
import { ICollectionState, IRequestData, IStoreState } from '../../modules/types';
import { IJoinLeagueState, ILeagueState }              from '../../modules/reducers/leagues';
import { IActionSearch }                               from '../../modules/sagas/leagues';
import { League }                                      from './JoinLeague/League'

import {
	ContentWithSidebar, FetchJsons,
	LeaguesNotFount,
	LoadingSpinner,
	PageTitle, PointsSystem,
	SearchField,
	SecondaryButtonLeftSide,
	SidebarAdComponent,
	WithMainNav,
} from '../../components';
import { below }                         from '../../assets/css/media';
import { Redirect, RouteComponentProps } from 'react-router';

const LeagueTitleSmall = styled( PageTitle )`
	
	${below.desktop`
		font-size: 1em;
		padding: 0.5em 0
	`}
`;

const SearchFieldCustom = styled( SearchField )`
	width: 100%;
`;

interface ICode {
	readonly code?: string | undefined
}

interface IJoinLeaguesModel extends IRequestData, RouteComponentProps<ICode> {
	readonly fetchListLeagues: (params: IActionSearch) => void;
	readonly clearLeagues: () => void;
	readonly loadMore: () => void;
	readonly join: (code: string) => void;
	readonly search_and_join: (value: string ) => void;
	readonly leagues: ICollectionState<ILeagueState> & IJoinLeagueState,
	readonly is_data_requested: boolean,
}

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>])

class JoinLeaguesComponent extends React.Component<IJoinLeaguesModel> {

	private readonly search: (params: IActionSearch) => void;

	constructor(props: IJoinLeaguesModel){
		super(props);
		_.bindAll(this, [
			'handleJoinClick',
			'handleSearch'
		]);
		const HALF_ONE_SEC = 500;

		this.search = _.debounce(this.searchFunc, HALF_ONE_SEC);
	}

	/**
	 * Handle on Join event from League component.
	 * function is called as _.partial(onJoin, league.id)
	 */
	public handleJoinClick(league_code: string){
		const { join } = this.props;

		join(league_code);
	}

	/**
	 * Handle on Search event from League component.
	 */
	public handleSearch(e: React.SyntheticEvent<EventTarget>) {
		const target = e.target;
		const value = _.trim(_.get(target, 'value'));

		this.search({
			value,
		});
	};

	/**
	 * Get All leagues
	 */
	public componentDidMount() {
		const { match: { params }, search_and_join } = this.props;
		const value = _.get(params, 'code', '');
		if(!_.isEmpty(params.code)){
			search_and_join(value || '')
		}
		else {
			this.fetchLeagues();
		}

	}

	/**
	 * Clear Join To League Model
	 */
	public componentWillUnmount(){
		this.clearLeagues();
	}

	public get leagues() {
		const { leagues: { items, next, joined_codes } } = this.props;
		const { match: { params } } = this.props;
		const code_url = _.get(params, 'code', '');

		if(code_url && joined_codes[code_url] && !joined_codes.is_joined){
			this.fetchLeagues();
			return <Redirect to={`/leagues/join`}/>
		}
		return <>
		{items.length ? (
			items.map((league: ILeagueState | null, i:number) => league && (
				<League
					key={i}
					onJoin={this.handleJoinClick}
					join_state={ joined_codes[league.code] }
					league={league}
				/>
			))
		) : (
			<LeaguesNotFount>Leagues not found</LeaguesNotFount>
		)}
		{next ? (
			<SecondaryButtonLeftSide onClick={this.loadMore}>Load more</SecondaryButtonLeftSide>
		) : null}
		</>
	}
	/**
	 * @ignore
	 */
	public render() {
		const { is_data_requested } = this.props;
		const { match: { params } } = this.props;
		const code_url = _.get(params, 'code', '');
		return (
			<WithMainNav>
				<FetchJsons/>
				<ContentWithSidebar
					sidebar_components={[Ads]}
				>
					<PageTitle>
						Invited to a private league?
					</PageTitle>
					<SearchFieldCustom
						type="text"
						placeholder="Enter your PIN or search here"
						defaultValue={code_url}
						onChange={this.handleSearch}
					/>
					<LeagueTitleSmall>
						Public leagues
					</LeagueTitleSmall>

					{this.leagues }
					{is_data_requested ? (
						<LoadingSpinner with_cover_bg={true} position="center"/>
					) : null}
				</ContentWithSidebar>
			</WithMainNav>
		)
	}

	private fetchLeagues(){
		const { fetchListLeagues } = this.props;

		fetchListLeagues({});
	}

	private readonly loadMore = () => {
		const { loadMore } = this.props;

		loadMore();
	};

	private searchFunc(params: IActionSearch){
		const { fetchListLeagues } = this.props;

		fetchListLeagues(params);
		this.clearLeagues();
	}


	private clearLeagues(){
		const { clearLeagues } = this.props;

		clearLeagues();
	}
}

const mapStateToProps = (state: IStoreState) => ({
	leagues: _.get(state, 'join_leagues'),
	is_data_requested: _.get(state, 'join_leagues.is_data_requested'),
});

const mapDispatchToProps = {
	fetchListLeagues: actions.getLeaguesForJoin,
	loadMore: actions.getLeaguesForJoinMore,
	clearLeagues: actions.clearLeaguesForJoin,
	join: actions.joinToLeague,
	search_and_join: actions.searchAndJoin,
};
export const JoinLeagues = connect(mapStateToProps, mapDispatchToProps)(JoinLeaguesComponent);
