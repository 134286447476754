import styled, { css }                                         from 'styled-components';
import theme                                                   from "../../assets/css/theme";
import RegularButton, { RegularButtonHref, RegularButtonLink } from './RegularButton';
// import { below }                                               from '../../assets/css/media';

const SecondaryButtonStyle = css`
	background: ${theme.primary};
	border: 1px solid ${theme.primary};
	color: white;
`;

export const SecondaryButton = styled(RegularButton)`
	${SecondaryButtonStyle}
`;

export const SecondaryButtonHref = styled(RegularButtonHref)`
	${SecondaryButtonStyle}
`;

export const SecondaryButtonLink = styled(RegularButtonLink)`
	${SecondaryButtonStyle}
`;


export default SecondaryButton;
