import { createReducer }            from 'redux-act';
import * as actions                 from '../../actions';
import { IAuthState, IRequestData } from '../../types';
import { IModelUser }               from '../../types/user';
import { getUserObjectFromStorage } from '../../utils/user';

export interface IUserReducerState extends IModelUser, IRequestData, IAuthState {
	readonly first_name: string;
	readonly last_name: string;
}

const saved_user: IModelUser = getUserObjectFromStorage();

const defaultState: IUserReducerState = {
	is_logged_in         : !!localStorage.getItem( 'is_authorized' ),
	is_data_requested    : false,
	id                   : 0,
	email                : '',
	first_name           : '',
	last_name            : '',
	team_name            : '',
	gender               : '',
	birthday             : '',
	country              : '',
	address              : '',
	facebook_id          : '',
	lang                 : '',
	brand                : 0,
	news                 : 0,
	disable_notifications: 0,
	team_supported       : '',
	postcode             : '',
	mobile               : '',
	recovered               : 0,
	weekly_results       : 0,
	weekly_reminders     : 0,
	...saved_user,
};


export const user = createReducer<IUserReducerState>( {}, defaultState );


/**
 * Login Form Actions
 */
user.on( actions.userLogin, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_data_requested: true,
}) );

user.on( actions.userLoginSuccess, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	...payload,
	is_logged_in     : true,
	is_data_requested: false,
}) );

user.on( actions.userLoginFailed, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_logged_in     : false,
	is_data_requested: false,
}) );

/**
 * Register Form Actions
 */
user.on( actions.userRegister, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_data_requested: true,
}) );

user.on( actions.userRegisterSuccess, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	...payload,
	is_logged_in     : true,
	is_data_requested: false,
}) );

user.on( actions.userRegisterFailed, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_logged_in     : false,
	is_data_requested: false,
}) );

user.on( actions.userLogoutSuccess, ( state: IUserReducerState ) => ({
	...state,
	is_logged_in     : false,
	is_data_requested: false,
}) );

/**
 * Update Form Actions
 * userUpdate
 * userUpdateSuccess
 * userUpdateFailed
 */
user.on( actions.userUpdate, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_data_requested: true,
}) );

user.on( actions.userUpdateSuccess, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	...payload,
	is_data_requested: false,
	is_stored        : true
}) );

user.on( actions.userUpdateFailed, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_data_requested: false,
}) );

/**
 * Returning Form Actions
 * userRecover
 * userRecoverSuccess
 * userRecoverFailed
 */
user.on( actions.userRecover, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_data_requested: true,
}) );

user.on( actions.userRecoverSuccess, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	...payload,
	is_data_requested: false,
	is_stored        : true
}) );

user.on( actions.userRecoverFailed, ( state: IUserReducerState, payload: IUserReducerState ) => ({
	...state,
	is_data_requested: false,
}) );


export default user;
