import { SagaIterator }              from 'redux-saga';
import { call, put }                 from 'redux-saga/effects';
import { ISagaAction, NumberOrNone } from '../../types';

export const DriversFetchSaga = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {

	const drivers = yield call( action.meta.api.JSON_SPORT.drivers );

	if ( drivers.error ) {
		// yield put( action.meta.actions.fetchDriversJSONFailed( { message: drivers.error } ) );
		return;
	}


	yield put( action.meta.actions.fetchDriversJSONSuccess( drivers ) );

};

export default DriversFetchSaga;
