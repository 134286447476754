import * as React                                  from 'react';
import * as _                                      from 'lodash';
import { connect }                                 from 'react-redux';
import * as actions                                from '../modules/actions';
import { IRecoverPassReducer, IUserReducerState }  from '../modules/reducers/user';
import styled                                      from 'styled-components';
import { IFormProps, IStoreState, IUserFormState } from '../modules/types';
import { LoadingSpinner }                          from '../components/AppLoading';

import {
	ContentWithSidebar,
	Field, FormCreator,
	GroupOptions,
	PageTitle,
	RecoverPassword,
	SecondaryButton, SelectStyled,
	TickBoxStyled,
	WithMainNav,
}                                           from '../components';
import { ICountriesReducerState, ICountry } from '../modules/reducers/countries';
import * as moment from 'moment';

const SmallTitle = styled( PageTitle )`
	font-size: 1.125em
`;

const BirthDayWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const DOBSelect = styled( SelectStyled )`
	width: 32%;
`;

interface IMapToProps {
	readonly user_recover_password: IRecoverPassReducer;
	readonly user: IUserReducerState;
	readonly countries: ICountriesReducerState;
}

type Props = {
	readonly fetchCountriesJSON: () => void;
	readonly fetchSquadsJSON: () => void;
	readonly send: ( params: any ) => void;
} & IMapToProps;

class RecoverPasswordFormComponent extends React.Component<Props> {
	public state = {
		day           : '1',
		month         : '1',
		year          : moment()
			.format( 'YYYY' ),
		current_year  : moment()
			.format( 'YYYY' )
	};
	constructor( props: Props ) {
		super( props );
		_.bindAll( this, [
			'handleChangePassword',
			'handleSubmitUpdate',
			'renderedFields'
		] )
	}

	/**
	 * Method to process restore password form submission
	 */
	public handleChangePassword( password: string ) {
		const { send } = this.props;
		send( {
			password
		} );
	};

	/**
	 * Method to process restore user's fields form submission
	 */
	public handleSubmitUpdate( values: IUserFormState ) {
		const { send } = this.props;
		const { day, month, year } = values;
		const birthday = moment( `${year}-${month}-${day}`, 'YYYY-M-D' ).format('YYYY-MM-DD');

		const User = Object.assign( { ...values }, { birthday } );
		send( {
			..._.omit( User, 'day', 'month', 'year' )
		} );
	};


	/**
	 * Get My account form
	 */
	public renderedFields( props: IFormProps, values: IUserFormState ) {
		let TAB_INDEX = 0;
		const { handleChange } = props;
		const {
			first_name,
			last_name,
			disable_notifications,
			email,
			is_changed,
			team_name,
			country,
			postcode,
			day,
			month,
			year,
			gender,
			weekly_results,
			weekly_reminders
		} = values;

		return (
			<>
				<SmallTitle>My Details</SmallTitle>
				<Field
					type="text"
					id={_.uniqueId( 'first_name' )}
					name='first_name'
					defaultValue={first_name}
					placeholder="First Name*"
					title="Invalid first name"
					pattern="^[a-zA-Z\s\-]{1,40}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>
				<Field
					type="text"
					id={_.uniqueId( 'last_name' )}
					name='last_name'
					placeholder="Last Name*"
					title="Invalid last name"
					pattern="^[a-zA-Z\s\-]{1,40}$"
					tabIndex={++TAB_INDEX}
					defaultValue={last_name}
					onChange={handleChange}
					required={true}
				/>
				<Field
					type="email"
					name='email'
					autoComplete='email'
					placeholder="Email *"
					tabIndex={++TAB_INDEX}
					defaultValue={email}
					onChange={handleChange}
					required={true}
				/>
				<Field
					type="text"
					id={_.uniqueId( 'team_name' )}
					name='team_name'
					placeholder="Enter team Name*"
					title="Invalid team name"
					defaultValue={team_name}
					pattern="^[a-zA-Z\s\d\-]{1,40}$"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
				/>

				<SelectStyled
					name="country"
					tabIndex={++TAB_INDEX}
					onChange={handleChange}
					required={true}
					value={country}
				>
					<option key='default' value='' disabled={true}>
						Please enter your country of residence
					</option>
					{this.countryOptions}
				</SelectStyled>
				{country === 'AU' && (
					<Field
						type="text"
						id={_.uniqueId( 'postcode' )}
						name='postcode'
						placeholder="Postcode*"
						title="Invalid postcode"
						defaultValue={postcode}
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
					/>
				)}
				<BirthDayWrapper>
					<DOBSelect
						name="day"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
						value={day}
					>
						{this.dayOptions( month, year )}
					</DOBSelect>
					<DOBSelect
						name="month"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
						value={month}
					>
						{this.monthOptions}
					</DOBSelect>
					<DOBSelect
						name="year"
						tabIndex={++TAB_INDEX}
						onChange={handleChange}
						required={true}
						value={year}
					>
						{this.yearOptions}
					</DOBSelect>
				</BirthDayWrapper>

				<GroupOptions>
					<TickBoxStyled
						id={_.uniqueId()}
						name='gender'
						onChange={handleChange}
						tabIndex={++TAB_INDEX}
						tick_position="left"
						type="radio"
						value='male'
						required={true}
						checked={gender  === 'male'}
					>
						Male
					</TickBoxStyled>
					<TickBoxStyled
						id={_.uniqueId()}
						name='gender'
						onChange={handleChange}
						tabIndex={++TAB_INDEX}
						tick_position="left"
						type="radio"
						value='female'
						required={true}
						checked={gender  === 'female'}
					>
						Female
					</TickBoxStyled>
				</GroupOptions>

				<SmallTitle>We give you full flexibility over what notification emails you
					receIve:</SmallTitle>

				<TickBoxStyled
					id={_.uniqueId()}
					name='disable_notifications'
					onChange={handleChange}
					tabIndex={++TAB_INDEX}
					defaultChecked={disable_notifications}
					width="100%"
				>
					All Communications<br/>
					By checking this box you will not any receive game emails
				</TickBoxStyled>

				<TickBoxStyled
					id={_.uniqueId()}
					name='weekly_reminders'
					onChange={handleChange}
					tabIndex={++TAB_INDEX}
					defaultChecked={Boolean( weekly_reminders )}
					width="100%"
				>
					Weekly Results<br/>
					By un-checking this box you will not receive the Weekly Results email
				</TickBoxStyled>

				<TickBoxStyled
					id={_.uniqueId()}
					name='weekly_results'
					onChange={handleChange}
					tabIndex={++TAB_INDEX}
					defaultChecked={Boolean( weekly_results )}
					width="100%"
				>
					Weekly Results<br/>
					By un-checking this box you will not receive the Weekly Reminder email
				</TickBoxStyled>

				<SecondaryButton
					max_width='300px'
					margin="1em auto 1em 0"
					type='submit'
					disabled={!is_changed}
				>
					UPDATE MY DETAILS
				</SecondaryButton>
			</>
		)
	}

	public get mainForm() {
		const { user } = this.props;
		const {
			is_data_requested,
			first_name,
			last_name,
			disable_notifications,
			email,
			brand,
			news,
			team_name,
			country,
			postcode,
			birthday,
			gender,
			weekly_results,
			weekly_reminders
		} = user;
		const birthday_date = moment(birthday, 'YYYY-MM-DD');
		const day = birthday_date.date();
		const month = birthday_date.month()+1;
		const year = birthday_date.year();

		return is_data_requested ? (
			<LoadingSpinner with_cover_bg={true} position="center"/>
		) : (
			<FormCreator
				onSubmit={this.handleSubmitUpdate}
				render={this.renderedFields}
				initialState={{
					first_name,
					last_name,
					disable_notifications,
					email,
					brand,
					news,
					team_name,
					country,
					postcode,
					day,
					month,
					year,
					gender,
					weekly_results,
					weekly_reminders
				}}
			/>
		)
	}

	public get changePasswordForm() {

		return (
			<>
				<SmallTitle>Change your password</SmallTitle>
				<RecoverPassword onSubmit={this.handleChangePassword}
				                 button_text="change password"/>
			</>
		)
	}

	/**
	 * @ignore
	 */
	public componentWillMount(): void {
		this.props.fetchCountriesJSON();
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<WithMainNav>
				<ContentWithSidebar empty_sidebar={true}>
					<PageTitle>My Account</PageTitle>
					{this.mainForm}
					{this.changePasswordForm}
				</ContentWithSidebar>
			</WithMainNav>
		)
	}

	private get countryOptions() {
		const { countries = [] } = this.props;
		return countries.map( ( country: ICountry, i: number ) => (
			<option key={`countries-${i}`} value={country.code}>
				{country.name}
			</option>
		) );
	}

	private get monthOptions() {
		const month_names = moment.months();
		const month_options = month_names.map( ( month : any, key: any ) => ({
			id  : key + 1,
			name: month
		}) );
		return month_options.map( ( month: any, i: number ) => (
			<option key={`month-${i}`} value={month.id}>
				{month.name}
			</option>
		) );
	}

	private get yearOptions() {
		const { current_year } = this.state;
		const minYears = 100;
		const last_year = Number( current_year ) - minYears;
		const years_list = [];
		for ( let i = Number( current_year ); i >= last_year; i-- ) {
			years_list.push( {
				id  : i,
				name: i
			} )
		}
		return years_list.map( ( year: any, i: number ) => (
			<option key={`year-${i}`} value={year.id}>
				{year.name}
			</option>
		) );
	}

	/**
	 * @ignore
	 */
	public dayOptions( month: any, year: any ) {
		const days_count = moment( `${year}-${month}`, 'YYYY-M' )
			.daysInMonth();
		const days_list = [];
		for ( let i = 1; i <= days_count; i++ ) {
			days_list.push( {
				id  : i,
				name: i
			} )
		}
		return days_list.map( ( day: any, i: number ) => (
			<option key={`year-${i}`} value={day.id}>
				{day.name}
			</option>
		) );
	}
}

const mapStateToProps = ( state: IStoreState ): IMapToProps => {
	return {
		user_recover_password: state.user_recover_password,
		user                 : state.user,
		countries            : state.countries,
	}
};

const mapDispatchToProps: any = {
	send              : actions.userUpdate,
	fetchCountriesJSON: actions.fetchCountriesJSON,
	fetchSquadsJSON   : actions.fetchSquadsJSON
};

export const RecoverPasswordForm = connect( mapStateToProps, mapDispatchToProps )( RecoverPasswordFormComponent );

export default RecoverPasswordForm;
