import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IRequestData } from '../../types';

export interface ILeagueLeaveReduser extends IRequestData {
	readonly is_success?: boolean;
}

const defaultState: ILeagueLeaveReduser = {
	is_data_requested: false,
	is_success: false,
};

export const league_leave = createReducer<ILeagueLeaveReduser>({}, defaultState)
	.on(actions.leaveLeague, (state: ILeagueLeaveReduser) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.leaveLeagueSuccess, (state: ILeagueLeaveReduser) => ({
		...state,
		is_success: true,
		is_data_requested: false,
	}))
	.on(actions.leaveLeagueFailed, (state: ILeagueLeaveReduser) => ({
		...defaultState,
		is_data_requested: false,
	}))
	.on(actions.clearLeagueCreate, (state: ILeagueLeaveReduser) => ({
		...defaultState,
		is_data_requested: false,
	}));

