import styled    from 'styled-components';
import info_icon from '../../assets/img/icons/info.png';

export const Tooltip = styled.button.attrs({
	type: 'button'
})`
	width: 20px;
	height: 20px;
	background: url(${info_icon}) no-repeat;
	border: none;
	box-shadow: none;
	cursor: pointer;
	background-size: contain;
	span {
		opacity: 0;
		position: absolute;
		right: -150%;
		top: 0;
		background-color: #fff;
		padding: 0.5em;
		border-radius: 5px;
		max-width: 50%;
		transition: all 0.3s ease-in;
		box-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
		z-index: 3;
	}
	&:hover {
		span {
			right: 2.5em;
			left: auto;
			opacity: 1;
		}
	}
`;