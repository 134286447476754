import * as sagas from '../sagas';
import * as selectors from '../selectors';
import {
	ActionsCreator,
	Api
} from '../utils';


const actions = ActionsCreator.mixin({
	userLogin          : 'User Log in Event',
	userLogout         : 'User Log out Event',
	userLoginSuccess   : 'User Log in Event Success',
	userLoginFailed    : 'User Log in Event Failed',
	userRegister       : 'User Registration Event',
	userRegisterSuccess: 'User Registration Event Success',
	userRegisterFailed : 'User Registration Event Failed',
	userUpdate         : 'User Update Event',
	userUpdateSuccess  : 'User Update Event Success',
	userUpdateFailed   : 'User Update Event Failed',

	userRecover       : 'User Recover Event',
	userRecoverSuccess: 'User Recover Event Success',
	userRecoverFailed : 'User Recover Event Failed',

	userForgotPassw                    : 'User Forgot Password Event',
	userForgotPasswSuccess             : 'User Forgot Passwor Event Success',
	userForgotPasswFailed              : 'User Forgot Passwor Event Failed',
	userRecoverPassw                   : 'User Recover Password Event',
	userRecoverPasswSuccess            : 'User Recover Passwor Event Success',
	userRecoverPasswFailed             : 'User Recover Passwor Event Failed',
	userLogoutSuccess                  : 'User Log Out Event Success',
	fetchGroupsJSON                    : 'Fetch Groups JSON',
	fetchGroupsJSONSuccess             : 'Fetch Groups JSON Success',
	fetchGroupsJSONFailed              : 'Fetch Groups JSON Failed',
	errorsManagement                   : 'Error Management',
	errorsShowGlobalError              : 'Error Show Global Error',
	errorsGlobalError                  : 'Error Global Error',
	errorsClearGlobalError             : 'Error Clear Global Error',
	setTournamentSelectedGroupId       : 'Set Tournament Selected Group Id',
	setTournamentSelectedGroupIdSuccess: 'Set Tournament Selected Group Id Success',
	fetchSquadsJSON                    : 'Fetch Squads JSON',
	fetchSquadsJSONSuccess             : 'Fetch Squads JSON Success',
	fetchSquadsJSONFailed              : 'Fetch Squads JSON Failed',
	fetchRoundsJSON                    : 'Fetch Rounds JSON',
	fetchRoundsJSONSuccess             : 'Fetch Rounds JSON Success',
	fetchRoundsJSONFailed              : 'Fetch Rounds JSON Failed',
	fetchJSONS                         : 'Fetch JSONS',
	fetchJSONSOnLogin                  : 'Fetch JSONS On Login',
	fetchJSONSSuccess                  : 'Fetch JSONS Success',
	fetchJSONSFailed                   : 'Fetch JSONS Failed',

	setTournamentDefaults            : 'Set Tournament Defaults Questions',
	addTournamentFreePlayer          : 'Add Player To First Free Tournament Place',
	addTournamentFreePlayerSuccess   : 'Add Player To First Free Tournament Place Success',
	removeTournamentFreePlayer       : 'Remove Tournament Player',
	removeTournamentFreePlayerSuccess: 'Remove Tournament Player Success',

	setPlayerPoolVisible             : 'Set PlayerPool visibility',
	createLeague                     : 'Create League Event',
	createLeagueSuccess              : 'Create League Event Success',
	createLeagueFailed               : 'Create League Event Failed',
	showLeague                       : 'Show League By Id Event',
	showLeagueSuccess                : 'Show League By Id Success',
	showLeagueFailed                 : 'Show League By Id Failed',
	updateLeague                     : 'Update League By Id Event',
	updateLeagueSuccess              : 'Update League By Id Success',
	updateLeagueFailed               : 'Update League By Id Failed',
	joinToLeague                     : 'Join To League By Code Event',
	joinToLeagueSuccess              : 'Join To League By Code Success',
	joinToLeagueFailed               : 'Join To League By Code Failed',
	searchAndJoin                    : 'Search And Join By Code Failed',
	leaveLeague                      : 'Leave League By Id Event',
	leaveLeagueSuccess               : 'Leave League By Id Success',
	leaveLeagueFailed                : 'Leave League By Id Failed',
	clearLeagueCreate                : 'Clear Create League Event',
	setTournamentBinaryValue         : 'Set Tournament binary value',
	getLeagues                       : 'Get Leagues ',
	getLeaguesSuccess                : 'Get Leagues Success',
	getLeaguesMore                   : 'Load More Leagues',
	getLeagueLadder                  : 'Get League Ladder Event',
	getLeagueLadderData              : 'Get League Ladder With League Event',
	getLeagueLadderDataSuccess       : 'Get League Ladder Event Success',
	getLeagueLadderDataFailed        : 'Get League Ladder Event Failed',
	getLeagueLadderDataMore          : 'Load More League Ladder',
	clearLeagueLadder                : 'Clear League Ladder',
	clearLeagues                     : 'Clear My Leagues State',
	getLeaguesFailed                 : 'Get Leagues Failed',
	getLeaguesForJoin                : 'Get Leagues For Join',
	getLeaguesForJoinSuccess         : 'Get Leagues For Join Success',
	getLeaguesForJoinMore            : 'Load More Leagues For Join',
	clearLeaguesForJoin              : 'Clear Leagues For Join State',
	getLeaguesForJoinFailed          : 'Get Leagues For Join Failed',
	setTournamentSliderValue         : 'Set Tournament Slider Value',
	saveTournamentPredictions        : 'Save Tournament Group',
	saveTournamentPredictionsSuccess : 'Save Tournament Group Success',
	fetchTournamentPredictions       : 'Get Tournament Predictions',
	fetchTournamentPredictionsSuccess: 'Get Tournament Predictions Success',
	tournamentChangeAction           : 'Tournament Change Action',
	setTournamentDefaultStore        : 'Set Tournament Default Store',
	fetchSnapshot                    : 'Fetch Snapshot',
	fetchSnapshotSuccess             : 'Fetch Snapshot Success',
	setTournamentBinaryMatchValue    : 'Set Tournament Binary Match Value',
	inviteViaEmail                   : 'Invite Via Email Event',
	inviteViaEmailSuccess            : 'Invite Via Email Event Success',
	inviteViaEmailFailed             : 'Invite Via Email Event Failed',
	inviteViaEmailClear              : 'Invite Via Email Event Clear',
	socialShare                      : 'Social Share Event',
	fetchGuidelines                  : 'Fetch Guidelines',
	fetchGuidelinesSuccess           : 'Fetch Guidelines Success',
	fetchTerms                       : 'Fetch Terms',
	fetchTermsSuccess                : 'Fetch Terms Success',
	fetchFAQS                        : 'Fetch FAQS',
	fetchFAQSSuccess                 : 'Fetch FAQS Success',
	fetchPrizes                      : 'Fetch Prizes',
	fetchPrizesSuccess               : 'Fetch Prizes Success',
	saveContactUs                    : 'Save Contact Us Form',
	saveContactUsSuccess             : 'Save Contact Us Form Success',
	saveContactUsFailed              : 'Save Contact Us Form Failed',
	fetchCountriesJSON               : 'Fetch Countries',
	fetchCountriesJSONSuccess        : 'Fetch Countries Success',
	fetchCountriesJSONFailed         : 'Fetch Countries Failed',
	fetchDriversJSON                 : 'Fetch Drivers JSON',
	fetchDriversJSONSuccess          : 'Fetch Drivers JSON Success',
	fetchDriversJSONFailed           : 'Fetch Drivers JSON Failed',
	saveMargin                       : 'Save Margin',
	saveMarginSuccess                : 'Save Margin Success',
	fetchResults                     : 'Fetch Results',
	fetchResultsSuccess              : 'Fetch Results Success',
	fetchResultsInitial              : 'Fetch Results Initial',
	fetchResultsFailed               : 'Fetch Results Failed',
	unsubscribe                      : 'Unsubscribe',
	unsubscribeSuccess               : 'Unsubscribe Success',
	unsubscribeFailed                : 'Unsubscribe Failed'
})
	.createWith({
		api: Api,
		sagas,
		selectors
	});

export const {
	/**
	 * User's actions
	 */
	userLogin,
	userLoginSuccess,
	userLoginFailed,
	userLogout,
	userLogoutSuccess,
	userRegister,
	userRegisterSuccess,
	userRegisterFailed,
	userForgotPassw,
	userForgotPasswSuccess,
	userForgotPasswFailed,
	userRecoverPassw,
	userRecoverPasswSuccess,
	userRecoverPasswFailed,
	userUpdate,
	userUpdateSuccess,
	userUpdateFailed,
	unsubscribe,
	unsubscribeSuccess,
	unsubscribeFailed,

	userRecover,
	userRecoverSuccess,
	userRecoverFailed,

	/**
	 * JSON's actions
	 */
	fetchGroupsJSON,
	fetchGroupsJSONSuccess,
	fetchGroupsJSONFailed,
	fetchSquadsJSON,
	fetchSquadsJSONSuccess,
	fetchSquadsJSONFailed,
	fetchRoundsJSON,
	fetchRoundsJSONSuccess,
	fetchRoundsJSONFailed,
	fetchJSONS,
	fetchJSONSSuccess,
	fetchJSONSFailed,
	fetchJSONSOnLogin,
	fetchGuidelines,
	fetchGuidelinesSuccess,
	fetchTerms,
	fetchTermsSuccess,
	fetchFAQS,
	fetchFAQSSuccess,
	fetchPrizes,
	fetchPrizesSuccess,
	fetchCountriesJSON,
	fetchCountriesJSONSuccess,
	fetchCountriesJSONFailed,
	fetchDriversJSON,
	fetchDriversJSONSuccess,
	fetchDriversJSONFailed,
	fetchResults,
	fetchResultsSuccess,
	fetchResultsFailed,
	fetchResultsInitial,
	/**
	 * Global errors manager
	 */
	errorsManagement,
	errorsGlobalError,
	errorsShowGlobalError,
	errorsClearGlobalError,
	/**
	 * Tournament actions
	 */
	setTournamentSelectedGroupId,
	setTournamentSelectedGroupIdSuccess,
	setTournamentDefaults,
	addTournamentFreePlayer,
	addTournamentFreePlayerSuccess,
	removeTournamentFreePlayer,
	removeTournamentFreePlayerSuccess,
	setPlayerPoolVisible,
	saveTournamentPredictions,
	saveTournamentPredictionsSuccess,
	fetchTournamentPredictions,
	fetchTournamentPredictionsSuccess,
	tournamentChangeAction,
	setTournamentDefaultStore,
	fetchSnapshot,
	fetchSnapshotSuccess,
	saveMargin,
	saveMarginSuccess,
	/**
	 * Leagues actions
	 */
	createLeague,
	createLeagueSuccess,
	createLeagueFailed,
	clearLeagueCreate,
	getLeagues,
	getLeaguesSuccess,
	getLeaguesFailed,
	getLeaguesMore,
	clearLeagues,
	showLeague,
	showLeagueSuccess,
	showLeagueFailed,
	updateLeague,
	updateLeagueSuccess,
	updateLeagueFailed,
	leaveLeague,
	leaveLeagueSuccess,
	leaveLeagueFailed,
	getLeaguesForJoin,
	getLeaguesForJoinSuccess,
	getLeaguesForJoinMore,
	clearLeaguesForJoin,
	getLeaguesForJoinFailed,
	joinToLeague,
	joinToLeagueSuccess,
	joinToLeagueFailed,
	searchAndJoin,
	inviteViaEmail,
	inviteViaEmailSuccess,
	inviteViaEmailFailed,
	inviteViaEmailClear,
	getLeagueLadderData,
	getLeagueLadder,
	getLeagueLadderDataSuccess,
	getLeagueLadderDataFailed,
	getLeagueLadderDataMore,
	clearLeagueLadder,
	socialShare,

	/**
	 * Contact Us
	 */
	saveContactUs,
	saveContactUsSuccess,
	saveContactUsFailed
} = actions;

export default actions;
