import { IStoreState, NumberOrNone } from "../types";
import * as _                        from "lodash";
import { IDriverReducersState }      from '../reducers/drivers';
import { mergeSquadsWithDrivers, sortDriversByNumber }    from '../utils/drivers';

export const authStatus = (state: IStoreState): boolean => state.user.is_logged_in;
export const isRecovered = (state: IStoreState): boolean => !!state.user.recovered;
export const getUserId = (state: IStoreState): number => state.user.id;
export const getCurrentLeagueId = (state: IStoreState): number => state.league_manage.league.id;
export const getActualRoundID = (state: any): NumberOrNone =>
	_.chain(state.rounds)
		.findLast(_.conforms({status: (n:any) => _.eq(n, 'complete')}))
		.get('id', 0)
		.value();
export const getDrivers = ( state: IStoreState): IDriverReducersState => sortDriversByNumber(mergeSquadsWithDrivers(state.drivers, state.squads));
