import styled, { css } from 'styled-components';
import theme           from '../../assets/css/theme';
import { Link }        from 'react-router-dom';

interface IButtonInterface {
	readonly transparent?: boolean
	readonly disabled?: boolean
	readonly margin?: string
	readonly max_width?: string
}

const RegularButtonStyle = css`
	color: ${({ disabled }: IButtonInterface) => (disabled ? theme.buttons_color : theme.primary )};
	font-family: ${theme.base_bold_font}, sans-serif;
	font-size: 15px;
	letter-spacing: 1.5px;
	line-height: 35px;
	text-align: center;
	width: 100%;
	max-width: ${( { max_width }: IButtonInterface ) => (max_width || '100%')};
	margin: ${( { margin }: IButtonInterface ) => (margin || 'auto')};
	height: 50px;
	border: 1px solid ${theme.primary};
	background: ${( { transparent }: IButtonInterface ) => (transparent ? 'transparent' : theme.primary)};
	opacity: ${( { disabled }: IButtonInterface ) => (disabled ? '0.3' : '1')};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const RegularButton = styled.button`
  ${RegularButtonStyle}
`;

export const RegularButtonHref = styled.a`
	${RegularButtonStyle}
`;

export const RegularButtonLink = styled( Link )`
	${RegularButtonStyle}
`;

export default RegularButton;
