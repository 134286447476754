import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { ILeagueState } from './my_leagues';


export interface ILeagueManageReduser {
	[x: string]: any;
	readonly league: ILeagueState;
	readonly is_error: boolean
}

const defaultState: ILeagueManageReduser = {
	is_data_requested: false,
	league: {
		id: 0,
		user_id: 0,
		num_teams: 0,
		round_points: 0,
		start_round: 0,
		overall_rank: 0,
		overall_points: 0,
		start_at: '',
		name: '-',
		privacy: '-',
		status: '-',
		first_name: '-',
		last_name: '-',
		class: '-',
		code: '-',
	},
	is_error: false,
};

export const league_manage = createReducer<ILeagueManageReduser>({}, defaultState)
	.on(actions.showLeague, (state: ILeagueManageReduser) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.showLeagueSuccess, (state: ILeagueManageReduser, payload: ILeagueState) => ({
		...state,
		league: payload,
		is_data_requested: false,
		is_error: false,
	}))
	.on(actions.showLeagueFailed, (state: ILeagueManageReduser) => ({
		...state,
		is_error: true,
		is_data_requested: false,
	}))
	.on(actions.updateLeague, (state: ILeagueManageReduser) => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(actions.updateLeagueSuccess, (state: ILeagueManageReduser, payload: ILeagueState) => ({
		...state,
		league: payload,
		is_data_requested: false,
	}))
	.on(actions.updateLeagueFailed, (state: ILeagueManageReduser) => ({
		...state,
		is_data_requested: false,
	}));