import * as React from 'react';
import { SyntheticEvent } from 'react';
import { WithSubNav } from '../../components/Navigations';
import { HelpMenuItems as items_menu } from '../../components/Navigations/menuItems';
import { Help } from './Help';
import { IStoreState } from '../../modules/types';
import actions from '../../modules/actions';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { filter, partial } from 'lodash';
import { IFAQ } from '../../modules/reducers/help';
import { SidebarAdComponent } from '../../components/StaticAd';
import { PointsSystem } from '../../components/PointsSystem';

const FAQList = styled.ul`
	padding-left: 0 !important;
	margin: 0 !important;
`;
const FAQItem = styled.li`
	list-style-type: none;
	cursor: pointer;
	${({ isOpen }: IFAQItem) => {
	const open = isOpen && css`
			>div {
				background: #f5f5f5;
			}
			ul {
				display: block;
			}
		`;
	return [open];
}}
`;
const FAQCategory = styled.div`
	font-family: TheSun-Medium, sans-serif;
	font-size: 1.5em;
	color: #383d43;
    padding: .5em 1em;
    border-bottom: 1px solid #ccc;
	 &:hover {
    	background: #f5f5f5;
    }
	span {
		width: 25px;
    	display: inline-block;
	}
`;
const Questions = styled.ul`
	display: none;
	padding-left: 0 !important;
	margin: 0 !important;
`;
const QuestItem = styled.li`
	list-style-type: none;
	cursor: pointer;
	${({ isOpen }: IFAQItem) => {
	const open = isOpen && css`
			>div {
				display: block;
			}
		`;
	return [open];
}}
`;
const Question = styled(FAQCategory)`
	font-size: 1em;
 	padding: .5em 3em;
`;
const Answer = styled.div`
	display: none;
	box-shadow: 0 2px 10px -3px #ccc inset;
	padding: 1em 4em;
    border-bottom: 1px solid #ccc;
    color: #767676;
`;

interface IFAQItem {
	isOpen: boolean
}

interface IProps {
	faqs: IFAQ[];
	fetchFAQS: () => void;
}

interface IState {
	[key: string]: number[]
}

class HelpFAQClass extends React.Component<IProps, IState> {
	public state: any = {
		open_faqs: [],
		open_answers: []
	};

	get faq() {
		const { faqs } = this.props;
		return (
			<FAQList>
				{
					faqs.map((faq: any, key: number) => {
						const isOpen = this.state.open_faqs.indexOf(faq.id) > -1;
						return (
							<FAQItem
								key={`faq-key-${key}`}
								onClick={partial(this.toggleFAQ, faq.id, 'open_faqs') as any}
								isOpen={isOpen}
							>
								<FAQCategory>{!isOpen ? (<span>+</span>) : (<span>-</span>)}
									{faq.name}</FAQCategory>
								{this.faqQuestions(faq.items)}
							</FAQItem>
						)
					})
				}
			</FAQList>
		);
	}

	/**
	 * @ignore
	 */
	public componentWillMount() {
		this.props.fetchFAQS();
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<WithSubNav backLink="/" backTitle='HELP' items_menu={items_menu}>
				<Help>
					<section>
						<div className='help-title'>
							FAQS
						</div>
						{this.faq}
					</section>
					<aside>
						<SidebarAdComponent/>
						<PointsSystem key='points'/>
					</aside>
				</Help>

			</WithSubNav>

		);
	}

	/**
	 * Stop propagation on answer click
	 */
	private stopAnswerPropagation(e: SyntheticEvent) {
		e.stopPropagation();
	}

	/**
	 * Add/remove item for open/close status
	 */
	private readonly toggleFAQ = (id: number, state_field: string, e: SyntheticEvent) => {
		e.stopPropagation();

		let faqs: number[] = this.state[state_field];

		if (faqs.indexOf(id) > -1) {
			faqs = filter(faqs, value => value !== id);
		} else {
			faqs.push(id);
		}

		this.setState({
			[state_field]: faqs
		})
	};

	/**
	 * Get questions and answers
	 */
	private faqQuestions(questions: any[]) {
		return (
			<Questions>
				{
					questions.map((question: any, key: number) => {
						const isOpen = this.state.open_answers.indexOf(question.id) > -1;
						return (
							<QuestItem
								key={`question-key-${key}`}
								onClick={partial(this.toggleFAQ, question.id, 'open_answers') as any}
								isOpen={isOpen}
							>
								<Question>{!isOpen ? (<span>+</span>) : (<span>-</span>)}{question.title}</Question>
								<Answer
									onClick={this.stopAnswerPropagation}
									dangerouslySetInnerHTML={{ __html: question.text }}/>
							</QuestItem>
						)
					})
				}
			</Questions>
		);
	}
}


const mapStateToProps = (state: IStoreState) => ({
	faqs: state.help.faqs
});

const mapDispatchToProps = {
	fetchFAQS: actions.fetchFAQS
};

export const HelpFAQ = connect(mapStateToProps, mapDispatchToProps)(HelpFAQClass);

export default HelpFAQ;
