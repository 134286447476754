import styled from 'styled-components';
import { below } from '../../assets/css/media';

export const InviteContent = styled.div`
	position: relative;
	max-width: 300px;
	margin: 0 auto;
	overflow: hidden;
	${below.desktop`
		max-width: 80%;
	`}
`;

export default InviteContent