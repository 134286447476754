import * as React                  from 'react';
// import * as moment                 from 'moment';
import * as _                      from 'lodash';
import { Redirect, RouterProps }   from 'react-router';
import { connect }                 from 'react-redux';
import { IFormProps, IStoreState } from '../../modules/types';
import * as actions                from '../../modules/actions';

import {
	ContentWithSidebar, FetchJsons,
	Field,
	FormCreator,
	FormGroup,
	getLeaguesPath,
	LeagueFormWrapper,
	LoadingSpinner,
	OptionsStyled,
	PageTitle, PointsSystem,
	SecondaryButtonLeftSide,
	SelectStyled,
	SidebarAdComponent,
	WithMainNav,
} from '../../components';
import { ILeagueCreateReduser, IRound, IRoundsReducerState } from '../../modules/reducers';

interface ILeagueCreateState {
	readonly name: string;
	readonly privacy: string;
	readonly start_round: number;
}

interface IDispatchProps {
	readonly create: (league: ILeagueCreateState) => void;
	readonly clear_league: () => void;
}

interface IMapState {
	readonly first_name: string,
	readonly league: ILeagueCreateReduser,
	readonly rounds: IRoundsReducerState,
}

interface IMergedProps {
	readonly start_round: number,
}

interface ISetState {
	readonly [name: string]: string | number;
}

type Props = IDispatchProps & IMapState & IMergedProps;
type State = ILeagueCreateState & ISetState;

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>])

class LeagueCreateComponent extends React.Component<Props, State> {

	/**
	 * Pre fill state for show some fields
	 */
	public static getDerivedStateFromProps(props: Props, state: State) {

		if(props.rounds.length && !state.start_round){
			return {
				name: `${props.first_name}'s League`,
				start_round: _.get(_.first(props.rounds), 'id', state.start_round),
			}
		}

		return null;
	}

	public state = {
	    name       : '',
	    privacy    : 'public',
	    start_round: 0,
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			'handleSubmitForm',
            'renderedFields'
		])
	}

	/**
	 * Clear state of create league
	 */
	public componentWillUnmount(){
		const { clear_league } = this.props;

		clear_league();
	}

	/**
	 * Method to process registration form submission
	 */
	public handleSubmitForm (values: any) {
		const { create } = this.props;

		create(values);
	};

	private get roundsOptions(){
		const { rounds } = this.props;

		return rounds.map((round: IRound, i:number) => (
			<option key={i} value={round.id}>
				{`Round ${round.id}`}
				</option>
		))
	}

  /**
   * @ignore
   */
	public renderedFields(props: IFormProps, values: State) {
    const MAX_LEAGUE_NAME = 40;
    let TAB_INDEX = 0;
    const handleChange = props.handleChange;
    return (
	    <>
        <FormGroup
          title="What's your leagues name"
          tooltip="Max 40 characters. Inappropriate league names will be deleted and replaced with your team name."
        >
          <Field
            type="text"
            id='name'
            name='name'
            placeholder="Enter a name for your league*"
            title="Max 40 characters. Inappropriate league names will be deleted and replaced with your team name."
            defaultValue={values.name}
            pattern="{3,40}$"
            tabIndex={++TAB_INDEX}
            maxLength={MAX_LEAGUE_NAME}
            onChange={handleChange}
            required={true}
          />
        </FormGroup>

        <FormGroup
          title="What's your leagues privacy?"
          tooltip="Private leagues can only be joined with a
					 league PIN provided by the Chairman.
					 Public leagues are visible to everyone and
					  will not require a PIN or invite"
        >
          <OptionsStyled
            type="radio"
            name="privacy"
            tabIndex={TAB_INDEX}
            items={['public', 'private']}
            defaultValue={values.privacy}
            onChange={handleChange}
            required={true}
          />
        </FormGroup>

        <FormGroup
          title="When would you like your league to start?"
          tooltip="This is when the League will close for
					 entry and start scoring. You can select any future
					 round as your preferred start round."
        >
          <SelectStyled
            name="start_round"
            tabIndex={++TAB_INDEX}
            onChange={handleChange}
            required={true}
            value={`${values.start_round}`}
          >
            {this.roundsOptions}
          </SelectStyled>
        </FormGroup>

        <SecondaryButtonLeftSide type='submit' disabled={_.isEmpty(values.name)} margin={`0 0 12px`}>
          create my league
        </SecondaryButtonLeftSide>
      </>
    )
  }

	private get formCreateLeague() {

		return (
			<FormCreator
        as={LeagueFormWrapper}
        onSubmit={this.handleSubmitForm}
        render={this.renderedFields}
        initialState={{
	        ...this.state,
        }}
      />)
	}
	/**
	 * @ignore
	 */
	public render() {
		const { league } = this.props;
		return league.league && league.league.id ? (
			<Redirect to={getLeaguesPath('invite', league.league.id)}/>
		) : (
			<WithMainNav>
				<FetchJsons/>
				<ContentWithSidebar
					sidebar_components={[Ads]}
				>
					<PageTitle>
						Create A League
					</PageTitle>
					{league.is_data_requested && !this.state.start_round &&  <LoadingSpinner with_cover_bg={true} position="center"/>}
					{this.formCreateLeague}
				</ContentWithSidebar>
			</WithMainNav>
		)
	}
}

const mapStateToProps = (state: IStoreState): IMapState => ({
	first_name: _.get(state, 'user.first_name', ''),
	league: state.league_create,
	rounds: state.rounds,
});

const mapDispatchToProps: IDispatchProps = {
	clear_league: actions.clearLeagueCreate,
	create: actions.createLeague,
};

const mergeProps = (stateProps: IMapState, dispatchProps: IDispatchProps, ownProps: RouterProps) => {
	const { rounds } = stateProps;
	const predicate_rounds = _.partial( _.filter, rounds);
	const available_rounds = predicate_rounds((r: IRound) => r.status === 'scheduled') ||
		predicate_rounds((r: IRound) => r.status === 'completed');

	return {
		...stateProps,
		...ownProps,
		...dispatchProps,
		start_round: _.get(_.first(available_rounds), 'id'),
		rounds: available_rounds,
	};
};

export const LeagueCreate = connect(mapStateToProps, mapDispatchToProps, mergeProps)(LeagueCreateComponent);