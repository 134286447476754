import { findIndex, partial, find, filter}   from 'lodash';
import * as React                     from 'react';
import styled, { css }                from 'styled-components';
import theme                          from '../../assets/css/theme';
import { IGroup, IGroupReducerState } from '../../modules/reducers/groups';
import arrow                          from '../../assets/img/icons/arrow-down.png';
import * as moment                    from 'moment';
import { below }                      from '../../assets/css/media';
import { Icon }                       from '../Icons';

const GroupSwitcherContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	background: ${theme.secondary};
`;

const GroupName = styled.div`
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-family: ${theme.base_bold_font};
	width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
`;

const ArrowWrapper = styled.div`
	display: flex;
	width: 60px;
	height: 60px;
	cursor: pointer;
	background: #fff;
	align-items: center;
	justify-content: center;
	img {
		width: 22px;
		transform: rotate(-90deg);
	}
	${({isLeft}: IArrowWrapper) => (
		isLeft && css`
				img {
					transform: rotate(90deg);
				}
			`
	)}
`;

interface IArrowWrapper {
	isLeft?: boolean
}

const GroupBlockWrapper = styled.div`
	display: flex;
	padding: 0 1em;
	justify-content: center;
`;

const Block = styled.div`
	flex: 1;
	max-width: 42px;
	height: 2px;
	background: ${( { isActive }: IIsActive ) => (isActive ? theme.primary : '#fff')};
	margin: 0 2px;
	cursor: pointer;
`;

const TopBlock = styled.div`
	margin-bottom: 3px;
	text-align: center;
	line-height: 1.5;
	${below.desktop`
		margin-bottom: 9px;
	`}
`;

const Name = styled.div`
	font-size: 18px;
	margin-right: 10px;
	${below.desktop`
		font-size: 14px;
	`}
`;

const RaceNumber = styled.div`
	font-size: 16px;
	${below.desktop`
		font-size: 12px;
	`}
`;

const DropdownIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Dropdown = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: ${theme.secondary};
	z-index: 3;
	max-height: 50vh;
	overflow: auto;
`;

const DropdownItem = styled.div`
	padding: 10px;
	border-bottom: 1px solid #fff;
`;

interface IIsActive {
	isActive: boolean
}

interface IProps extends IGroupReducerState {
	callback: ( id: number ) => void;
	selected_group_id: number;
	ref: any;
}

export class GroupSwitcher extends React.Component<IProps, any> {
	public state = {
		dropDownOpen: false
	};

	get blocks() {
		const { groups } = this.props;

		return groups.length ? groups.map( group => (
			<Block
				isActive={this.isActiveBlock( group )}
				key={group.id}
				onClick={partial( this.selectGroupById, group.id )}
			/>
		) ) : '';
	}

	get groupName() {
		const group = this.selectedGroup;
		return group ? group.name : '';
	}

	get selectedGroup() {
		const { groups, selected_group_id } = this.props;
		return find( groups, { id: selected_group_id } );
	}

	get raceTitle() {
		const group = this.selectedGroup;
		const title = group ? group.number : '';
		return `Race ${title} lockout @`;
	}

	get raceDate() {
		const group = this.selectedGroup;
		return group ? moment(group.date_start).format('hA - DD/MM/YYYY') : '';
	}

	get dropdownItems() {
		const { groups, selected_group_id } = this.props;
		const filtered_groups = filter(groups, group => group.id !== selected_group_id);
		return filtered_groups.map(group => (
			<DropdownItem key={`group-${group.id}`} onClick={partial(this.changeSelectedGroup, group.id)}>
				{group.name}
			</DropdownItem>
		));
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<div>
				<GroupSwitcherContainer>
					<ArrowWrapper onClick={partial( this.setSelectedGroup, 'left' )}
					              isLeft={true}
					>
						<img src={arrow} alt=""/>
					</ArrowWrapper>
					<GroupName onClick={this.toggleDropdown}>
						<TopBlock>
							<DropdownIconWrapper>
								<Name>
									{this.groupName}
								</Name>
								<Icon name={!this.state.dropDownOpen ? 'chevron-down' : 'chevron-up'}/>
							</DropdownIconWrapper>
							<RaceNumber>
								{this.raceTitle} {this.raceDate}
							</RaceNumber>
						</TopBlock>
						<GroupBlockWrapper>
							{this.blocks}
						</GroupBlockWrapper>
						{
							this.state.dropDownOpen ? <Dropdown>{this.dropdownItems}</Dropdown> : null
						}
					</GroupName>
					<ArrowWrapper onClick={partial( this.setSelectedGroup, 'right' )}>
						<img src={arrow} alt=""/>
					</ArrowWrapper>
				</GroupSwitcherContainer>
			</div>
		)
	}

	/*
	* Check if we can highlight block.
	* We can if on first load by actual round or on group change by group index.
	* */
	private isActiveBlock( group: IGroup ) {
		const { selected_group_id } = this.props;
		return selected_group_id === group.id;
	}

	/*
	*  Set selected block by arrow click
	* */
	private readonly setSelectedGroup = ( direction: string ) => {
		const { selected_group_id, groups } = this.props;
		const selected_index = findIndex( groups, [ 'id', selected_group_id ] );

		let iterate_direction = direction === 'right' ? selected_index + 1 : selected_index - 1;

		if ( iterate_direction < 0 ) {
			iterate_direction = groups.length - 1;
		}
		else if ( iterate_direction >= groups.length ) {
			iterate_direction = 0;
		}

		this.props.callback( groups[ iterate_direction ].id )
	};
	/**
	 * Change selected group id from dropdown
	 * @param group_id number
	 */
	private readonly changeSelectedGroup = (group_id: number) => {
		this.props.callback( group_id );
		this.toggleDropdown();
	};
	/*
	* Select group by group id
	* */
	private readonly selectGroupById = ( id: number ) => {
		this.props.callback( id );
	};
	/**
	 * Toggle dropdown
	 */
	private readonly toggleDropdown = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen});
	}
}

export default GroupSwitcher;
