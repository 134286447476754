import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { ICollectionState, IRequestData } from '../../types';

export interface ILadderItem {
	is_own?: boolean
	readonly overall_rank: number
	readonly round: number
	readonly overall_points: number
	readonly round_rank: number
	readonly round_points: number
	readonly first_name: string
	readonly last_name: string
	readonly team_name: string
	readonly start_round: number
	readonly start_at: string
	readonly user_id: number
	readonly overall_margin: number
}

export type Ladder = ICollectionState<ILadderItem> & IRequestData;

const defaultState: Ladder = {
	items: [],
	next: false,
	is_data_requested: false,
};

export const league_ladder = createReducer<Ladder>({}, defaultState)
	.on(actions.getLeagueLadderData, (state: Ladder) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.clearLeagueLadder, () => ({
		...defaultState,
		is_data_requested: false,
	}))
	.on(actions.getLeagueLadderDataSuccess, (state: Ladder, payload: Ladder) => ({
		...state,
		items: [
			...state.items,
			...payload.items
		],
		next: payload.next,
		is_data_requested: false,
	}))
	.on(actions.getLeagueLadderDataFailed, (state: Ladder) => ({
		...state,
		is_data_requested: false,
	}));