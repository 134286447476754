import * as React from 'react';
import { Redirect } from "react-router";
import * as selectors from '../modules/selectors'

import { connect } from 'react-redux';
import { MastHead } from '../components';
import { isOffSeason } from "../modules/utils/dom_helpers";
import { RegistrationFormSecond } from './Registration/RegistrationFormSecond';

import { IAuthState, IStoreState }                      from '../modules/types';
import { GameTagLine, LandingWrapper } from './Landing';

/**
 * Registration page component
 */
class RegistrationPageComponent extends React.Component<IAuthState> {
	/**
	 * @ignore
	 */
	public render() {
		if(isOffSeason) {
			return  <Redirect to='/'/>
		}
		return (
			<LandingWrapper>
				<MastHead>
					<GameTagLine>
						Welcome to the official tipping competition of the Repco Supercars Championship
					</GameTagLine>
				</MastHead>
				<div>
					<RegistrationFormSecond />
				</div>
			</LandingWrapper>
		)
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.authStatus(state)
});

export const RegistrationSecond = connect(mapStateToProps, null)(RegistrationPageComponent);

export default RegistrationSecond;
