import * as _                                              from 'lodash';
import { SagaIterator }                                    from 'redux-saga';
import { call, put, select, take }                         from 'redux-saga/effects';
import { ISagaAction }                                     from '../../types';
import { ILadderItem, ILeagueState }               from '../../reducers/leagues';
import { getCurrentLeagueId, getUserId } from '../../selectors';
import ApiErrors                                           from '../../utils/Api/ApiErrors';

export interface IActionSearch {
	value?: string,
}

const setCommissionerLeague = (league: ILeagueState, user_id: number): ILeagueState => {
	league.is_commissioner = league.user_id === user_id;
	return league;
};

const setOwnUser = (ladder: ILadderItem, user_id: number): ILadderItem => {
	ladder.is_own = ladder.user_id === user_id;
	return ladder;
};

export const postJoinToLeagueSaga = function* (action: ISagaAction<string>): SagaIterator {
	const code: string = action.payload;
	try {
		const { errors } = yield call(action.meta.api.Joins.join, {
			code,
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put(action.meta.actions.joinToLeagueSuccess(code));
	} catch (e) {
		yield put(action.meta.actions.joinToLeagueFailed(code));
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}
};

export const postLeagueLeaveSaga = function* (action: ISagaAction<number>): SagaIterator {

	try {
		const { errors } = yield call(action.meta.api.Joins.leave, {
			league_id: action.payload
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}
		yield put(action.meta.actions.leaveLeagueSuccess());

	} catch (e) {
		yield put(action.meta.actions.leaveLeagueFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}

};

export const postLeagueCreateSaga = function* (action: ISagaAction<ILeagueState>): SagaIterator {
	try {
		const { result, errors } = yield call(action.meta.api.Leagues.create, action.payload);

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put(action.meta.actions.createLeagueSuccess(result));

	} catch (e) {
		yield put(action.meta.actions.createLeagueFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}
};

export const postLeagueUpdateSaga = function* (action: ISagaAction<ILeagueState>): SagaIterator {
	try {
		const { result, errors } = yield call(action.meta.api.Leagues.update, action.payload);

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}
		const user_id = yield select(getUserId);
		yield put(action.meta.actions.updateLeagueSuccess(setCommissionerLeague(result, user_id)));

	} catch (e) {
		yield put(action.meta.actions.updateLeagueFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}
};

export const getMyListLeaguesSaga = function* (action: ISagaAction<null>, offset: number = 0): SagaIterator {
	try {
		const LIMIT = 15;
		const { result, errors } = yield call(action.meta.api.Leagues.my_list, {
			offset,
			limit: LIMIT,

		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}
		const user_id = yield select(getUserId);
		yield put(action.meta.actions.getLeaguesSuccess({
			items: result.leagues.map(_.partial(setCommissionerLeague, _, user_id)),
			next : result.next
		}));

		if(result.next){
			yield take(action.meta.actions.getLeaguesMore);
			yield call(getMyListLeaguesSaga, action, offset + LIMIT);
		}

	} catch (e) {
		yield put(action.meta.actions.getLeaguesFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}
};

export const getLeagueByIdSaga = function* (action: ISagaAction<number>): SagaIterator {

	try {
		const { result, errors } = yield call(action.meta.api.Leagues.show, {
			id: action.payload
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}
		const user_id = yield select(getUserId);
		yield put(action.meta.actions.showLeagueSuccess(setCommissionerLeague(result, user_id)));

	} catch (e) {
		yield put(action.meta.actions.showLeagueFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}

};

interface ILoadMore {
	readonly offset: number | undefined,
	readonly query: string | undefined,
}

export const getJoinListLeaguesSaga = function* (action: ISagaAction<IActionSearch & ILoadMore>): SagaIterator {

	try {
		const LIMIT = 10;
		const ZERO_OFFSET = 0;
		const query = _.get(action, 'payload.value');
		const load_more_query = _.get(action, 'query', '');
		const load_more_offset = _.get(action, 'offset', ZERO_OFFSET);

		const { result, errors } = yield call(action.meta.api.Joins.show_for_join, {
			offset: load_more_offset,
			q: query,
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put(action.meta.actions.getLeaguesForJoinSuccess({
			items: _.get(result, 'leagues', []),
			next : _.get(result, 'next', false),
		}));

		const new_offset = query === load_more_query || !query ? load_more_offset + LIMIT : ZERO_OFFSET;

		_.assign(action, {
			offset: new_offset || LIMIT,
			query,
		});

		if(result.next){
			yield take(action.meta.actions.getLeaguesForJoinMore);
			yield call(getJoinListLeaguesSaga, action);
		}

	} catch (e) {
		yield put(action.meta.actions.getLeaguesForJoinFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}
};



export const searchAndJoinSaga = function* (action: ISagaAction<string>): SagaIterator {

	try {
		const query = _.get(action, 'payload');
		const { result, errors } = yield call(action.meta.api.Joins.show_for_join, {
			q: query,
		});

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put(action.meta.actions.getLeaguesForJoinSuccess({
			items: _.get(result, 'leagues', []),
			next : _.get(result, 'next', false),
		}));

		yield call(postJoinToLeagueSaga, action)

	} catch (e) {
		yield put(action.meta.actions.getLeaguesForJoinFailed());
		yield put(action.meta.actions.errorsGlobalError({ code: 0, text: e }));
	}
};

export const getLadderDataSaga = function* (action: ISagaAction<any>, offset: number = 0): SagaIterator {

	const league_id = yield select(getCurrentLeagueId);
	const action_league_id =  +_.get(action, 'payload.id');
	const is_mass_league =  +_.get(action, 'payload.is_mass_league');

	if(!_.eq(league_id, action_league_id) && !is_mass_league){
		yield call(getLeagueByIdSaga, {...action, payload: action_league_id });
	}

	yield call(getLeagueLadderDataSaga, action);
};

export const getLeagueLadderDataSaga = function* (action: ISagaAction<any>, offset: number = 0): SagaIterator {
	try {
		const LIMIT = 20;

		const { rounds } = yield select();
		if(_.isEmpty(rounds)) {
			yield take(action.meta.actions.fetchRoundsJSONSuccess);
		}

		const { id, round, enduro, is_rankings } = action.payload;
		const options = {
			offset,
			limit: LIMIT,
			id,
			enduro
		};

		if(round) {
			_.extend(options, { round } );
		}
		const method = is_rankings ? action.meta.api.Rankings.rankings : action.meta.api.Leagues.ladder;

		const { result, errors } = yield call(method, options );

		console.log(result);

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		const user_id = yield select(getUserId);
		yield put(action.meta.actions.getLeagueLadderDataSuccess({
			items: result.ladder.map(_.partial(setOwnUser, _, user_id)),
			next : result.next
		}));

		if(result.next){
			yield take(action.meta.actions.getLeagueLadderDataMore);
			yield call(getLeagueLadderDataSaga, action, offset + LIMIT);
		}

	} catch (e) {
		yield put(action.meta.actions.getLeagueLadderDataFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}
};
