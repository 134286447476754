import * as React from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import fanHubLogo from '../../assets/img/fanhub-logo.svg';

const FooterWrapper = styled.div`
	background: ${theme.fh_footer_bg};
	width: 100%;
	color: #fff;
	padding: 2em 0 1em;
`;

const FanHubFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em 7em;
	font-size: 10px;
`;

const Separator = styled.div`
	height: 2px;
	width: 97%;
	background: #fff;
	opacity: 0.2;
	margin: auto;
`;

const CopyRight = styled.div``;

const PoweredBy = styled.div`
	display: flex;
	align-items: center;
	div {
		margin-right: 1em;
		white-space: nowrap;
	}
`;

export const Footer = () => (
	<React.Fragment>
		<FooterWrapper>
			<Separator/>
			<FanHubFooter>
				<CopyRight>
					Copyright © V8 Supercars Australia Pty Ltd
				</CopyRight>
				<PoweredBy>
					<div>Powered by</div>
					<a href="http://fanhubmedia.com/" rel="noopener noreferrer" target="_blank">
						<img src={fanHubLogo} alt="Fanhub"/>
					</a>
				</PoweredBy>
			</FanHubFooter>
		</FooterWrapper>
	</React.Fragment>
);

export default Footer;
