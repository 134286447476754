import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import {
	Redirect,
	RouteComponentProps
} from 'react-router';

import {
	ContentWithSidebar,
	MastHead,
	PageTitle,
	RecoverPassword
} from '../components';
import * as actions from '../modules/actions';
import { IRecoverPassReducer } from '../modules/reducers/user';
import { IRecoverRequest } from '../modules/sagas/user';
import {
	ISendRequest,
	IStoreState
} from '../modules/types';
import ApiErrors from '../modules/utils/Api/ApiErrors';
import { isOffSeason } from "../modules/utils/dom_helpers";


type DispatchToProps = ISendRequest<IRecoverRequest>

interface IToken {
	readonly token: string;
}

interface IMapToProps {
	readonly user_recover_password: IRecoverPassReducer
}

type Props = DispatchToProps & IMapToProps & RouteComponentProps<IToken>

class RecoverPasswordFormComponent extends React.Component<Props> {

	constructor(props: Props) {
		super(props);
		_.bindAll(this, [
			'handleSubmitForm'
		]);
	}

	/**
	 * Method to process restore password form submission
	 */
	public handleSubmitForm(password: string) {
		const { match: { params } } = this.props;
		const { send } = this.props;

		send({
			token: params.token,
			password
		});
		console.log('send');
	};

	/**
	 * @ignore
	 */
	public render() {

		const { match: { params }, user_recover_password: { error_code } } = this.props;

		if ( !params.token ) {
			return <Redirect to="/"/>;
		}

		if ( error_code === ApiErrors.USER_EXPIRED_TOKEN ) {
			return <Redirect to="/forgot-password"/>;
		}
		if ( isOffSeason ) {
			return <Redirect to='/'/>;
		}

		return (
			<>
				<MastHead/>
				<ContentWithSidebar empty_sidebar={true}>
					<PageTitle>Change your password</PageTitle>
					<RecoverPassword onSubmit={this.handleSubmitForm}/>
				</ContentWithSidebar>
			</>
		);
	}
}

const mapStateToProps = (state: IStoreState): IMapToProps => ( {
	user_recover_password: state.user_recover_password
} );

const mapDispatchToProps: DispatchToProps = {
	send: actions.userRecoverPassw
};

export const RecoverPasswordForm = connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordFormComponent);

export default RecoverPasswordForm;
