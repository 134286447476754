import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface IGuideline {
	title: string;
	content: string;
}

export interface ITerms {
	id: number;
	tags: string;
	text: string;
	title: string;
}

export interface IFAQQestion {
	category_id: number;
	id: number;
	tags: string;
	text: string;
	title: string;
	type: string;
}

export interface IFAQ {
	id: number;
	name: string;
	parent_id: number;
	type: string;
	items: IFAQQestion[]
}

export interface IPrizes {
	id: number;
	tags: string;
	text: string;
	title: string;
}

export interface IHelpReducer {
	guidelines: IGuideline[];
	terms: ITerms[];
	faqs: IFAQ[];
	prizes: IPrizes[]
}

const initialState: IHelpReducer = {
	guidelines: [],
	terms: [],
	faqs: [],
	prizes: [],
};

export const help = createReducer<IHelpReducer>( {}, initialState );

help.on( actions.fetchGuidelinesSuccess, ( state: IHelpReducer, payload: IHelpReducer ) => ({
	...state,
	...payload
}));
help.on( actions.fetchTermsSuccess, ( state: IHelpReducer, payload: IHelpReducer ) => ({
	...state,
	...payload
}) );
help.on( actions.fetchFAQSSuccess, ( state: IHelpReducer, payload: IHelpReducer ) => ({
	...state,
	...payload
}) );
help.on( actions.fetchPrizesSuccess, ( state: IHelpReducer, payload: IHelpReducer ) => ({
	...state,
	...payload
}) );