import * as React                                       from 'react';
import { PointsSystem, SidebarAdComponent, WithSubNav } from '../../components';
import { HelpMenuItems as items_menu }                  from '../../components/Navigations/menuItems';
import { connect }                                      from 'react-redux';
import actions                                          from '../../modules/actions';
import { IStoreState }                                  from '../../modules/types';
import { ITerms }                                       from '../../modules/reducers/help';
import { Help }                                         from './';

class HelpTermsClass extends React.Component<any, any> {
	get terms() {
		const { terms } = this.props;
		return terms.map( ( term: ITerms, key: number ) => (
			<div className='help-item' key={`term-${key}`}>
				<div className='title'>{term.title}</div>
				<div className='content' dangerouslySetInnerHTML={{ __html: term.text }}/>
			</div>
		) )
	}

	/**
	 * @ignore
	 */
	public componentWillMount() {
		this.props.fetchTerms();
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<WithSubNav backLink="/" backTitle='HELP' items_menu={items_menu}>
				<Help>
					<section>
						<div className='help-title'>
							Terms & Conditions
						</div>
						{this.terms}
					</section>
					<aside>
						<SidebarAdComponent/>
						<PointsSystem key='points'/>
					</aside>
				</Help>
			</WithSubNav>

		);
	}
}


const mapStateToProps = ( state: IStoreState ) => ({
	terms: state.help.terms
});

const mapDispatchToProps = {
	fetchTerms: actions.fetchTerms
};

export const HelpTerms = connect( mapStateToProps, mapDispatchToProps )( HelpTermsClass );

export default HelpTerms;