/**
 * Set fixed state to body (overflow: hidden;)
 */
export const manageBodyClass = (state: boolean): void => {
	if (state) {
		window.document.body.classList.add('fixed-scroll');
	} else {
		window.document.body.classList.remove('fixed-scroll');
	}
};
/*
* Check is mobile
* */
export const isMobile = () => (
	typeof window.matchMedia !== 'undefined'
		? window.matchMedia('(max-width: 971px)').matches
		: false
);

export const delay = (ms: number): any => new Promise((res: any) => setTimeout(res, ms));


export const getCookie = (name: string) => {
	const matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)" // eslint-disable-line
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const numberPostfix = (gen_number: number) => {
	const post_fixes = ['st','nd', 'rd'];
	const post_fix = post_fixes[gen_number] || '';
	return post_fix;
};

export const isOffSeason = process.env.REACT_APP_OFF_SEASON === 'true';
