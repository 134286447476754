import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { NavLink } from 'react-router-dom';

export const NavItem = styled(NavLink)`
	font-family: ${theme.base_bold_font};
	color: ${theme.base_text_color};
	font-size: 0.9375em;
	padding: 1em 0.75em;
	text-transform: uppercase;
	text-align: center;
	width: 100%;
	& > a {
	  display: none;
	}
	&.active {
		background: ${theme.primary};
		color: #FFFFFF;
		 & > a {
			display:flex;
			
			svg {
				fill: #FFFFFF;
			}
		}
	}
`;