import { isString, isArray, identity } from "lodash";
import { createAction } from "redux-act";

/**
 * Store default actions and manage meta data passed to all actions by default.
 */
export class ActionsCreator {
  constructor() {
    throw new Error("This class can't be instantiated");
  }

  /**
   * Map of default actions.
   * @return {Object} defaultActions - Default actions described
   * in the nex way: `actionName: "Action description"`
   */
  static get defaultActions() {
    return {};
  }

  /**
   * ActionsCreator Constructor.
   * @param {Object|undefined} meta - Meta data that would be passed to sagas,
   * once bundled action will be called.
   */
  static createWith(meta) {
    const actions = {};
    const defaultActions = this.defaultActions;
    const defaultMetaReducer = () => ({ ...meta, actions });

    for (let key in defaultActions) {
      if (!defaultActions.hasOwnProperty(key)) {
        continue;
      }

      const value = defaultActions[key];

      if (isString(value)) {
        actions[key] = createAction(value, identity, defaultMetaReducer);
        continue;
      }

      if (isArray(value)) {
        const [
          description = "",
          payloadReducer = identity,
          metaReducer = defaultMetaReducer
        ] = value;

        actions[key] = createAction(description, payloadReducer, metaReducer);
        continue;
      }

      throw new Error(
        "Action value should be a string" +
          " or an array with [description]," +
          " [payloadReducer], [metaReducer]"
      );
    }

    return actions;
  }

  /**
   * Mixing defaultActions field with provided new one actions.
   * @param {Object} actionsObject
   * @return {ActionsCreator} - Clone of ActionsCreator with
   * extended defaultActions static field.
   * @example
   * ActionsCreator.mixin({
   *      // Just description
   *      someActionName: 'Some Action Description',
   *
   *      // Just description passed as array
   *      someSecondActionName: ['Some Second Action Description'],
   *
   *      // Description and payloadReducer
   *      someThirdActionName: [
   *          'Some Third Action Description',
   *          payload => ({ ...payload, additionalData: 123 })
   *      ],
   *
   *      // Description, payloadReducer and metaReducer
   *      someForthActionName: [
   *          'Some Forth Action Description',
   *          payload => ({ ...payload, additionalData: 123 }),
   *          () => ({ thisIsMyOwnMetaData: true }),
   *      ],
   *
   *      // Description and metaReducer, payloadReducer is missed and will be set default
   *      someFifthActionName: [
   *          'Some Fifth Action Description',
   *          undefined,
   *          () => ({ thisIsMyOwnMetaData: true }),
   *      ]
   *  }).mixin({
   *      testAction: 'Test action'
   *  })
   */
  static mixin(actionsObject) {
    const defaultActions = {
      ...this.defaultActions,
      ...actionsObject
    };

    return class NewActionsCreator extends this {
      static get defaultActions() {
        return defaultActions;
      }
    };
  }
}

export default ActionsCreator;
