import styled from 'styled-components';
import { NavItem } from './NavItem';
import { below } from '../../assets/css/media';

export const SiteMapMenuWrapper = styled.div`
	display: flex;
	max-width: 70%;
	flex-basis: 70%;
	justify-content: flex-start;
	${NavItem}{
		flex: 1 1 100%;
		white-space: nowrap;
		
	}
	${below.desktop`
		max-width: 100%;
		flex-basis: 100%;
		${NavItem}{
			max-width: 100%;
			justify-content: flex-start;
		}
	`}
`;

export default SiteMapMenuWrapper;