import * as React              from 'react';
import styled                  from 'styled-components';
import theme                   from '../../assets/css/theme';
import { LeagueStandingsLink } from '../Navigations';
import { ILeagueState }        from '../../modules/reducers/leagues';

const Chairman = styled.div`
	color: ${theme.base_text_color};
	font-family: ${theme.base_font};
	font-size: 16px;
`;

const LeagueInfoWrapper = styled.div`
	background: #fff;
	display: flex;
	justify-content: space-between;align-items: center;
	padding: 0.75em;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
	position: relative;
`;

const LeagueTextName = styled.span`
	font-size: 20px;
`;

const LeagueName = styled.div`
	max-width: 100%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	width: 100%;
	margin-left: 40px;
	${LeagueTextName}{
		white-space: nowrap;
		font-family: ${theme.base_bold_font};
	}
`;

const Line = styled.div`
	background: ${theme.primary};
	position: absolute;
	height: 100%;
	width: 20px;
	top: 0;
	left: 20px;
	transform: skew(-20deg, 0deg);
`;

interface ILeagueInfo {
	readonly league: ILeagueState,
	readonly is_name_link?: boolean
	readonly children?: React.ReactNode
}

export const LeagueInfo = ( { league, is_name_link, children }: ILeagueInfo ) => {
	return (
		<LeagueInfoWrapper>
			<Line />
			<LeagueName>
				<LeagueTextName
					as={is_name_link ? LeagueStandingsLink(league.id) : 'span'}
				>
					{league.name}
					</LeagueTextName>
				<Chairman>{`Chairman: ${league.first_name} ${league.last_name}`}</Chairman>
			</LeagueName>
			{children}
		</LeagueInfoWrapper>
	)
};

export default LeagueInfo;