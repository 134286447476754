import { SagaIterator }              from 'redux-saga';
import { call, put }                 from 'redux-saga/effects';
// import { find, last } from 'lodash';
import { ISagaAction, NumberOrNone } from '../../types';

export const SquadsFetchSaga = function*(
	action: ISagaAction<NumberOrNone>
): SagaIterator {

	const squads = yield call(action.meta.api.JSON_SPORT.squads);

	if (squads.error) {
		yield put(action.meta.actions.fetchSquadsJSONFailed({ message: squads.error }));
		return;
	}

	yield put(action.meta.actions.fetchSquadsJSONSuccess(squads));

};

export default SquadsFetchSaga;
