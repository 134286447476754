import { createReducer } from 'redux-act';
import * as actions      from '../../actions';


export type IUnsubscribeReducerState =  string;

const initialState: IUnsubscribeReducerState = 'Unsubscribe Request';

export const unsubscribe = createReducer<IUnsubscribeReducerState>( {}, initialState );

unsubscribe.on( actions.unsubscribeSuccess, () => 'You successfully unsubscribed' );
unsubscribe.on( actions.fetchSquadsJSONFailed, () => (initialState) );

