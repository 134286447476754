import { IPrediction } from '../../reducers/tournament';
import { IGroup }            from '../../reducers/groups';
import { find, first, merge }                 from 'lodash';

export const getSelectedGroup = (selected_group_id: any, groups: any ) => {
	const group = find( groups, { id: selected_group_id } );
	return group || {};
};

export const getGroupPredictions: ( group: IGroup | { [ key: string ]: any }, questions: IPrediction[] ) => IPrediction[] =
	( group, user_prediction ) => {
		// const group_questions: any[] = [];
		// const group_questions_id = group_questions.map( ( question: any ) => question.id );
		return [];
		// return filter( user_prediction, prediction => group_questions_id.indexOf( prediction.question_id ) > -1 );
	};

// export const getGroupDataForSave: ( group: IGroup | { [ key: string ]: any }, questions: IPrediction[] ) => ITournamentSave = ( group, user_prediction ) => {
// 	return {
// 		group_id : group.id,
// 		questions: getGroupPredictions( group, user_prediction ),
// 	}
// };

export const mergeQuestionsWithPredictions = ( predictions: IPrediction[], predictions_for_merge: IPrediction[] ) => {

	return predictions.map( prediction => {
		const prediction_for_merge: any = find( predictions_for_merge, { race_id: prediction.race_id } ) || {};
		const podium = merge(prediction.podium, prediction_for_merge.podium);
		prediction_for_merge.podium = podium;
		return {
			...prediction,
			...prediction_for_merge
		}
	} );
};

export const getSelectedGroupId = (available_groups: any, selected_group_id: number) => {
	const selected_group = find(available_groups, {id: selected_group_id});
	const first_group: any = first(available_groups);
	const first_group_id = first_group ? first_group.id : 1;
	return selected_group ? selected_group.id : first_group_id;
};
