import * as React         from 'react';
import styled             from 'styled-components';
import Countdown          from 'react-countdown-now';
import { partial, isNaN } from 'lodash';
import theme              from '../../assets/css/theme';
import { below }          from '../../assets/css/media';

interface ICountdownWrapper {
	is_uppercase: boolean;
}

const CountdownTimerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	color: ${({ textColor } : ITimerWrapper) => textColor || '#fff'};
	
`;

interface ITimerWrapper {
	textColor: string;
}

const Unit = styled.div`
	font-size: 22px;
	letter-spacing: 0.5px;
	line-height: 1;
	text-align: center;
	font-family: ${theme.base_bold_font};
	${below.desktop`
		font-size: 18px;
	`}
`;

const Label = styled.div`
	font-family: ${theme.base_font};
	font-size: 12px;
	line-height: 2;
	text-align: center;
	text-transform: ${({ is_uppercase }: ICountdownWrapper) => is_uppercase ? 'uppercase' : 'none'};
`;

const LabelsWrapper = styled.div`
	display: flex;
`;

const UnitsWrapper = styled.div`
	display: flex;
`;

const checkForMultiplyDateTimeLabel = (unit: string) => parseInt(unit, 10) === 1 ? '' : 's';

interface IProps  {
	date: string;
	onComplete?: () => any;
	label_type?: string;
	is_uppercase?: boolean;
	with_label?: boolean;
	className?: string;
	labelStyle?: any;
	textColor?: string;
	with_small_labels?: boolean;
}

const getMinutesLabel = (label: any, time: any, day: any, labelStyle: any) => {
	return (
		<Label
			as={labelStyle || 'div'}
			is_uppercase={label}
		>
			{label}{checkForMultiplyDateTimeLabel(time)}{!day && ':'}
		</Label>
	);
};

const getMinutes = (time: any, day: any, with_small_labels: any) => {
	return (
		<Unit>
			{time}{getSmallLabel(with_small_labels, 'm')}{!day && ':'}
		</Unit>
	);
};

const getLabels = ({days, label, is_uppercase, hours, minutes, seconds, labelStyle }: any ) => {
	return (
		<LabelsWrapper>
			{days ? (
				<Label
					as={labelStyle || 'div'}
					is_uppercase={is_uppercase}
				>
					{label.days}{checkForMultiplyDateTimeLabel(days)}:
				</Label>
			) : null}
			<Label
				as={labelStyle || 'div'}
				is_uppercase={is_uppercase}
			>
				{label.hours}{checkForMultiplyDateTimeLabel(hours)}:
			</Label>
			{getMinutesLabel(label.minutes, minutes, days, labelStyle)}
			{!days ? (
				<Label
					as={labelStyle || 'div'}
					is_uppercase={is_uppercase}
				>
					{label.seconds}{checkForMultiplyDateTimeLabel(seconds)}
				</Label>
			) : null}
		</LabelsWrapper>
	)
};

const getSmallLabel = (with_small_labels: boolean, label: string) => with_small_labels && label;

const renderer = (
	{ days, hours, minutes, seconds, completed, label_type, is_uppercase, with_label, labelStyle, with_small_labels, ...props } : any
) => {
	const label = {
		full: {
			days: 'Day',
			hours: 'Hour',
			minutes: 'Minute',
			seconds: 'Second'
		},
		short: {
			days: 'Day',
			hours: 'Hr',
			minutes: 'Min',
			seconds: 'Sec'
		}
	}[label_type || 'short'];
	// Render a countdown
	return (
		<CountdownTimerWrapper className={props.className} textColor={props.textColor}>

			{with_label ? (
				getLabels({ days, hours, minutes, seconds,label, is_uppercase, labelStyle })
			): null}

			<UnitsWrapper>
				{days ? (
					<Unit>
						{completed ? '00' : days}
						{getSmallLabel(with_small_labels, 'd')}:
					</Unit>
				) : null}
				<Unit>
					{hours}{getSmallLabel(with_small_labels, 'h')}:
				</Unit>
				{getMinutes(minutes, days, with_small_labels)}
				{!days ? (
					<Unit>
						{seconds}{getSmallLabel(with_small_labels, 's')}
					</Unit>
				) : null}
			</UnitsWrapper>

		</CountdownTimerWrapper>
	);
};

const render = (label_type: any, is_uppercase: any, className: any, with_label: boolean, labelStyle: any, textColor: any, with_small_labels: any, { ...props }) => {
	return renderer({ label_type, is_uppercase, className, with_label,labelStyle, textColor, with_small_labels, ...props });
};

export const CountdownTimer = (
	{ date, onComplete, label_type, is_uppercase, with_label = true, className, labelStyle, textColor, with_small_labels}: IProps
) => {
	const to_date = new Date(date);
	return !isNaN(to_date.getTime()) ? (
		<Countdown
			date={to_date}
			onComplete={onComplete}
			renderer={partial(render, label_type, is_uppercase, className, with_label, labelStyle, textColor, with_small_labels)}
		/>
	) : null;
};

export default CountdownTimer;