import * as _           from 'lodash';
import { SagaIterator } from 'redux-saga';
import { put }          from 'redux-saga/effects';
import { ISagaAction }  from '../../types';
import ApiErrors        from '../../utils/Api/ApiErrors';


export const errorsManagerSaga = function* (action: ISagaAction<any>): SagaIterator {
	try {
		const error = action.payload;
		if(error.code === ApiErrors.AUTH_REQUIRED || error.code === ApiErrors.USER_EXPIRED_TOKEN){
			yield put(action.meta.actions.userLogout())
		}
		if(error instanceof TypeError) {
			console.error(error);
			return;
		}
		if(_.isString(error)){
			throw error;
		}
		if(error && _.isString(error.message)) {
			throw error.message
		}
	} catch (e) {
		yield put(action.meta.actions.errorsShowGlobalError({ text: e }));
	}
};