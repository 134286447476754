import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface ICountry {
	code: string,
	name: string,
	is_disabled: boolean
}

export type ICountriesReducerState = ICountry[];

const initialState: ICountriesReducerState = [];

export const countries = createReducer<ICountriesReducerState>( {}, initialState );

countries.on( actions.fetchCountriesJSONSuccess, ( state: ICountriesReducerState, payload: ICountriesReducerState ) => payload );
countries.on( actions.fetchCountriesJSONFailed, () => (initialState) );

