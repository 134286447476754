import { SagaIterator }              from 'redux-saga';
import { call, put }                 from 'redux-saga/effects';
import { ISagaAction, NumberOrNone } from '../../types';

export const CountriesFetchSaga = function*(
	action: ISagaAction<NumberOrNone>
): SagaIterator {

	const countries = yield call(action.meta.api.JSON_SPORT.countries);

	if (countries.error) {
		yield put(action.meta.actions.fetchCountriesJSONFailed({ message: countries.error }));
		return;
	}

	yield put(action.meta.actions.fetchCountriesJSONSuccess(countries));
};

export default CountriesFetchSaga;
