import styled, { css } from 'styled-components';
import { below } from '../../assets/css/media'
import theme from '../../assets/css/theme';

interface IContent {
	readonly no_mobile_padding?: boolean
}

export const Content = styled.div`
	width: 100%;
	max-width: ${theme.content_width};
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 20px;
	position: relative;
	align-items: flex-start;
	section {
		width: 69%;
		min-height: 82vh;
		${below.desktop`
			width: 100%;
		`}
	}
	aside {
		width: 30%;
		${below.desktop`
			display: none;
		`}
	}
	${({ no_mobile_padding }: IContent) => (below.desktop`${ css`
		padding: ${no_mobile_padding ? '0' : '0 10px'};
	`}`)}
`;

export default Content;