import * as React                             from 'react';
import * as _                                 from 'lodash';
import styled, { css }                        from 'styled-components';
import { connect }                            from 'react-redux';
import { IStoreState }                        from '../../modules/types';
import * as actions                           from '../../modules/actions';
import theme                                  from '../../assets/css/theme';
import { copyToClipboard }                    from '../../modules/utils/social_share';
import { LoadingSpinner }                     from '../../components/AppLoading';
import { delay }                              from '../../modules/utils/dom_helpers';
import { below }                              from '../../assets/css/media';
import { ILeagueManageReduser }               from '../../modules/reducers/leagues';
import { RouteComponentProps }                from 'react-router';
import { share }                              from '../../modules/utils/social_share';
import { LeaguesNextMenuItems as items_menu } from '../../components/Navigations/menuItems'

import {
	ContentWithSidebar, FetchJsons,
	InviteContent,
	LeagueInviteEmailLink,
	PageTitle, PointsSystem,
	RegularButtonLight,
	SidebarAdComponent,
	WithSubNav,
} from '../../components'


const LeagueCode = styled.div`
	margin: 2.5em 0 2em;
	text-align: center;
	font-size: 1.125em;
	color: ${theme.base_text_color};
	font-family: ${theme.base_bold_font};
	p:last-child {
		font-size: 1.55em;
		padding: 0.25em;
	}
	
	${below.desktop`
		margin: 0 0 1em;
		font-size: 1em
		p:last-child {
			font-size: 1.375em;
			padding: 0.25em;
		}
	`}
`;

interface ICopy {
	readonly copied?: boolean
}

const RegularButtonLightCopy = styled( RegularButtonLight )`
	position: relative;
	&:after {
		content: 'Copied';
		position: absolute;
		right: -100%;
		top: 0;
		padding: 0 0.5em;
		box-sizing: border-box;
		max-width: 50%;
		transition: all 0.3s ease-in;
		z-index: 3;
		height: 100%;
		background: ${theme.primary};
		color: white;
		${( { copied }: ICopy ) => (copied && css`
			right: 0
		`)}
	}
`;

const InviteContentWrapper = styled( InviteContent )`
	overflow: hidden;
	position: relative;
	input {
		width: 1px;
		height: 1px;
		left: 1%;
		top: 0;
		position: absolute;
		z-index: -1;
	}
`;


interface IMapProps {
	readonly model_manage: ILeagueManageReduser
}

interface IDispatchProps {
	readonly show_league: ( league_id: number ) => void
}

interface IMergedProps {
	readonly league_id: number;
	readonly is_need_to_fetch: boolean;
}

interface IState {
	readonly copied: boolean;
}

type Props = IMapProps & IDispatchProps & IMergedProps & RouteComponentProps;

const Ads = ([<SidebarAdComponent key='ad'/>, <PointsSystem key='points'/>])

class InvitesLeagueComponent extends React.Component<Props, IState> {

	public state = {
		copied: false
	};

	public hiddenInput: React.RefObject<HTMLInputElement>;

	constructor( props: Props ) {
		super( props );
		this.hiddenInput = React.createRef();

		_.bindAll( this, [
			'copyLink',
			'handleShare',
			'handleShare'
		] )
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount() {
		const { show_league, league_id, is_need_to_fetch } = this.props;

		if ( is_need_to_fetch ) {
			show_league( league_id );
		}
	}

	/**
	 * Hide message for copy button
	 */
	public hideMessage() {
		const THREE_SECONDS = 3000;
		delay( THREE_SECONDS )
			.then(
				() => {
					this.setState( {
						copied: false
					} )
				}
			);
	}

	/**
	 * Copy link
	 */
	public copyLink() {
		copyToClipboard( this.hiddenInput )
			.then( () => {
				this.setState( {
					copied: true
				}, this.hideMessage )
			} )
	};

	/**
	 * Method for sharing Twitter and Facebook
	 */
	public handleShare( type_share: 'tw' | 'fb' ) {
		const { league_id, model_manage: { league } } = this.props;

		share( {
			sc     : type_share,
			league_id,
			section: 'league',
			message: `Come and join my Supercars Tipping league, the league pin is ${league.code}`
		} );
	}

	/**
	 * @ignore
	 */
	public get RegularButtonLights(): React.ReactNode {
		const { model_manage: { league, is_data_requested } } = this.props;
		const { copied } = this.state;
		const link = `${window.location.origin}/leagues/join/${league.code}`;

		// noinspection NegatedConditionalExpressionJS
		return (
			<InviteContentWrapper>
				{is_data_requested ? (
					<LoadingSpinner with_cover_bg={true} position="center"/>
				) : (
					<>
						<input ref={this.hiddenInput} defaultValue={link}/>
						<RegularButtonLightCopy onClick={this.copyLink} copied={copied}>
							Copy Link
						</RegularButtonLightCopy>
						<RegularButtonLight
							onClick={_.partial( this.handleShare, 'fb' )}>Facebook</RegularButtonLight>
						<RegularButtonLight
							onClick={_.partial( this.handleShare, 'tw' )}>Twitter</RegularButtonLight>
						<RegularButtonLight
							as={LeagueInviteEmailLink( league.id )}>Email</RegularButtonLight>
					</>
				)
				}
			</InviteContentWrapper>
		)
	}

	/**
	 * @ignore
	 */
	public render() {

		const { model_manage: { league } } = this.props;

		return (
			<WithSubNav backLink="/leagues" backTitle={league.name} items_menu={items_menu}>
				<FetchJsons/>
				<ContentWithSidebar
					sidebar_components={[ Ads ]}
				>
					<PageTitle align="center">
						Invite someone to join {league.name}
					</PageTitle>
					<LeagueCode>
						<p>League PIN</p>
						<p>{league.code}</p>
					</LeagueCode>
					{this.RegularButtonLights}
				</ContentWithSidebar>
			</WithSubNav>

		)
	}
}

const mapStateToProps = ( state: IStoreState ): IMapProps => ({
	model_manage: state.league_manage,
});


const mapDispatchToProps: IDispatchProps = {
	show_league: actions.showLeague,
};

const mergeProps = ( stateProps: IMapProps, dispatchProps: IDispatchProps, ownProps: RouteComponentProps ) => {
	const league_id = _.get( ownProps, 'match.params.id' );
	const is_need_to_fetch = !_.eq( stateProps.model_manage.league.id, parseInt( league_id, 10 ) );

	return {
		...stateProps,
		...ownProps,
		...dispatchProps,
		league_id,
		is_need_to_fetch
	};
};


export const InvitesLeague = connect( mapStateToProps, mapDispatchToProps, mergeProps )( InvitesLeagueComponent );
