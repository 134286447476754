import * as React from 'react';
import * as _ from 'lodash';
import { Field } from './Field';

interface IConfirmField<T>  extends React.AllHTMLAttributes<T>{
	placeholderConfirm: string;
	stateChange?: (state: boolean) => void;
}

export class ConfirmField<T> extends React.Component<IConfirmField<T | HTMLInputElement>> {
	public confirm_field: React.RefObject<HTMLInputElement>;
	public regular_field: React.RefObject<HTMLInputElement>;

	constructor(props: IConfirmField<T | HTMLInputElement>) {
		super(props);
		this.confirm_field =  React.createRef();
		this.regular_field =  React.createRef();
	}
	
	public check = ():void => {
		const { name, stateChange } = this.props;
		const regular_value = _.get(this.regular_field, 'current.value', '');
		const confirm_value = _.get(this.confirm_field, 'current.value', '');
		const confrimm_DOM_element = this.confirm_field.current;

		if(!_.isNull(confrimm_DOM_element)){
			const is_valid = regular_value === confirm_value;

			if (is_valid) {
				confrimm_DOM_element.setCustomValidity('');
			} else {
				confrimm_DOM_element.setCustomValidity(`${name} must be matching.`);
			}

			if(_.isFunction(stateChange)) {
				stateChange(is_valid);
			}
		}

	};

	/**
	 * @ignore
	 */
	public render(){
		const {
			name,
			placeholder,
			defaultValue,
			placeholderConfirm,
			onChange,
			as,
			...props
		} = this.props;

		return (
			<div>
				<Field
					id={name}
					name={name}
					placeholder={placeholder}
					onInput={this.check}
					defaultValue={defaultValue}
					ref={this.regular_field as any}
					onChange={onChange}
					{...props}
				/>
				<Field
					id={`${name}_confirm`}
					name={`${name}_confirm`}
					placeholder={placeholderConfirm}
					onInput={this.check}
					{...props}
					ref={this.confirm_field as any}
				/>
			</div>
		)
	}
}

export default ConfirmField;