import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface IDriver {
	readonly id: number;
	readonly color: string;
	readonly endurance_partner: string;
	readonly first_name: string;
	readonly last_name: string;
	readonly image: number;
	readonly number: number;
	readonly squad_id: number;
	readonly wildcard: number;
	result: any;
	squad_name: string;
}

export type IDriverReducersState = IDriver[];

const initialState: IDriverReducersState = [];

export const drivers = createReducer<IDriverReducersState>( {}, initialState );

drivers.on( actions.fetchDriversJSONSuccess, ( state: IDriverReducersState, payload: IDriverReducersState ) => payload );
drivers.on( actions.fetchDriversJSONFailed, () => (initialState) );

