import * as React from 'react';
import styled     from 'styled-components';
import logo       from '../../assets/img/supercars-logo-2022.png';
import theme      from '../../assets/css/theme';
import { SecondaryButtonHref } from '../';

const PointSystemWrapper = styled.div`
	box-shadow: 0 2px 10px 0 rgba(0,0,0,0.35);
	background: #fff;
`;

const Header = styled.div`
	background: #EDF0F2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-bottom: 3px solid #E8351A;
	padding: 20px 0 30px 0;
`;
const Logo = styled.img`
	max-width: 113px;
	margin-bottom: 20px;
`;
const Title = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 32px;
`;
const Content = styled.div`
	padding: 10px;
`;
const PointWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #EDF0F2;
	&:nth-child(4) {
		border-bottom: none;
	}
`;
const PointText = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 17px;
	max-width: 55%;
	line-height: 23px;
	letter-spacing: 1px;
`;
const Point = styled.div`
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: ${({bg}: IPoint) => bg ? bg : '#000'};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
`;
const PointNumber = styled.div`
	font-size: 20px;
	font-family: ${theme.base_bold_font};
`;
const PointLabel = styled.div`
	font-size: 8px;
`;

interface IPoint {
	bg: string;
}

export const PointsSystem = () => (
	<PointSystemWrapper>
		<Header>
			<Logo src={logo} alt="logo"/>
			<Title>Points System</Title>
		</Header>
		<Content>
			<PointWrapper>
				<PointText>Correctly tipped
					podium position</PointText>
				<Point bg='#7ED321'>
					<PointNumber>2</PointNumber>
					<PointLabel>PTS</PointLabel>
				</Point>
			</PointWrapper>
			<PointWrapper>
				<PointText>Incorrectly tipped podium position but still on the podium</PointText>
				<Point bg='#FF9503'>
					<PointNumber>1</PointNumber>
					<PointLabel>PTS</PointLabel>
				</Point>
			</PointWrapper>
			<PointWrapper>
				<PointText>Correctly tipping entire podium</PointText>
				<Point bg='#7ED321'>
					<PointNumber>7</PointNumber>
					<PointLabel>PTS</PointLabel>
				</Point>
			</PointWrapper>
			<PointWrapper>
				<PointText>Incorrectly tipped podium position </PointText>
				<Point bg='#E8351A'>
					<PointNumber>0</PointNumber>
					<PointLabel>PTS</PointLabel>
				</Point>
			</PointWrapper>
			<SecondaryButtonHref margin={'20px auto 0'} href='/help/guidelines'>VIEW GAME GUIDELINES</SecondaryButtonHref>
		</Content>
	</PointSystemWrapper>
);

export default PointsSystem;
