import { connect } from "react-redux";
import * as actions from "../../modules/actions";

interface ILogoutAction {
    readonly userLogout: () => void
}

const LogoutComponent = ({ userLogout }: ILogoutAction) => {
    userLogout();
    return null
};

const mapDispatchToProps = {
    userLogout: actions.userLogout
};

export const Logout = connect(null, mapDispatchToProps)(LogoutComponent);