import { CountdownTimer }  from '../CountdownTimer';
import * as React          from 'react';
import styled              from 'styled-components';
import theme               from '../../assets/css/theme';
import { ILeagueState }    from '../../modules/reducers/leagues';
import { ITimerFunctions } from '../../modules/types';

const LeagueDataWrappe = styled.div`
	background: #333F48;
	display: flex;
	justify-content: space-around;
	padding: 0.5em 0;
`;

const Column = styled.div`
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	p, & > div {
		flex: 1 1 100%;
		max-width: 100%;
		color: white;
		font-size: 1.125em;
		font-family: ${theme.base_bold_font};
		text-align: center;
	
		&:first-child {
			font-size: 0.75em;
			opacity: 0.7;
			font-family: ${theme.base_font};
			margin-bottom: 0.5em;
			text-transform: uppercase;
			letter-spacing: 0.6px;
		}
	}
`;

const ColumnCountdown = styled.div `
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	justify-content: center;
	& > div & > div {
		flex: 1 1 100%;
		max-width: 100%;
		color: white;
		font-size: 1.125em;
		font-family: ${theme.base_bold_font};
		text-align: center;
	
		&:first-child {
			font-size: 0.75em;
			opacity: 0.7;
			font-family: ${theme.base_font};
			margin-bottom: 0.5em;
			text-transform: uppercase;
			letter-spacing: 0.6px;
		}
	}
`;

const Label = styled.div`
	font-size: 0.75em;
	opacity: 0.7;
	font-family: ${theme.base_font};
	margin-bottom: 0.5em;
	text-transform: uppercase;
	letter-spacing: 0.6px;
	line-height: 1;
`;

interface ILeagueData {
	readonly league: ILeagueState,
}

type Props = ILeagueData & ITimerFunctions



const scheduledData = ({ league, onCompleteTimer }: Props) => (
	<>
		<ColumnCountdown>
			<CountdownTimer
				date={league.start_at}
				is_uppercase={true}
				with_label={true}
				onComplete={onCompleteTimer}
				labelStyle={Label}
			/>
		</ColumnCountdown>

		<Column>
			<p>START DAY</p>
			<p>{league.start_round}</p>
		</Column>

		<Column>
			<p>Teams</p>
			<p>{league.num_teams}</p>
		</Column>
	</>
);

const playingData = ({ league }: ILeagueData) => (
	<>
		<Column>
			<p>start day</p>
			<p>{league.start_round}</p>
		</Column>
		<Column>
			<p>overall pts</p>
			<p>{league.overall_points}</p>
		</Column>
		<Column>
			<p>rank</p>
			<p>{league.overall_rank}/{league.num_teams}</p>
		</Column>
	</>
);

export const LeagueData = ( props: Props ) => {
	return (
		<LeagueDataWrappe>
			{ props.league.status === 'scheduled' ? (
				scheduledData(props)
			) : (
				playingData(props)
			) }
		</LeagueDataWrappe>
	)
};

export default LeagueData;