import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { below } from '../../assets/css/media';

export const FormGlobalTitle = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 25px;
	color: ${theme.base_text_color};
	padding: 1em 0;
	line-height: 25px;
	text-align: center;
	${below.desktop`
		font-size: 1.375em;
		padding: 1em 0;
	`}
`;

export default FormGlobalTitle;