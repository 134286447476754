import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { ICollectionState } from '../../types';
import { ILeagueState } from './my_leagues';

export interface IJoinState {
	is_data_requested: boolean,
	is_joined: boolean
}

export interface IJoinLeagueState {
	joined_codes: {
		[code: string]: IJoinState,
	},
}

export type JoinLeagues = ICollectionState<ILeagueState> & IJoinLeagueState;

const defaultState: JoinLeagues = {
	items: [],
	next: false,
	joined_codes: {},
};

export const join_leagues = createReducer<JoinLeagues>({}, defaultState)
	.on(actions.clearLeaguesForJoin, (state: JoinLeagues) => ({
		...defaultState,
		joined_codes: state.joined_codes,
	}))
	.on(actions.getLeaguesForJoin, (state: JoinLeagues, payload: string) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.getLeaguesForJoinSuccess, (state: JoinLeagues, payload: JoinLeagues) => ({
		...state,
		items: [
			...state.items,
			...payload.items
		],
		next: payload.next,
		is_data_requested: false,
	}))
	.on(actions.getLeaguesForJoinFailed, (state: JoinLeagues) => ({
		...state,
		is_data_requested: false,
	}))
	/**
	 * Save in reducer state about one league
	 * format should be [league_code]: state
	 */
	.on(actions.joinToLeague, (state: JoinLeagues, league_code: string) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[league_code] : {
				is_data_requested: true,
				is_joined: false,
			},
		},
		is_data_requested: !Boolean(league_code),
	}))
	.on(actions.joinToLeagueSuccess, (state: JoinLeagues, payload: string) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload] : {
				is_data_requested: false,
				is_joined: true,
			},
		},
		is_data_requested: false,
	}))
	.on(actions.joinToLeagueFailed, (state: JoinLeagues, payload: string) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload] : {
				is_data_requested: false,
				is_joined: false,
			},
		},
		is_data_requested: false,
	}))
	.on(actions.clearLeaguesForJoin, () => ({...defaultState }));