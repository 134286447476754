import { SagaIterator }                               from 'redux-saga';
import { all, call, CallEffectFn, put, select, take } from 'redux-saga/effects';
import { ISagaAction, NumberOrNone } from '../../types';
import { filter, size }              from 'lodash';
import RoundsFetchSaga               from '../rounds';
import SquadsFetchSaga               from '../squads';
import GroupsFetchSaga               from '../groups';
import DriversFetchSaga              from '../drivers';
import { authStatus }                from '../../selectors';

const maybeCall = ( arr: any, func: any, action: any ): CallEffectFn<any> | null => (size( arr ) ? null : call( func, action ));

export const JSONSFetchSaga = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const {
		groups,
		rounds,
		squads,
		drivers
		// checksums,
	} = yield select();

	try {
		yield all( [
			maybeCall( rounds, RoundsFetchSaga, action ),
			maybeCall( squads, SquadsFetchSaga, action ),
			maybeCall( groups.groups, GroupsFetchSaga, action ),
			maybeCall( drivers, DriversFetchSaga, action ),
		] );

		yield put( action.meta.actions.fetchJSONSSuccess() );
	}
	catch ( e ) {
		console.log( e );
	}


};

export const JSONSFetchOnLoginSaga = function* (
	action: ISagaAction<any>
): SagaIterator {

	const is_logged_in = yield select( authStatus );

	if ( !is_logged_in ) {
		yield take( action.meta.actions.userLoginSuccess );
	}

	yield call( JSONSFetchSaga, action );
};

export default JSONSFetchSaga;


export const fetchGuidelinesJSON = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const { help } = yield select();

	if ( !help.guidelines.length ) {
		try {
			const guidelines = yield call( action.meta.api.JSON_SPORT.guidlines );
			if(!guidelines.code) {
				yield put( action.meta.actions.fetchGuidelinesSuccess( { guidelines } ) );
			}
		}
		catch ( e ) {
			console.log( e );
		}
	}

};


export const fetchTermsJSON = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const { help } = yield select();
	try {

		if ( !help.terms.length ) {
			const terms = yield call( action.meta.api.JSON_SPORT.terms );
			if(!terms.code) {
				yield put( action.meta.actions.fetchTermsSuccess( { terms: terms.items } ) );
			}
		}

	}
	catch ( e ) {
		console.log( e );
	}
};

export const fetchPrizesJSON = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const { help } = yield select();
	try {

		if ( !help.prizes.length ) {
			const prizes = yield call( action.meta.api.JSON_SPORT.prizes );
			if(!prizes.code) {
				yield put( action.meta.actions.fetchPrizesSuccess( { prizes: prizes.items } ) );
			}
		}

	}
	catch ( e ) {
		console.log( e );
	}
};

export const fetchFAQSJSON = function* (
	action: ISagaAction<NumberOrNone>
): SagaIterator {
	const { help } = yield select();
	try {

		if ( !help.faqs.length ) {
			const faqs = yield call( action.meta.api.JSON_SPORT.faq );
			if(!faqs.code) {
				const format_faqs = faqs.categories.map( ( category: any ) => {
					const items = filter( faqs.items, { category_id: category.id } );
					return {
						...category,
						items
					}
				} );

				yield put( action.meta.actions.fetchTermsSuccess( { faqs: format_faqs } ) );
			}

		}

	}
	catch ( e ) {
		console.log( e );
	}
};