import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
    Route,
    RouteProps as RoutePropsTypes,
} from "react-router-dom";
import { authStatus } from "../../modules/selectors"
import { IStoreState } from "../../modules/types";

interface IModelState {
    readonly is_authenticated?: boolean;
}

type Props = RoutePropsTypes & IModelState;

const NotAuthRouteComponent = ({ is_authenticated, ...rest }: Props) =>
    is_authenticated  ? <Redirect to="/tipping"/> : <Route {...rest} />;

const mapStateToProps = (state: IStoreState) => ({
    is_authenticated: authStatus(state),
});

export const NotAuthRoute = connect(mapStateToProps)(NotAuthRouteComponent);