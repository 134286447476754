import { createReducer } from 'redux-act';
import * as actions      from '../../actions';

export interface ISquad {
	readonly id: number | string;
	readonly full_name: string;
	readonly name: string;
	readonly is_disabled: boolean;
}

export type ISquadsReducerState = ISquad[];

const initialState: ISquadsReducerState = [];

export const squads = createReducer<ISquadsReducerState>( {}, initialState );

squads.on( actions.fetchSquadsJSONSuccess, ( state: ISquadsReducerState, payload: ISquadsReducerState ) => payload );
squads.on( actions.fetchSquadsJSONFailed, () => (initialState) );

