import * as _                  from 'lodash';
import { SagaIterator }        from 'redux-saga';
import { call, put }           from 'redux-saga/effects';
import { IEmail, ISagaAction } from '../../types';
import ApiErrors               from '../../utils/Api/ApiErrors';

export interface IContactUSSagaAction extends IEmail {
	readonly name: string,
	readonly category: string
}

export const postContactUsSaga = function* (action: ISagaAction<IContactUSSagaAction>): SagaIterator {
	try {
		const { errors } = yield call(action.meta.api.Contact.save, action.payload);

		const is_error = !_.isEmpty(errors);

		if (is_error) {
			throw new ApiErrors(errors[0].text, errors[0].code)
		}

		yield put(action.meta.actions.saveContactUsSuccess());

	} catch (e) {
		yield put(action.meta.actions.saveContactUsFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};