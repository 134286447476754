import * as React      from 'react';
import styled, { css } from 'styled-components';
import theme           from '../../assets/css/theme';
import CountdownTimer  from '../CountdownTimer';
import { ISnapshot }   from '../../modules/reducers/tournament';
import { isNull }      from 'lodash';
import { Tooltip }     from '../Tooltip';
import { below }       from '../../assets/css/media';

const GameBarWrapper = styled.div`
	background: #fff;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.35);
	overflow: hidden;
	margin-bottom: 1em;
	${below.desktop`
		margin: 2em 10px;
	`}
`;

const TopSection = styled.div`
	height: 70px;
	align-items: center;
	display: flex;
	color: ${theme.base_text_color};
	position: relative;
`;

const NameBox = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 1.57em;
	position: relative;
	z-index: 1;
	margin-left: 1.5em;
	margin-right: auto;
	${below.desktop`
		margin-left: .5em;
		font-size: 18px;
	`}
`;

const ScoreBox = styled.div`
	background: #333F48;
	display: flex;
	justify-content: center;
	height: 70px;
	align-items: center;
`;

const Snapshot = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
	color: #fff;
	text-align: center;
`;

const SnapshotLabel = styled.div`
	font-family: ${theme.base_font};
	font-size: 12px;
	line-height: 2;
`;

const SnapshotPoints = styled.div`
	font-size: 22px;
	letter-spacing: 0.5px;
	line-height: 1;
	font-family: ${theme.base_bold_font};
`;

const Line = styled.div`
	background: ${theme.primary};
	position: absolute;
	height: 100%;
	width: 38px;
	top: 0;
	left: 15px;
	transform: skew(-20deg, 0deg);
	${below.desktop`
		display: none;
	`}
`;

const RaceStatusBox = styled.div`
	text-align: center;
`;

const RaceStatusTitle = styled.div`
	text-transform: uppercase;
	margin-bottom: 5px;
	font-size: 15px;
	${({isProvisional}: IClosed) => {
		const provisional = isProvisional && css`
			color: #E8351A;
		`;
		return [provisional];
	}}
`;

const ToolTipWithMargin = styled(Tooltip)`
	margin-right: 3em;
	margin-left: 1em;
	${below.desktop`
		 margin-right: 10px;
	`}
`;

const Closed = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 20px;
	color: #9B9B9B;
	${({isProvisional}: IClosed) => {
		const provisional = isProvisional && css`
			color: #E8351A;
		`;
		return [provisional];
	}}
	${below.desktop`
		font-size: 18px
	`}
`;

interface IClosed {
	isProvisional: boolean;
}

interface IProps {
	selectedGroup: any;
	teamName: string;
	snapshot: ISnapshot;
}

interface IState {
	is_show_countdown: boolean
}

export class GameBar extends React.Component<IProps, IState> {
	public state = {
		is_show_countdown: true,
	};

	get locked() {
		const {selectedGroup} = this.props;
		return selectedGroup.status !== 'scheduled';
	}

	get countDownBox() {
		const { selectedGroup } = this.props;
		const { date_start = '' } = selectedGroup;

		return selectedGroup.status === 'scheduled' ? (
			<CountdownTimer
				textColor={`#${theme.primary}`}
				with_label={false}
				with_small_labels={true}
				date={date_start}
				is_uppercase={true}
			/>
		) : null;
	}

	get lockedStatus() {
		const { selectedGroup } = this.props;
		let status = '';
		if(selectedGroup.status !== 'scheduled') {
			status = 'Close';
		}
		if(this.isProvisional) {
			status = 'PROVISIONAL';
		}
		if(this.isFinal) {
			status = 'FINAL';
		}
		return status;
	}

	get isProvisional() {
		const { selectedGroup } = this.props;
		return selectedGroup.status === 'complete' && !selectedGroup.finalized;
	}

	get isFinal() {
		const { selectedGroup } = this.props;
		return selectedGroup.status === 'complete' && selectedGroup.finalized;
	}

	get raceStatusTitle() {
		const { selectedGroup } = this.props;
		const titles = {
			'scheduled': 'Tipping closes in:',
			'playing': 'Predictions',
			'complete': 'Results',
			'default': ''
		};
		return titles[selectedGroup.status] || titles.default;
	}

	get scoreBox() {
		const { snapshot } = this.props;
		const { round_points, overall_points, overall_rank } = snapshot;
		return (
			<Snapshot>
				<div>
					<SnapshotLabel>
						ROUND PTS
					</SnapshotLabel>
					<SnapshotPoints>
						{this.getSnapshootPoints(round_points)}
					</SnapshotPoints>
				</div>
				<div>
					<SnapshotLabel>
						TOTAL PTS
					</SnapshotLabel>
					<SnapshotPoints>
						{this.getSnapshootPoints(overall_points)}
					</SnapshotPoints>
				</div>
				<div>
					<SnapshotLabel>
						RANK
					</SnapshotLabel>
					<SnapshotPoints>
						{this.getSnapshootPoints(overall_rank)}
					</SnapshotPoints>
				</div>
			</Snapshot>
		);
	}
	/**
	 *
	 * @ignore
	 */
	public render() {
		const { teamName } = this.props;
		return (
			<GameBarWrapper>
				<TopSection>
					<Line />
					<NameBox>{teamName}</NameBox>
					<RaceStatusBox>
						<RaceStatusTitle isProvisional={this.isProvisional}>{this.raceStatusTitle}</RaceStatusTitle>
						{this.countDownBox}
						<Closed isProvisional={this.isProvisional}>{this.lockedStatus}</Closed>
					</RaceStatusBox>
					<ToolTipWithMargin><span>Once this clock hits zero, you will be unable to edit your predictions for the upcoming race.</span></ToolTipWithMargin>
				</TopSection>
				<ScoreBox>
					{this.scoreBox}
				</ScoreBox>
			</GameBarWrapper>
		);
	}

	/**
	 * Get snapshot point or dash
	 */
	private getSnapshootPoints(points: number | null) {
		return isNull(points) ? '-' : points;
	}
}

export default GameBar;
