import * as _ from 'lodash';
import { SagaIterator } from 'redux-saga';
import {
	call,
	put
} from 'redux-saga/effects';
import {
	ISagaAction,
	IUserFormState
} from '../../types';
import ApiErrors from '../../utils/Api/ApiErrors';
import {
	clearUserFromStorage,
	saveUserToStorage
} from '../../utils/user';


export const postUserLoginSaga = function* (action: ISagaAction<IUserFormState>): SagaIterator {
	try {
		const { result, errors, ...status } = yield call(action.meta.api.Auth.login, {
			login: action.payload.email,
			password: action.payload.password
		});

		const is_error = !_.isEmpty(errors);

		if ( is_error && !status.success ) {
			throw new ApiErrors(errors[ 0 ].text, errors[ 0 ].code);
		}

		saveUserToStorage(result.user, result.session_id);
		yield put(action.meta.actions.userLoginSuccess(result.user));

	} catch ( e ) {
		console.log(e);
		yield put(action.meta.actions.userLoginFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};

export const postUserRegisterSaga = function* (action: ISagaAction<IUserFormState>): SagaIterator {

	try {
		const { result, errors } = yield call(action.meta.api.User.create, action.payload);

		const is_error = !_.isEmpty(errors);

		if ( is_error ) {
			throw new ApiErrors(errors[ 0 ].text, errors[ 0 ].code);
		}
		const storage_answers = localStorage.getItem('predictions') || '{}';
		const answers = JSON.parse(storage_answers);

		saveUserToStorage(result.user, result.session_id);

		if ( !_.isEmpty(answers) ) {
			yield put(action.meta.actions.saveTournamentPredictions(answers));
		}
		yield put(action.meta.actions.userRegisterSuccess(result.user));

	} catch ( e ) {
		console.log(e);
		yield put(action.meta.actions.userRegisterFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};

export const postUserUpdateSaga = function* (action: ISagaAction<IUserFormState>): SagaIterator {

	try {
		const { result, errors } = yield call(action.meta.api.User.update, action.payload);

		ApiErrors.checkOnApiError({ errors });

		saveUserToStorage(result.user, result.session_id);
		yield put(action.meta.actions.userUpdateSuccess(result.user));

	} catch ( e ) {
		console.log(e);
		yield put(action.meta.actions.userUpdateFailed());
		yield put(action.meta.actions.errorsGlobalError(e));
	}

};

export const postUserLogoutSaga = function* (action: ISagaAction<undefined>): SagaIterator {
	try {

		clearUserFromStorage();
		yield call(action.meta.api.Auth.logout);
		yield put(action.meta.actions.userLogoutSuccess());

	} catch ( e ) {
		console.log(e);
	}
};

export const recoverUserSaga = function* (action: ISagaAction<undefined>): SagaIterator {
	try {

		const { result, errors } = yield call(action.meta.api.User.recover);
		ApiErrors.checkOnApiError({ errors });

		saveUserToStorage(result.user, result.session_id);
		console.log(result.user);
		yield put(action.meta.actions.userRecoverSuccess({
			...result.user
		}));

	} catch ( e ) {
		yield put(action.meta.actions.userRecoverFailed(e));
		yield put(action.meta.actions.errorsGlobalError(e));
	}
};
