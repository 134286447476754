import * as React     from 'react';
import * as _         from 'lodash';
import styled         from 'styled-components';
import { IFormProps } from '../../modules/types';

/**
 * Usage for flexible styling. For example you can use `as` parametr for custom styles
 */
const Form = styled.form``;

interface IFormValues {
	[x: string]: any;
	is_changed?: boolean
}

interface IFormWrapperState {
	values: IFormValues
	is_changed?: boolean
}

interface IFormCreator {
	readonly initialState?: IFormValues;
	readonly onSubmit: (params: any) => void;
	readonly render: any;
	readonly as?: any
}

/**
 * Component for wrapping form elements. use of one method handleChange is provided.
 * Designed for custom validation can be implemented here
 */
 export class FormCreator extends React.Component<IFormCreator, IFormWrapperState> {

	public state = {
		values: {},
		is_changed: false,
	};

	constructor( props: IFormCreator ) {
		super( props );
		_.bindAll( this, [
			'handleSubmitForm',
			'handleChange'
		] )
	}

	/**
	 * Track changes to any field in a form. It is recorded in `values`
	 */
	public handleChange( e: React.SyntheticEvent<EventTarget> ): void {
		const is_checkbox = _.get( e.target, 'type' ) === 'checkbox';
		const target = e.target;

		const name = _.get( target, 'name', '' );

		let value: string | number;

		if ( is_checkbox ) {
			value = _.get( target, 'checked', 0 ) ? 1 : 0;
		}
		else {
			value = _.get( target, 'value', '' );
		}

		/**
		 * For reverse value, unticket = true
		 */
		if ( _.includes( [ 'brand' ], name ) ) {
			value = +!value;
		}

		this.setState( ( { values }: IFormWrapperState ) => ({
			values: {
				...values,
				[ name ]: value,
			},
			is_changed: true,
		}) );
	}

	/**
	 * Method to process registration form submission
	 */
	public handleSubmitForm( e: React.SyntheticEvent<EventTarget> ): void {
		e.preventDefault();
		const { onSubmit } = this.props;

		this.setState({
			is_changed: false,
		}, () => onSubmit( this.state.values ));
	};


	get propsForm(): IFormProps {
		return {
			handleChange: this.handleChange as any
		}
	}

	get values(): IFormValues {
		return this.state.values;
	}

	/**
	 * @ignore
	 */
	public componentWillMount(): void {
		const { initialState } = this.props;
		if ( !!initialState ) {
			this.setState({
				values: {
					...initialState,
				}
			});
		}
	}

	/**
	 * @ignore
	 */
	public componentWillReceiveProps( nextProps: Readonly<IFormCreator>, nextContext: any ): void {
		const { initialState } = nextProps;
		if ( !!initialState ) {
			this.setState({
				values: {
					...initialState,
				}
			});
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const { render, ...props } = this.props;
		const { is_changed } = this.state;
		return <Form
			{...props}
			onSubmit={this.handleSubmitForm}
		>
			{render( this.propsForm, {...this.values, is_changed } )}
		</Form>

	}
}
