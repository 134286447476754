import * as React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter, RouteComponentProps } from "react-router";
import {
    Route,
    RouteProps as RoutePropsTypes,
} from "react-router-dom";
import {
	authStatus,
	isRecovered
} from "../../modules/selectors";
import { IStoreState } from "../../modules/types";

interface IModelState {
    readonly is_authenticated?: boolean;
    readonly is_recovered?: boolean;
}

type Props = RoutePropsTypes & IModelState & RouteComponentProps;

const PrivateRouteComponent = ({ is_recovered, is_authenticated,location, ...rest }: Props) => {

	const is_recover_page = location.pathname === '/registration-recover';
	const is_logout = location.pathname === '/logout';

	if(is_recovered) {
		if(is_recover_page) {
			return <Redirect to="/"/>
		}
		return is_authenticated  ?  <Route {...rest} /> : <Redirect to="/"/>
	}

	if(is_recover_page || is_logout) {
		return is_authenticated  ?  <Route {...rest} /> : <Redirect to="/"/>
	}

	return <Redirect to='/registration-recover'/>
};

const mapStateToProps = (state: IStoreState) => ({
    is_authenticated: authStatus(state),
    is_recovered: isRecovered(state),
});

export const PrivateRoute = withRouter(connect(mapStateToProps)(PrivateRouteComponent));
