import * as React from 'react';
import { SubNavigation } from './SubNavigation';
import {
	IItem,
} from './menuItems'


interface IProps {
	readonly children: React.ReactNode
	readonly backLink: string;
	readonly backTitle: string;
	readonly items_menu?: IItem[];
}

export const WithSubNav = ({ children, ...props }: IProps) => (
	<>
		<SubNavigation {...props}/>
		{children}
	</>
);

export default WithSubNav;