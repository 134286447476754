import * as React                                       from 'react';
import { PointsSystem, SidebarAdComponent, WithSubNav } from '../../components';
import { HelpMenuItems as items_menu }                  from '../../components/Navigations/menuItems';
import { connect }                                      from 'react-redux';
import actions                                          from '../../modules/actions';
import { IStoreState }                                  from '../../modules/types';
import { IPrizes }                                      from '../../modules/reducers/help';
import { Help }                                         from './';

class HelpPrizesClass extends React.Component<any, any> {
	get prizes() {
		const { prizes } = this.props;
		return prizes.map( ( prize: IPrizes, key: number ) => (
			<div className='help-item' key={`guide-${key}`}>
				<div className='content' dangerouslySetInnerHTML={{ __html: prize.text }}/>
			</div>
		) )
	}

	/**
	 * @ignore
	 */
	public componentWillMount() {
		this.props.fetchPrizes();
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<WithSubNav backLink="/" backTitle='HELP' items_menu={items_menu}>
				<Help>
					<section>
						<div className='help-title'>
							Prizes
						</div>
						{this.prizes}
					</section>
					<aside>
						<SidebarAdComponent/>
						<PointsSystem key='points'/>
					</aside>
				</Help>
			</WithSubNav>

		);
	}
}


const mapStateToProps = ( state: IStoreState ) => ({
	prizes: state.help.prizes
});

const mapDispatchToProps = {
	fetchPrizes: actions.fetchPrizes
};

export const HelpPrizes = connect( mapStateToProps, mapDispatchToProps )( HelpPrizesClass );

export default HelpPrizes;