import * as React from 'react';
import styled from 'styled-components';
import checked_icon from '../../assets/img/icons/checked.svg';

interface ICheckbox extends React.AllHTMLAttributes<HTMLInputElement> {
	readonly onChange: (e: React.SyntheticEvent<EventTarget>) => void;
	readonly children: React.ReactNode;
	readonly name: string;
	readonly id: string;
	readonly tick_position?: string;
	readonly type?: string;
	readonly as?: any;
	readonly is_radio?: boolean;
	readonly width?: string;
}

const StyledCheckbox = styled.div`
	margin: 0.75em auto;
	width: 100%;
	overflow: hidden;
	position: relative;
`;

interface ICheckboxStyled {
	readonly tick_position?: string;
}

const Checkbox = styled.input`
	position: absolute;
	${({ tick_position }: ICheckboxStyled) => (tick_position)}: 0;
	z-index: 0;
`;

interface ILabel extends ICheckboxStyled {
	readonly is_radio?: boolean;
	readonly width?: string;
}

const Label = styled.label`
	transition: .4s;
	padding-${({ tick_position }: ILabel) => (tick_position)}: 3.5em;
	position: relative;
	line-height: 1.4;
	font-size: 0.75em;
	color: #7E858A;
	min-height: 2em;
	vertical-align: baseline;
	text-align: left;
	max-width: ${({ width }: ILabel) => (width || '32em')};
	box-sizing: border-box;
	width: 100%;
	display: inline-block;
	${({ is_radio }: ILabel) => (is_radio ? `
		display: inline-flex;
		align-items: center;
	`: '')}
	
	&:before {
		position: absolute;
		${({ tick_position }: ILabel) => (tick_position)}: 0;
		top: 0;
		display: block;
		content: '';
		border: 1px solid #E8351A;
		width: 2em;
		height: 2em;
		box-sizing: border-box;
		background-color: white;
		z-index: 1;
		${Checkbox}:checked + & {
			background-image: url(${checked_icon});
			background-size: 1.5em;
			background-repeat: no-repeat;
			background-position: center;
		}
	} 
`;

export const TickBoxStyled = ({ onChange, children, name, tick_position, type, id, width, ...props }: ICheckbox) => (
	<StyledCheckbox>
		<Checkbox
			onChange={onChange}
			name={name}
			id={id}
			type={type || 'checkbox'}
			tick_position={tick_position || 'right'}
			{...props}
		/>
		<Label
			htmlFor={id}
			is_radio={type === 'radio'}
			tick_position={tick_position || 'right'}
			width={width}
		>
			{children}
		</Label>
	</StyledCheckbox>
);

export default TickBoxStyled;
