import styled from 'styled-components';
import theme from '../../assets/css/theme';

export const Field = styled.input`
	box-shadow: none;
	font-family: ${theme.secondary_font}, sans-serif;
	color: #7E858A;
	text-align: left;
	transition: all 0.1s ease;
	width: 100%;
	outline: 0;
	outline-offset: 0;
	height: 40px;
	padding: 0 0.5em;
	display: flex;
	align-items: center;
	line-height: 12px;
	box-sizing: border-box;
	margin-bottom: 12px;
	text-align-last: left;
	border: 1px solid #EDEDED;
	font-size: 14px;
	&:disabled {
		background: transparent;
	}
	&::-webkit-input-placeholder {
		color: ${theme.base_text_color};
	}
	&::-moz-placeholder {
		color: ${theme.base_text_color};
	}
	&:-ms-input-placeholder {
		color: ${theme.base_text_color};
	}
	&:-moz-placeholder {
		color: ${theme.base_text_color};
	}
`;

export default Field;